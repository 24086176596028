/** @format */
import React from "react";
import ImageIcon from "assets/icons/ImageIcon";
import PollIcon from "assets/icons/PollIcon";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import PollModal from "components/Timeline/timeline-modals/PollModal";
const GroupMediaPollComposer = () => {
  const {
    postStore: {
      modal: { isPollOpen },
      togglePoll
    }
  } = useStore();

  console.log("Checking poll: ", isPollOpen);

  return (
    <>
      <div className="flex justify-evenly w-[100%] ">
        <div className=" flex items-center gap-3 justify-center hover:bg-[#ccc] rounded-full md:w-[117px] py-2">
          <ImageIcon />
          <p className=" font-bold leading-[18px] text-[14px]">Image</p>
        </div>

        <div
          onClick={togglePoll}
          className=" flex items-center gap-3 justify-center  hover:bg-[#ccc] rounded-full md:w-[117px] py-2"
        >
          <PollIcon />
          <p className="text-[#8C2C1B] font-bold leading-[18px] text-[14px]">Poll</p>
        </div>
      </div>

      {isPollOpen && <PollModal isOpen={isPollOpen} onClose={togglePoll} />}
    </>
  );
};

export default observer(GroupMediaPollComposer);
