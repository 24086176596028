/** @format */

import React from "react";

const PinIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7088 1.5598C12.7076 0.561074 14.0621 0 15.4745 0C18.4157 0 20.8 2.3843 20.8 5.32548C20.8 6.73789 20.2389 8.09245 19.2402 9.09117L10.0284 18.3029C9.45405 18.8773 8.67503 19.2 7.86274 19.2C6.17124 19.2 4.8 17.8288 4.8 16.1373C4.8 15.325 5.12268 14.5459 5.69706 13.9716L14.6343 5.03431L15.7657 6.16569L6.82843 15.1029C6.55411 15.3773 6.4 15.7493 6.4 16.1373C6.4 16.9451 7.05489 17.6 7.86274 17.6C8.25068 17.6 8.62274 17.4459 8.89706 17.1716L18.1088 7.9598C18.8075 7.26113 19.2 6.31354 19.2 5.32548C19.2 3.26796 17.532 1.6 15.4745 1.6C14.4865 1.6 13.5389 1.9925 12.8402 2.69117L3.35391 12.1775C2.2309 13.3005 1.6 14.8236 1.6 16.4118C1.6 19.719 4.28102 22.4 7.58823 22.4C9.1764 22.4 10.6995 21.7691 11.8225 20.6461L21.0343 11.4343L22.1657 12.5657L12.9539 21.7775C11.5308 23.2005 9.60075 24 7.58823 24C3.39736 24 0 20.6026 0 16.4118C0 14.3993 0.799471 12.4692 2.22254 11.0461L11.7088 1.5598Z"
          fill="#808080"
        />
      </svg>
    </div>
  );
};

export default PinIcon;
