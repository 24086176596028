/** @format */

import React from "react";

const GlobeIcon = () => {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM8.72571 2.28983C5.16881 3.21597 2.46801 6.26324 2.05493 10H6.05009C6.2743 7.23681 7.1991 4.58442 8.72571 2.28983ZM13.2743 2.28983C14.8009 4.58442 15.7257 7.23681 15.9499 10H19.9451C19.532 6.26324 16.8312 3.21597 13.2743 2.28983ZM13.9424 10C13.6912 7.28683 12.6697 4.70193 11 2.5508C9.33032 4.70193 8.30879 7.28683 8.05759 10H13.9424ZM8.05759 12H13.9424C13.6912 14.7132 12.6697 17.2981 11 19.4492C9.33032 17.2981 8.30879 14.7132 8.05759 12ZM6.05009 12H2.05493C2.46801 15.7368 5.16881 18.784 8.72571 19.7102C7.1991 17.4156 6.2743 14.7632 6.05009 12ZM13.2743 19.7102C14.8009 17.4156 15.7257 14.7632 15.9499 12H19.9451C19.532 15.7368 16.8312 18.784 13.2743 19.7102Z"
          fill="#808080"
        />
      </svg>
    </div>
  );
};

export default GlobeIcon;
