import React, { useState } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Button,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Textarea
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";

const PollModal = ({ isOpen, onClose }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [options, setOptions] = useState([
    { index: 1, value: "" },
    { index: 2, value: "" }
  ]);

  const handleOptionIndex = () => {
    const newOption = { index: options.length + 1, value: "" };
    setOptions((prev) => [...prev, newOption]);
  };

  const handleChange = (index, e) => {
    const data = [...options];
    data[index][e.target.name] = e.target.value;
    console.log(data);
    setOptions(data);
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading>Create a Poll</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex flexDirection="column" gap={3}>
              <FormControl>
                <FormLabel>Your question</FormLabel>
                <Textarea
                  h={{ base: "30px", lg: "70px" }}
                  ref={initialRef}
                  placeholder="E.g., What is your preferred career path"
                />
              </FormControl>

              {options.map((opt) => (
                <FormControl key={opt.index}>
                  <FormLabel>option {opt.index}</FormLabel>
                  <Input
                    placeholder="E.g., Web Developer"
                    height={{ base: "25px", md: "40px" }}
                    type="text"
                    name={opt.index}
                    onChange={(event) => handleChange(opt.index, event)}
                  />
                </FormControl>
              ))}
            </Flex>

            <Flex
              onClick={handleOptionIndex}
              alignItems="center"
              gap={3}
              px="1rem"
              my="1rem"
              cursor="pointer"
            >
              <FaPlus />
              <Text fontSize={{ base: "12px", md: "16px" }} fontWeight={700} color="#0C378B">
                Add option
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PollModal;
