/** @format */

import React from "react";

const ProgressBar = ({ percentage }) => {
  console.log(percentage);
  return (
    <div className=" w-full  h-1 bg-gray-200 rounded-full relative">
      <div
        className={`w-[${percentage}] bg-[#0C378B]  h-[100%] rounded-full`}
        // className="w-[40%] h-full bg-black rounded-full "
      />
    </div>
  );
};

export default ProgressBar;
