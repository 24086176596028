/** @format */

import React, { useRef, useState } from "react";
import SendIcon from "assets/icons/SendIcon";
import { observer } from "mobx-react-lite";

const PostInput = ({ options, inputFocus, setInputFocus, toggleModal, isOpen, type }) => {
  const { send, postData = "", setPostData } = options;

  const postHandler = (e) => {
    const { value } = e.target;
    setPostData(value);
  };
  const handleFocus = () => {
    type === "comment" && setInputFocus;
  };

  const handleBlur = () => {
    inputFocus && type === "comment" ? setInputFocus : null;
  };

  return (
    <>
      <form
        className="w-full relative"
        onSubmit={(e) => {
          e.preventDefault();
          send({ text: postData });
        }}
      >
        <div className="w-full relative">
          <button
            type="submit"
            disabled={inputFocus}
            className={`absolute w-[45px] h-[45px] lg:w-[33px] lg:h-[33px] lg:right-1 right-[0.5rem] top-[0.5rem] lg:top-[0.19rem] rounded-full ${
              inputFocus && type === "comment" ? "bg-[#1455D9]" : "bg-[#ccc]"
            } flex justify-center items-center`}
          >
            <SendIcon color="#fff" width="18" />
          </button>
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="border-[1px] rounded-full px-5 focus:border-[#CCCCCC]  lg:h-[40px] h-[60px]  active:shadow-[2px_4px_5px_rgba(0,0,0,0.1)] focus:border-[1px] outline-none focus:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] w-[100%]"
            placeholder=""
            value={postData}
            onChange={postHandler}
            disabled={isOpen}
            name="post"
            onClick={() => {
              type === "createNewPost" ? toggleModal() : handleFocus();
            }}
          />
        </div>
      </form>
    </>
  );
};

export default observer(PostInput);
