import React from "react";
import FriendsProfileCard from "../FriendsProfileCard";
import { useRespondToRequest } from "hooks/friend";
import { refetchKeys } from "constant";
import { getTimeAgoFromUpdated } from "utils/helpers";

const DesktopComponent = ({ data }) => {
  const { mutate: handleRequestType } = useRespondToRequest(refetchKeys.connectRequest);

  return (
    <div className="w-[100%] items-center flex justify-between md:gap-3 gap-[1rem]">
      <FriendsProfileCard data={data} />

      <div className="flex  justify-between items-end md:w-[235px] h-[72px] text-[12px] md:text-[14px] text-[#fff] justify-self-end">
        <button
          className=" rounded-[5px] w-[107px] h-[46px]  bg-[#1455D9] flex justify-center items-center"
          onClick={() => {
            handleRequestType("accepted", data.id);
          }}
        >
          Confirm
        </button>
        <div className="flex  justify-end text-[#333] flex-col items-end">
          <p className="justify-self-end text-[14px] leading-[19px] text-[#808080]">
            {getTimeAgoFromUpdated(data.createdAt)}
          </p>
          <button
            onClick={() => {
              handleRequestType("Decline", data.id);
            }}
            className=" rounded-[5px] w-[104px] h-[46px]  bg-[#F3F2F0] text-[12px] md:text-[14px] text-center flex justify-center items-center font-bold"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesktopComponent;
