/** @format */

import EditIcon from "assets/icons/EditIcon";
import React, { useState } from "react";
import AboutModal from "../../modal/AboutModal";
import "./about.css";
import ArrowUp from "assets/icons/ArrowUp";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useStore } from "store";
import { useGetProfile, useUpdateUserBio } from "hooks/user";
import { observer } from "mobx-react-lite";
import ShowLessIcon from "assets/icons/ShowLessIcon";
import ShowMoreIcon from "assets/icons/ShowMoreIcon";

const About = ({ header, actionable, userBio = "" }) => {
  const [showMore, setShowMore] = useState(true);
  const [bio, setBio] = useState({ bio: "" });

  const { data: { data: { profile } = {} } = {} } = useGetProfile();
  const { mutate: userProfilePatchFn } = useUpdateUserBio();

  const {
    authService,
    aboutStore: {
      modal: { isOpen },
      toggleModal,
      value,
      setValue
    }
  } = useStore();

  const handleAboutDetail = (e) => {
    const { name, value } = e.target;
    setBio((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (bio.bio == "") return;
    userProfilePatchFn(bio);
  };

  return (
    <>
      <div className="text-[14px] text-[#000] font-medium leading-6 whitespace-normal text-start w-full  border-1 shadow-[1px_3px_4px_rgba(0,0,0,0.1)] rounded-md px-3 p-4 md:p-4 bg-secondary-bg relative">
        <div className="flex justify-between items-center ">
          <h3 className="font-bold leading[27.28px] py-2 text-[20px] ">{header}</h3>
          {actionable && (
            <div className="lg:mr-6" onClick={toggleModal}>
              <EditIcon />
            </div>
          )}
        </div>

        {profile?.bio ? (
          <div className="about-content flex justify-center items-center w-full">
            <div
              className={`border-[1px] relative w-full flex justify-center items-center flex-col border-[#CCCCCC] rounded-[4px] p-3 md:border-none ${
                showMore ? "" : "container"
              } `}
            >
              <p className="font-normal leading-[24px] text-[12px] mb-5">
                {showMore
                  ? profile?.bio && actionable
                    ? profile.bio
                    : userBio
                  : profile?.bio && actionable
                    ? profile?.bio.substring(0, 400)
                    : userBio?.substring(0, 400)}
              </p>
              {profile?.bio && (
                <button
                  onClick={() => setShowMore((prev) => !prev)}
                  className={`${
                    showMore
                      ? "flex justify-center gap-2 items-center"
                      : "absolute bottom-5  left-[30%] md:left-[15%] lg:left-[23%] flex justify-center gap-2 items-center"
                  }  h-[34px] w-[140px]  border-[1px] border-[#1455D9] text-[#1455D9] rounded-[23px] text-center`}
                >
                  Show {showMore ? "less" : "More"}
                  {showMore ? <ShowLessIcon /> : <ShowMoreIcon />}
                </button>
              )}
            </div>
          </div>
        ) : (
          <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
            No profile bio yet
          </p>
        )}
      </div>

      {isOpen && actionable ? (
        <AboutModal
          isOpen={isOpen}
          onClose={() => toggleModal()}
          toggle={() => toggleModal()}
          input={bio.about}
          handleAboutDetail={handleAboutDetail}
          submit={handleSubmit}
        />
      ) : null}
    </>
  );
};

export default observer(About);
