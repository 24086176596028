/** @format */
import React from "react";
const ShareIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1581 7.81489C15.883 8.5467 16.8886 9 18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 5.37704 14.0522 5.74189 14.1497 6.08773L8.84198 9.18519C8.11707 8.45333 7.11148 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.11168 16 8.11744 15.5465 8.84237 14.8144L14.1509 17.9078C14.0526 18.255 14 18.6214 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C16.8902 15 15.886 15.452 15.1613 16.1818L9.85051 13.0871C9.9479 12.7414 10 12.3768 10 12C10 11.623 9.94784 11.2582 9.85036 10.9124L15.1581 7.81489ZM18 3C16.8954 3 16 3.89543 16 5C16 5.34701 16.0884 5.67338 16.2439 5.95784C16.2542 5.97351 16.2642 5.98956 16.2738 6.00598C16.2833 6.02228 16.2923 6.03873 16.3008 6.05533C16.6538 6.62248 17.2828 7 18 7C19.1046 7 20 6.10457 20 5C20 3.89543 19.1046 3 18 3ZM7.70017 10.9462C7.70844 10.9623 7.71718 10.9782 7.7264 10.994C7.73569 11.0099 7.74534 11.0255 7.75536 11.0407C7.91132 11.3255 8 11.6524 8 12C8 12.3476 7.9113 12.6746 7.7553 12.9594C7.74519 12.9747 7.73545 12.9905 7.72608 13.0065C7.717 13.0221 7.70838 13.0379 7.70023 13.0537C7.34743 13.6218 6.71789 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.71785 10 7.34736 10.3782 7.70017 10.9462ZM16 19C16 18.675 16.0775 18.3681 16.2151 18.0968C16.2399 18.0642 16.263 18.0298 16.2841 17.9935C16.3044 17.9587 16.3223 17.9232 16.3379 17.8872C16.6969 17.3521 17.3073 17 18 17C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19Z"
          fill="#0C378B"
        />
      </svg>
    </div>
  );
};

export default ShareIcon;
