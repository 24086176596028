/** @format */

import React from "react";

const ArrowBackIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3313 11.2L9.36561 6.16568L8.23424 5.03431L1.26855 12L8.23424 18.9657L9.36561 17.8343L4.3313 12.8L22.3999 12.8V11.2L4.3313 11.2Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};

export default ArrowBackIcon;
