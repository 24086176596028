import request from "api";

export const registerApi = async (data) => {
  return await request.post("/auth/register", data);
};

export const loginApi = async (postBody) => {
  return await request.post("/auth/login", postBody);
};

export const forgotPassword = async (data) => {
  return await request.post("/auth/forgot-password", data);
};

export const onboardingCompletion = async (data) => await request.put("/auth/onboard", data);

export const getUserById = async (id) => {
  return await request.get(`/users/${id}`);
};

export const googleSocialLogin = async (postBody) => {
  return await request.post(`${process.env.REACT_APP_API_URL}/v1/auth/google`, postBody);
};

export function resetPassword({ data, token }) {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const { confirmPassword } = data;
  return axios.post(
    `${process.env.REACT_APP_API_URL}/auth/reset-password`,
    { password: confirmPassword },
    config
  );
}

export function organizationRegister(postBody) {
  return axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/organization/register`, postBody);
}

export function userAvaterUpload(postBody, userId) {
  const headers = {
    userId: `${userId}`,
    name: "profile picture"
  };
  return axios.post(`${process.env.REACT_APP_API_URL}/v1/files/image/upload`, postBody, {
    headers: headers
  });
}

export function getUserFiles(userId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/v1/files/user/${userId}`);
}
