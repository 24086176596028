/** @format */

import React from "react";
import { Spinner } from "@chakra-ui/react";
import { useEducation } from "hooks/user/education";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import EditIcon from "assets/icons/EditIcon";
import EducationItem from "./EducationItem";
import EducationModal from "../../modal/EducationModal";

const Education = () => {
  const {
    educationStore: {
      modal: { isOpen },
      toggleModal
    }
  } = useStore();

  const { data: { data: educationData = [] } = {}, isLoading } = useEducation();

  return (
    <>
      <div className="w-[100%]  relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.1)] rounded-md p-2 px-3 md:p-6 bg-secondary-bg">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center px-2 lg:px-0">
            <h3 className="font-bold leading[27px] py-2 text-[20px] ">Education</h3>
            <button className="lg:mr-2 " onClick={() => toggleModal()}>
              <EditIcon size="18" />
            </button>
          </div>
          <div className="flex flex-col py-2 gap-2 md:gap-6 border-[1px] rounded-[4px] border-[#CCCCCC] pl-3 md:border-none">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            ) : Array.isArray(educationData) && educationData.length > 0 ? (
              educationData.map((education, i) => (
                <EducationItem key={i} education={education} actionable={true} />
              ))
            ) : (
              <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
                No education data available.
              </p>
            )}
          </div>
        </div>
      </div>

      {isOpen && <EducationModal />}
    </>
  );
};

export default observer(Education);
