import ResourceIcon from "assets/icons/ResourceIcon";
import StarRating from "components/Rating";
import ProgressBar from "pages/Dashboard/group/GroupResources/ProgressBar";
import React from "react";

const resourceData = {
  id: "check",
  image: "/images/messageframe.png",
  courseTitle: "General Training Class for Professionals",
  intro: "Startup tools for any successful career path",
  completionPercertage: 50,
  numbersOfModule: 5,
  rating: 4.0
};
export const LearningHeader = () => {
  return (
    <main className="w-full  bg-secondary-bg md:border-1 md:shadow-[1px_2px_4px_rgba(0,0,0,0.3)] md:rounded-md ">
      <section className="md:h-[404px] flex ">
        <div className="w-[40%]">
          <img src={resourceData.image} className="h-[100%] object-contain" />
        </div>

        {/*  */}
        <div className="w-[60%] flex flex-col  justify-between ">
          <div className=" gap-3 flex justify-start ">
            <div className="mt-4 flex flex-col gap-2 p-3 md:w-[100%]">
              <p className="w-[80%] text-[16px] text-[#333] leading-[22px] font-bold">
                {resourceData.courseTitle}
              </p>

              <p className="leading-[24px] font-normal text-[12px] text-[#0C378B]">
                {resourceData.intro}
              </p>

              <div className="flex flex-col gap-3  text-[#808080] text-[14px] leading-5 ">
                <div className="flex gap-4 ">
                  <p className="w-[30%]">Enrollment</p>
                  <p>150474</p>
                </div>
                <div className="flex gap-4 ">
                  <p className="w-[30%]">Module</p>
                  <p>10 modules</p>
                </div>
                <div className="flex gap-4 items-center ">
                  <p className="w-[30%]">Rating</p>
                  <div className="flex gap-2 justify-between items-center ">
                    <StarRating rating={resourceData.rating} maxRating={5} size={10} />
                    <p className=" text-[#808080]  text-[10px] leading-[24px] ">
                      ({resourceData.rating}.0)
                    </p>
                    <p className="text-[8px] px-[7px] border-[1px] rounded-xl border-[#CCCCCC]">
                      Rate this course
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-[100%] h-2 mt-2">
                <ProgressBar percentage={`${resourceData.completionPercertage}%`} />
              </div>
              <div className="flex justify-between items-center text-[#808080]  text-[12px] leading-[24px] ">
                <p className="">{resourceData.completionPercertage}% Complete</p>
              </div>
            </div>
          </div>

          {/* Button */}
          <div className="flex justify-end items-end">
            <div className="flex items-center gap-3 p-4">
              <div className="flex items-center gap-2 rounded-[8px] border-[1px] p-2">
                <ResourceIcon />
                <button>Bookmark</button>
              </div>
              <button className="  rounded-[8px] border-[1px] py-2 px-3 text-[#fff] bg-[#1455D9]">
                Start Course
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
