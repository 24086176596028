import React from "react";
import { Box, Stack, StackDivider } from "@chakra-ui/react";
// import SideBarLink from "./SideBarLink";
import { ProxSureWhiteLogo } from "styles/icons";

export default function SidebarMenu() {
  return (
    <Stack
      align="center"
      py="20px"
      spacing="30px"
      divider={<StackDivider w="90%" alignSelf="none" borderColor="#DFDFDF" />}
    >
      <Box>
        <ProxSureWhiteLogo fontSize="10em" />
      </Box>
      {/* <Stack w="100%" pt="60px">
        {menuItems.map(item => (
          <SideBarLink
            exact
            key={item.label}
            isDisabled={isDisabled}
            label={item.label}
            icon={item.icon}
            route={item.route}
            onCloseMobileMenu={onCloseMobileMenu}
          />
        ))}
      </Stack> */}
    </Stack>
  );
}
