/** @format */

import React from "react";

const GIFIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6001 2.4C1.6001 1.07452 2.67461 0 4.0001 0H17.1315L22.4001 5.26863V21.6C22.4001 22.9255 21.3256 24 20.0001 24H4.0001C2.67461 24 1.6001 22.9255 1.6001 21.6V2.4ZM3.2001 17.6V9.6H8.0001V11.2H4.8001V16H6.4001V13.6H8.0001V17.6H3.2001ZM12.8001 11.2H14.4001V9.6H9.6001V11.2H11.2001V16H9.6001V17.6H14.4001V16H12.8001V11.2ZM20.8001 9.6V11.2H17.6001V12.8H19.2001V14.4H17.6001V17.6H16.0001V9.6H20.8001Z"
          fill="#808080"
        />
      </svg>
    </div>
  );
};

export default GIFIcon;
