/** @format */

import React from "react";

const PhotoIcon = () => {
  return (
    <div>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M27.6 16.4H26V14.8H27.6V16.4Z" fill="#808080" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 12.4C10 11.0745 11.0745 10 12.4 10H31.6C32.9255 10 34 11.0745 34 12.4V31.6C34 31.6052 34 31.6104 34 31.6155C33.9916 32.9339 32.9203 34 31.6 34H12.4C11.4161 34 10.5705 33.408 10.2 32.5607C10.1806 32.5162 10.1624 32.471 10.1456 32.4252C10.1291 32.38 10.1139 32.3342 10.1 32.2878C10.0363 32.0745 10.0015 31.8487 10 31.615C10 31.61 10 31.605 10 31.6V12.4ZM32.4 23.2591L28.4 19.2617L23.6663 23.9923L17.1996 15.9142L11.6 22.9182V12.4C11.6 11.9582 11.9582 11.6 12.4 11.6H31.6C32.0418 11.6 32.4 11.9582 32.4 12.4V23.2591Z"
          fill="#808080"
        />
      </svg>
    </div>
  );
};

export default PhotoIcon;
