/** @format */
import React from "react";

const CopyIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.17157 9.17157C3.92172 8.42143 4.93913 8 6 8H9C9.55229 8 10 7.55228 10 7C10 6.44772 9.55229 6 9 6H6C4.4087 6 2.88258 6.63214 1.75736 7.75736C0.632141 8.88258 0 10.4087 0 12C0 13.5913 0.632141 15.1174 1.75736 16.2426C2.31451 16.7998 2.97595 17.2417 3.7039 17.5433C4.43185 17.8448 5.21207 18 6 18H9C9.55229 18 10 17.5523 10 17C10 16.4477 9.55229 16 9 16H6C5.47471 16 4.95457 15.8965 4.46927 15.6955C3.98396 15.4945 3.54301 15.1999 3.17157 14.8284C2.42143 14.0783 2 13.0609 2 12C2 10.9391 2.42143 9.92172 3.17157 9.17157Z"
          fill="#333333"
        />
        <path
          d="M15 6C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8H18C18.5253 8 19.0454 8.10346 19.5307 8.30448C20.016 8.5055 20.457 8.80014 20.8284 9.17157C21.1999 9.54301 21.4945 9.98396 21.6955 10.4693C21.8965 10.9546 22 11.4747 22 12C22 12.5253 21.8965 13.0454 21.6955 13.5307C21.4945 14.016 21.1999 14.457 20.8284 14.8284C20.457 15.1999 20.016 15.4945 19.5307 15.6955C19.0454 15.8965 18.5253 16 18 16H15C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18H18C18.7879 18 19.5681 17.8448 20.2961 17.5433C21.0241 17.2417 21.6855 16.7998 22.2426 16.2426C22.7998 15.6855 23.2417 15.0241 23.5433 14.2961C23.8448 13.5681 24 12.7879 24 12C24 11.2121 23.8448 10.4319 23.5433 9.7039C23.2417 8.97595 22.7998 8.31451 22.2426 7.75736C21.6855 7.20021 21.0241 6.75825 20.2961 6.45672C19.5681 6.15519 18.7879 6 18 6H15Z"
          fill="#333333"
        />
        <path
          d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};

export default CopyIcon;
