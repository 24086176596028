import { Switch } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { CiDollar } from "react-icons/ci";

const ResourceForm = ({ values, handleChange }) => {
  const [isChecked, setIsChecked] = useState(true);
  return (
    <form className="bg-[#FFFFFF] rounded-[8px] lg:p-4 text-[#333] flex flex-col gap-3 font-normal lg:text-[18px] leading-6">
      {/* Resoures title */}
      <div className="flex flex-col gap-1">
        <label className="">Resource Title</label>
        <input
          type="text"
          name="title"
          title="title"
          onChange={handleChange}
          value={values.title}
          className="w-[100%] rounded-lg border-[1px] border-[#CCCCCC] px-4 py-3"
        />
      </div>

      {/* Resource Description */}
      <div className="flex flex-col gap-1">
        <label className="">Description</label>
        <textarea
          maxLength={200}
          type="text"
          title="description"
          name="description"
          value={values.description}
          onChange={handleChange}
          className="w-[100%] h-64 placeholder:text-[16px] placeholder:font-normal placeholder:text-[#ccc] lg:h-[120px] p-2 rounded-lg  border-[1px] border-[#CCCCCC] px-4 py-3"
        ></textarea>
      </div>

      {/* Course Level */}

      {/* Module */}

      {/* Price  */}
      <div className="w-[100%] flex gap-6 py-4 ">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 ">
            <p className="">Pricing type</p>
            <CiDollar />
          </div>
          <div className="flex items-center justify-center gap-2 border-[1px] border-[#ccc] p-2  rounded-full">
            <p className="text-base font-semibold">Free</p>
            <Switch
              id="price"
              isChecked={isChecked}
              onChange={() => {
                setIsChecked((prev) => !prev);
              }}
            />
          </div>
        </div>
        {/*  */}

        <div className="flex flex-col justify-start gap-2">
          <p>Set price</p>
          <input
            disabled={isChecked}
            name="price"
            title="price"
            value={values.price}
            onChange={handleChange}
            type="number"
            className="placeholder:text-[16px] placeholder:font-normal placeholder:text-[#ccc] rounded-lg bg-[#F3F2F0] lg:w-[100%] w-[170px]   border-[1px] border-[#CCCCCC] px-2 py-2"
          />
        </div>
      </div>
    </form>
  );
};

export default observer(ResourceForm);
