/** @format */

import React from "react";

const RatingIcon = ({ size }) => {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.94838 1.27872C7.86415 1.10805 7.69034 1 7.50001 1C7.30969 1 7.13587 1.10805 7.05165 1.27872L5.00476 5.42621L0.42816 6.09081C0.239806 6.11816 0.0833166 6.25008 0.0244934 6.43109C-0.0343298 6.6121 0.0147161 6.81081 0.151008 6.94366L3.46277 10.1718L2.68076 14.7306C2.64858 14.9182 2.72569 15.1078 2.87966 15.2196C3.03364 15.3315 3.23778 15.3463 3.40624 15.2577L7.50001 13.1054L11.5938 15.2577C11.7623 15.3463 11.9664 15.3315 12.1204 15.2196C12.2743 15.1078 12.3515 14.9182 12.3193 14.7306L11.5373 10.1718L14.849 6.94366C14.9853 6.81081 15.0344 6.6121 14.9755 6.43109C14.9167 6.25008 14.7602 6.11816 14.5719 6.09081L9.99527 5.42621L7.94838 1.27872Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  );
};

export default RatingIcon;
