import { makeAutoObservable, configure } from "mobx";
import webSocketService from "services/WebSocketService";

configure({
  // enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true
});

class ChatStore {
  socket = null;
  conversations = new Map();
  activeConversations = new Map();

  constructor(rootStore) {
    makeAutoObservable(this);
    const { authService } = rootStore;
    this.authService = authService;
    this.rootStore = rootStore;
  }

  initSocket() {
    // Initialize Socket.IO connection
    this.socket = webSocketService.connect();
    this.setupWebSocketListeners();
  }

  setupWebSocketListeners() {
    webSocketService.subscribeToEvent("conversations", (conversations) => {
      // Handle new conversations from the WebSocket service
      this.setConversations(conversations);
    });

    webSocketService.subscribeToEvent("typingStatus", (data) => {
      const { conversationId, userId, isTyping } = data;
      const user = this.authService.getUser();
      if (!user || user.userId === userId) return;
      const activeConversation = this.activeConversations.get(conversationId);
      if (activeConversation) {
        activeConversation.typing = {
          isTyping,
          user: this.rootStore.chatService.getUser(activeConversation.users, userId)
        };
      }
    });
    // webSocketService.subscribeToEvent("conversation", (conversations) => {
    //   // Handle new conversations from the WebSocket service
    //   this.setConversations(conversations);
    // });

    webSocketService.subscribeToEvent("newMessage", (data) => {
      const { message, user, uniqueId } = data;
      const conversation = this.conversations.get(message.conversation.id);

      if (conversation) {
        this.addMessageToConversation(conversation, message, user, uniqueId);
      } else {
        const conversation = message.conversation;
        this.setConversation(conversation);
        this.addToActiveConversations(conversation);
      }
    });
  }

  setConversations = (conversations) => {
    if (!conversations) {
      console.error("Invalid conversations data:", conversations);
      return;
    }
    conversations.forEach((conversation) => {
      this.conversations.set(conversation.id, conversation);
    });
  };
  setConversation = (conversation) => {
    if (!conversation) {
      console.error("Invalid conversation data:", conversation);
      return;
    }
    this.conversations.set(conversation.id, conversation);
  };
  setActiveConversations(activeConversations) {
    activeConversations.forEach((conversation) => {
      // Check if the conversation ID exists in the map
      if (!this.activeConversations.has(conversation.id)) {
        this.activeConversations.set(conversation.id, conversation);
      }
    });
  }
  addToActiveConversations = (conversation) => {
    if (!this.activeConversations.has(conversation.id)) {
      this.activeConversations.set(conversation.id, conversation);
    }
  };
  joinConversation = (friendId) => {
    const conversation = Array.from(this.conversations.values()).find((conversation) =>
      conversation.users.some((user) => user.userId === friendId)
    );

    if (conversation) {
      const activeConversation = this.activeConversations.get(conversation.id);

      if (!activeConversation) {
        const user = this.rootStore.chatService.getUser(conversation.users, friendId);
        if (!user) return;
        const fetchedConversation = { ...conversation, ...user };
        this.activeConversations.set(fetchedConversation.id, fetchedConversation);
        webSocketService.emit("joinRoom", { conversationId: conversation.id });
        return;
      }
    } else {
      webSocketService.emit("joinConversation", { friendId });
    }
  };
  userTyping = (conversationId, isTyping) => {
    const user = this.authService.getUser();
    if (!user) return;
    const { user: currentUser } = user;
    webSocketService.emit("typing", {
      conversationId,
      userId: currentUser.userId,
      isTyping
    });
  };
  sendMessage = (payload) => {
    const { message, conversation: conversationId } = payload;

    // Update the local conversation with the new message
    const conversation = this.conversations.get(conversationId);
    webSocketService.emit("sendMessage", payload);

    // Ensure the conversation exists before attempting to join
    if (!conversation) {
      webSocketService.emit("joinConversation", { conversationId });
    }

    // Join the room associated with the conversation
    webSocketService.emit("joinRoom", { conversationId });
  };
  addMessageToConversation = (conversation, message, user, uniqueId = "") => {
    conversation.messages.push(message);
    conversation.lastUpdated = message.createdAt;
    conversation.lastMessage = message.message;
    conversation.lastSender = user.fullName;

    this.sortConversations();

    const activeConversation = this.activeConversations.get(conversation.id);
    if (activeConversation && uniqueId.trim() !== "") {
      message.status = true;
      const existingMessageIndex = activeConversation.messages.findIndex(
        (msg) => msg.uniqueId === uniqueId
      );
      if (existingMessageIndex !== -1) {
        activeConversation.messages[existingMessageIndex] = message;
      } else {
        activeConversation.messages.push(message);
      }
    }
  };
  sortConversations = () => {
    const sortedConversations = Array.from(this.conversations.values()).sort(
      (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
    );
    this.conversations = new Map(
      sortedConversations.map((conversation) => [conversation.id, conversation])
    );
  };
  generateUniqueId() {
    // Create a unique ID based on the current timestamp
    return new Date().getTime().toString();
  }
  closeConversation = (id) => {
    const activeConversation = this.activeConversations.get(id);
    if (activeConversation) {
      this.activeConversations.delete(id);
    }
  };
  get sortedActiveConversations() {
    return Array.from(this.activeConversations.values()).sort(
      (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
    );
  }
}

export default ChatStore;
