/** @format */

import React from "react";

const HomeIcon = () => {
  return (
    <div>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1139 1.21065C12.7528 0.929784 12.2472 0.929784 11.8861 1.21065L2.88606 8.21065C2.64247 8.4001 2.5 8.69141 2.5 9V20C2.5 20.7957 2.81607 21.5587 3.37868 22.1213C3.94129 22.6839 4.70435 23 5.5 23H19.5C20.2957 23 21.0587 22.6839 21.6213 22.1213C22.1839 21.5587 22.5 20.7957 22.5 20V9C22.5 8.69141 22.3575 8.4001 22.1139 8.21065L13.1139 1.21065ZM16.5 21H19.5C19.7652 21 20.0196 20.8946 20.2071 20.7071C20.3946 20.5196 20.5 20.2652 20.5 20V9.48908L12.5 3.26686L4.5 9.48908V20C4.5 20.2652 4.60536 20.5196 4.79289 20.7071C4.98043 20.8946 5.23478 21 5.5 21H8.5V12C8.5 11.4477 8.94772 11 9.5 11H15.5C16.0523 11 16.5 11.4477 16.5 12V21ZM10.5 21V13H14.5V21H10.5Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default HomeIcon;
