/** @format */

import React from "react";
import FriendsProfileCard from "../usable/FriendsProfileCard";

import ShapeIcon from "assets/icons/ShapeIcon";
import MyNetworkIcon from "assets/icons/MyNetworkIcon";
import useGetFriends from "hooks/friend";
import Spinner from "components/Spinner";
import { useStore } from "store";

const Networks = () => {
  const {
    chatStore: { joinConversation }
  } = useStore();
  const { data: { data: friends = [] } = {}, isLoading } = useGetFriends();

  const messageUser = React.useCallback(
    (friendId) => {
      joinConversation(friendId);
    },
    [joinConversation]
  );

  return (
    <div className="w-full flex flex-col gap-4 py-3">
      {isLoading ? (
        <Spinner />
      ) : friends.length > 0 ? (
        friends.map((friend) => (
          <div key={friend?.id} className="flex h-[72px] justify-between items-center">
            <FriendsProfileCard data={friend} />
            <div className="flex gap-4">
              <button className="rounded-[5px] flex gap-2 text-[12px] md:text-[14px] font-bold leading-[21px] text-[#0C378B]  border-[1px] border-[#0C378B] px-[16px] py-[10px] justify-center items-center">
                <MyNetworkIcon />
                <p className="hidden md:block">Connected</p>
              </button>
              <button
                className="px-[16px] rounded-[5px] py-[10px] bg-[#1455D9] flex justify-center items-center"
                onClick={() => messageUser(friend.userId)}
              >
                <ShapeIcon />
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-[16px] text-center mt-5 text-[#333]">No friends found.</div>
      )}
    </div>
  );
};

export default Networks;
