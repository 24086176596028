/** @format */

import React from "react";
import UserTemplate from "../UserTemplate/UserTemplate";

const SearchDisplay = ({ data = [], handleSeleted }) => {
  return (
    <div className="md:h-[316px] selected-scrolling overscroll-auto overflow-auto w-[100%] flex flex-col gap-2">
      {data?.map((element, i) => (
        <div key={i} className="flex gap-3 items-start w-[100%] hover:bg-[#F3F2F0] px-2 rounded-md">
          <div className="w-[100%] items-center flex gap-3 py-2">
            <input
              type="checkbox"
              className=" w-[40px] h-[40px] border-[1px] rounded-[4px] border-[#333] appearance-none indeterminate:bg-gray custom-check"
            />
            <UserTemplate data={element} />
          </div>
          <p className=" pt-4 font-semibold text-[#808080] text-[14px] leading-[16px]">Members</p>
        </div>
      ))}
    </div>
  );
};

export default SearchDisplay;
