import { useQuery } from "@tanstack/react-query";
import { getSearch } from "api/search";

/**
 * Custom hook to fetch search results based on the provided query and optional pagination parameters.
 *
 * @param {string} queryString - The search query string.
 * @param {number} [page=1] - The page number for pagination. Defaults to 1 if not provided.
 * @param {number} [limit=10] - The limit of items per page. Defaults to 10 if not provided.
 * @returns {Object} An object containing data, loading state, and refetch function.
 */
export const useSearch = (queryString, page = 1, limit = 10) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["search", queryString, page, limit],
    queryFn: () => getSearch(queryString, page, limit)
  });

  return { data, isLoading, refetch };
};
