/** @format */

import React, { useState } from "react";
import ArrowUp from "assets/icons/ArrowUp";
import GIFIcon from "assets/icons/GIFIcon";
import MessageMoreIcon from "assets/icons/MessageMoreIcon";
import PhotoIcon from "assets/icons/PhotoIcon";
import PinIcon from "assets/icons/PinIcon";
import SmillingEmojiIcon from "assets/icons/SmillingEmojiIcon";

const ChatInput = () => {
  const [input, setInput] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    setInput("");
  };
  return (
    <div className="h-full w-full px-5 py-3">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div className="flex gap-2 border-b-1 border-[#808080] py-3">
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full h-[90px] lg:h-[130px] text-[#333] text-[14px] font-bold leading-[21px] focus:border-[#ccc] outline-none  rounded-lg border-1 border-[#ccc] bg-[#ccc] p-2"
              placeholder="Write a message..."
            />
            <ArrowUp />
          </div>

          <div className="flex justify-between items-center  px-1">
            <div className="flex items-center py-4 gap-4">
              <div>
                <PhotoIcon />
              </div>
              <div>
                <PinIcon />
              </div>

              <div>
                <GIFIcon />
              </div>
              <div>
                <SmillingEmojiIcon />
              </div>
            </div>

            <div className="flex gap-3 items-center">
              <button
                onClick={handleSubmit}
                type="submit"
                className="bg-[#1455D9] rounded-[25px] px-[24px] py-[11px] text-white text-[14px] font-bold leading-[21px]"
              >
                send
              </button>
              <MessageMoreIcon />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatInput;
