/** @format */

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

const CreatableSkill = ({ skills, setSkills }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (!inputValue) return;

    switch (e.key) {
      case "Enter":
      case "Tab":
        e.preventDefault();
        handleChange(inputValue);
        setInputValue(""); // Reset the input value
        break;
      default:
        return;
    }
  };

  const handleChange = (inputValue) => {
    if (inputValue) {
      setSkills([...skills, inputValue]);
    }
  };

  const selectedSkills = skills && skills.map((skill) => createOption(skill));

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      name="skill"
      menuIsOpen={false}
      onInputChange={(value) => setInputValue(value)}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press Enter..."
      value={selectedSkills}
      className="leading-[20px] font-semibold text-[14px]"
    />
  );
};

export default observer(CreatableSkill);
