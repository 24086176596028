/** @format */

import React from "react";

const PollIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 3.00003C13.0523 3.00003 13.5 3.44775 13.5 4.00003V20C13.5 20.5523 13.0523 21 12.5 21C11.9477 21 11.5 20.5523 11.5 20V4.00003C11.5 3.44775 11.9477 3.00003 12.5 3.00003Z"
          fill="#8C2C1B"
        />
        <path
          d="M18.5 9.00003C19.0523 9.00003 19.5 9.44775 19.5 10V20C19.5 20.5523 19.0523 21 18.5 21C17.9477 21 17.5 20.5523 17.5 20V10C17.5 9.44775 17.9477 9.00003 18.5 9.00003Z"
          fill="#8C2C1B"
        />
        <path
          d="M7.5 14C7.5 13.4477 7.05228 13 6.5 13C5.94772 13 5.5 13.4477 5.5 14V20C5.5 20.5523 5.94772 21 6.5 21C7.05228 21 7.5 20.5523 7.5 20V14Z"
          fill="#8C2C1B"
        />
        <rect x="1.5" y="1.00003" width="20" height="20" rx="3" stroke="#8C2C1B" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default PollIcon;
