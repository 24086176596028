/** @format */

import React from "react";

const ShowMoreIcon = () => {
  return (
    <div>
      <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L0.292893 6.29289C-0.0976311 6.68342 -0.0976311 7.31658 0.292893 7.70711C0.683417 8.09763 1.31658 8.09763 1.70711 7.70711L7 2.41421L12.2929 7.70711C12.6834 8.09763 13.3166 8.09763 13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289L7.70711 0.292893Z"
          fill="#1455D9"
        />
      </svg>
    </div>
  );
};

export default ShowMoreIcon;
