import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
  addUserExperience,
  getUserExpirence,
  deleteUserExperience,
  editUserExperience
} from "api/user";
import { toast } from "react-toastify";

export const useExperience = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => getUserExpirence(),
    queryKey: ["experience"]
  });
  return {
    data,
    isLoading
  };
};

export const useAddExperinceMutation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: addUserExperience,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["experience"]);
      onSuccessCallback();
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const addExperience = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: addExperience
  };
};
export const useEditExperienceMutation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: editUserExperience,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["experience"]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const editExperience = (body) => {
    mutate.mutate(body);
  };

  return {
    mutate: editExperience
  };
};

export const useExperienceDeleteMututation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: deleteUserExperience,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["experience"]);
      onSuccessCallback();
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const deleteExperience = (id) => {
    mutate.mutate(id);
  };

  return {
    mutate: deleteExperience
  };
};
