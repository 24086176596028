import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { createComment, createPost, getPost, performAction } from "api/feed";

export const useGetPost = (skip) => {
  const query = useQuery(["getPost", skip], () => getPost({ skip }));
  return query;
};

export const useCreatePost = () => {
  const queryClient = useQueryClient();

  const mutate = async ({ postData, refetchKeys = [] }) => {
    try {
      const data = await createPost(postData);
      toast(data?.message);
      // Invalidate and refetch queries specified in refetchKeys
      if (refetchKeys.length > 0) {
        queryClient.invalidateQueries(refetchKeys);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { mutate };
};
export const useCreateComment = (refetchKeys = []) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(({ commentData, type }) => createComment(commentData, type), {
    onSuccess: (data) => {
      toast(data?.message);
      if (refetchKeys.length > 0 && data.status) {
        queryClient.invalidateQueries(refetchKeys);
      }
    },
    onError: (data) => {
      console.log(data);
      toast.error(data?.message);
    }
  });

  return mutation;
};

export const useFeedAction = (refetchKeys = []) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, type, targetType, action }) => performAction(id, type, targetType, action),
    {
      onSuccess: (data) => {
        toast(data?.message);
        // Invalidate and refetch
        if (refetchKeys.length > 0 && data.status) {
          queryClient.invalidateQueries(refetchKeys);
        }
      },
      onError: (data) => console.log(data)
    }
  );

  return mutation;
};
