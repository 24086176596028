/** @format */

import React from "react";

const FeedImage = ({ src }) => {
  return (
    <div className="w-full">
      <img src={src} alt="feed-image" className="w-full  md:h-[427px] object-cover " />
    </div>
  );
};

export default FeedImage;
