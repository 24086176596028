import React from "react";
import EmptyStateImage from "../../assets/images/empty-state-feeds.png";
import { useLocation } from "react-router-dom";
const EmptyState = () => {
  const { pathname } = useLocation();
  const checkPath = pathname == "/app/timeline";

  return (
    <div className="container bg-white text-center w-[100%] lg:h-[657px] flex justify-center items-center">
      <div className=" w-[90%] lg:w-[70%] lg:h-[70%]">
        {!checkPath && <p>ProXsure takes you through a life development program</p>}
        <div className=" flex flex-col justify-center items-center">
          <img
            className="object-cover"
            src={checkPath ? "/images/EmptyTimeline.png" : EmptyStateImage}
            alt="empty state"
          />
        </div>
        {checkPath ? (
          <div className=" mt-4 gap-6 flex flex-col justify-center items-center">
            <p className="md:text-[16px] leading-[27px] font-semibold">
              Who do you want to see on your timeline?
            </p>

            <button className="bg-[#1455D9] text-white px-[22px] py-[11px] rounded-[8px] ">
              Explore
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmptyState;
