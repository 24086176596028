/** @format */
// import { useHistory } from "react-router";
import { replace } from "formik";
import { useNavigate } from "react-router";
import AuthServiceInstance from "services";

export const useLogout = () => {
  const navigate = useNavigate();

  const logOut = () => {
    navigate("/auth/sign-in");
    AuthServiceInstance.deleteToken();
  };

  return {
    logOut
  };
};
