import { dummyProfile } from "constant/profile";
import React from "react";

const UserTemplate = ({ data }) => {
  return (
    <div className="flex gap-6 items-center">
      <img src={data?.image || dummyProfile.src} className="h-[57px] w-[58px] rounded-full" />

      <div className="flex flex-col justify-start">
        <p className=" text-[16px] text-[#333333] font-semibold ">{data?.name}</p>

        <p className="text-[14px] text-[#808080] leading-[19px] font-semibold ">HR @Google</p>
      </div>
    </div>
  );
};

export default UserTemplate;
