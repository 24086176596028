/** @format */

import React from "react";
import {
  Button,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Modal,
  ModalCloseButton,
  ModalHeader,
  Text
} from "@chakra-ui/react";
import useWidth from "utils/useWidth";

const GroupDescriptionModal = ({ description, isOpen, onClose, toggle }) => {
  const { size } = useWidth("xs", "2xl");

  return (
    <>
      <Modal
        onClose={() => {
          onClose;
          toggle();
        }}
        size={size}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p className="text-[20px] leading-[27px] md:text-[25px] md:leading-[34px] text-[#333] font-[400]">
              About this group
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex flex-col gap-2 itmes-start text-[12px] font-[400] leading-[16px]">
              <h2 className=" text-[14px] text-[#333] leading-[21px] font-semibold">Description</h2>

              <Text>{description}</Text>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose;
                toggle();
              }}
              width={{ base: "100%", md: "84px" }}
              lineHeight={{ md: "21px" }}
              fontSize={{ base: "12", md: "16px" }}
              fontWeight="bold"
              backgroundColor="#1455D9"
              color="#fff"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GroupDescriptionModal;
