import axios from "axios";
import { config } from "../config";
import AuthServiceInstance from "../services";

const request = axios.create({
  baseURL: `${config.baseURL}/api`,
  headers: {
    "Content-Type": "application/json"
  }
});

request.interceptors.request.use(
  async (config) => {
    // Add the access token to the request headers if the user is authenticated
    let refreshToken, accessToken;
    const tokens = AuthServiceInstance.getTokens();
    if (tokens) {
      accessToken = tokens.accessToken;
      refreshToken = tokens.refreshToken;
    }
    if (AuthServiceInstance.isAuthenticated()) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    } else {
      if (refreshToken) {
        const getAccessToken = await AuthServiceInstance.refreshAccessToken();
        if (getAccessToken) {
          config.headers["Authorization"] = `Bearer ${getAccessToken}`;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response && error.response.status === 401 && AuthServiceInstance.isAuthenticated()) {
      const { accessToken, refreshToken } = AuthServiceInstance.getTokens();
      const isTokenExpired = AuthServiceInstance.isTokenExpired(accessToken);
      const isRefreshTokenExpired = AuthServiceInstance.isTokenExpired(refreshToken);
      if (isTokenExpired && !isRefreshTokenExpired) {
        try {
          const response = await AuthServiceInstance.refreshAccessToken(refreshToken);
          AuthServiceInstance.setTokens(response.accessToken, response.refreshToken);
          const newAccessToken = response.accessToken;
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
          // Retry the request with the new access token
          return axios(error.config);
        } catch (refreshError) {
          // Handle the token refresh error, log or display an error message
          console.error("Token refresh failed:", refreshError);
          // Optionally, you can redirect to a login page or show a user-friendly error message
        }
      }
    }
    // For other types of errors, log or handle them as appropriate
    console.error("Request failed:", error);
    // You can return a custom error or reject the promise with the error
    return Promise.reject(error);
  }
);

export const makeRequest = (method, url, data = {}, customHeaders = {}) => {
  return request({
    method,
    url,
    data,
    headers: {
      ...request.defaults.headers.common,
      ...customHeaders
    }
  });
};

export default request;
