/** @format */

import React from "react";

const DashboardIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_2373_870" fill="white">
          <rect x="2" y="2" width="9" height="11" rx="1" />
        </mask>
        <rect
          x="2"
          y="2"
          width="9"
          height="11"
          rx="1"
          stroke="#0C378B"
          strokeWidth="4"
          mask="url(#path-1-inside-1_2373_870)"
        />
        <mask id="path-2-inside-2_2373_870" fill="white">
          <rect x="14" y="2" width="9" height="7" rx="1" />
        </mask>
        <rect
          x="14"
          y="2"
          width="9"
          height="7"
          rx="1"
          stroke="#0C378B"
          strokeWidth="4"
          mask="url(#path-2-inside-2_2373_870)"
        />
        <mask id="path-3-inside-3_2373_870" fill="white">
          <rect x="14" y="12" width="9" height="11" rx="1" />
        </mask>
        <rect
          x="14"
          y="12"
          width="9"
          height="11"
          rx="1"
          stroke="#0C378B"
          strokeWidth="4"
          mask="url(#path-3-inside-3_2373_870)"
        />
        <mask id="path-4-inside-4_2373_870" fill="white">
          <rect x="2" y="16" width="9" height="7" rx="1" />
        </mask>
        <rect
          x="2"
          y="16"
          width="9"
          height="7"
          rx="1"
          stroke="#0C378B"
          strokeWidth="4"
          mask="url(#path-4-inside-4_2373_870)"
        />
      </svg>
    </div>
  );
};

export default DashboardIcon;
