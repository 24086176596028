/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addFriends,
  exploreOtherFriends,
  getFriendRequestType,
  getMyFriends,
  respondToFriendRequest
} from "api/friends";
import { toast } from "react-toastify";

export const useGetFriends = () => {
  const query = useQuery({
    queryKey: ["getFriends"],
    queryFn: () => getMyFriends(),
    staleTime: 10000
  });
  return query;
};

export default useGetFriends;
export const useGetConnect = () => {
  const query = useQuery({
    queryKey: ["connectRequests"],
    queryFn: () => getFriendRequestType("connect"),
    staleTime: 10000
  });

  return query;
};
export const useGetSentRequest = () => {
  const query = useQuery({
    queryKey: ["sentRequests"],
    queryFn: () => getFriendRequestType("sent")
  });

  return query;
};

export const useRespondToRequest = (refetchKeys = []) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: respondToFriendRequest,
    onSuccess: (data) => {
      toast(data?.message);
      data.status &&
        refetchKeys.length > 0 &&
        refetchKeys.forEach((key) => {
          queryClient.invalidateQueries([key]);
        });
    }
  });

  const requestResponseType = (status, id) => {
    return mutate.mutate({ status, id });
  };

  return {
    mutate: requestResponseType
  };
};

export const useAddFriendRequest = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: addFriends,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["requestType"]);
    }
  });

  const sendFriendsRequest = (param) => {
    return mutate.mutate(param);
  };

  return {
    mutate: sendFriendsRequest
  };
};

export const useExploreOthers = () => {
  const query = useQuery(["exploreOthers"], exploreOtherFriends, { staleTime: 10000 });
  return query;
};
