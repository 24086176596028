/** @format */

import React from "react";

const EditIcon = ({ size = "18", color = "#0C378B" }) => {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 1.37866C19.1722 1.37866 18.3783 1.70751 17.7929 2.29288L8.29289 11.7929C8.16473 11.921 8.07382 12.0816 8.02986 12.2574L7.02986 16.2574C6.94466 16.5982 7.04451 16.9587 7.29289 17.2071C7.54127 17.4555 7.90176 17.5553 8.24254 17.4701L12.2425 16.4701C12.4184 16.4262 12.5789 16.3352 12.7071 16.2071L22.2071 6.70709C22.7925 6.12173 23.1213 5.32781 23.1213 4.49998C23.1213 3.67216 22.7925 2.87824 22.2071 2.29288C21.6217 1.70751 20.8278 1.37866 20 1.37866ZM19.2071 3.70709C19.4174 3.4968 19.7026 3.37866 20 3.37866C20.2974 3.37866 20.5826 3.4968 20.7929 3.70709C21.0032 3.91738 21.1213 4.20259 21.1213 4.49998C21.1213 4.79737 21.0032 5.08259 20.7929 5.29288L11.4888 14.597L9.37437 15.1256L9.90296 13.0112L19.2071 3.70709Z"
          fill={color}
        />
        <path
          d="M4 3.5C3.20435 3.5 2.44129 3.81607 1.87868 4.37868C1.31607 4.94129 1 5.70435 1 6.5V20.5C1 21.2957 1.31607 22.0587 1.87868 22.6213C2.44129 23.1839 3.20435 23.5 4 23.5H18C18.7957 23.5 19.5587 23.1839 20.1213 22.6213C20.6839 22.0587 21 21.2957 21 20.5V13.5C21 12.9477 20.5523 12.5 20 12.5C19.4477 12.5 19 12.9477 19 13.5V20.5C19 20.7652 18.8946 21.0196 18.7071 21.2071C18.5196 21.3946 18.2652 21.5 18 21.5H4C3.73478 21.5 3.48043 21.3946 3.29289 21.2071C3.10536 21.0196 3 20.7652 3 20.5V6.5C3 6.23478 3.10536 5.98043 3.29289 5.79289C3.48043 5.60536 3.73478 5.5 4 5.5H11C11.5523 5.5 12 5.05228 12 4.5C12 3.94772 11.5523 3.5 11 3.5H4Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default EditIcon;
