/** @format */

import CancelIcon from "assets/icons/CancelIcon";
import React from "react";

const SelectedUser = ({ data = [] }) => {
  return (
    <div className="flex flex-col gap-4 selected-scrolling overscroll-auto overflow-auto w-[100%]">
      {data?.map((element, i) => (
        <div key={i} className="flex justify-between items-center">
          <div className=" flex items-center gap-3">
            <img
              src={element?.image}
              alt={element?.name}
              className="w-[48px] h-[48px] object-cover rounded-full"
            />
            <p className="text-[16px] text-[#333333] leading-[21px] ">{element?.name}</p>
          </div>
          <p className=" p-2 rounded-full hover:bg-[#F3F2F0]">
            <CancelIcon />
          </p>
        </div>
      ))}
    </div>
  );
};

export default SelectedUser;
