import React from "react";
import GlobeIcon from "assets/icons/GlobeIcon";
import PlusIcon from "assets/icons/PlusIcon";
import MoreIcon from "assets/icons/MoreIcon";
import { getTimeAgoFromUpdated } from "utils/helpers";
import { dummyProfile } from "constant/profile";
import { useStore } from "store";
import { Link } from "react-router-dom";

const FeedProfile = ({ owner, createdAt, updatedAt }) => {
  const { profile, userId } = owner;
  const fullname = `${profile.lastName} ${profile.firstName}`;

  const { authService } = useStore();
  const currentAccount = authService.isCurrentUser(userId);

  return (
    <div className="px-4 w-full h-full relative ">
      <div className="flex justify-between items-start">
        <Link to={`/app/${userId}/profile`}>
          <div className="flex gap-2 items-center">
            <img
              src={profile.profilePic ?? dummyProfile?.src}
              alt="group-admin-image"
              className=" rounded-full md:w-[60px] h-[64px] w-[64px] md:h-[60px]"
            />
            <div className=" flex flex-col">
              <h2 className="text-[14px] leading-[19px] font-bold text-[#333]">{fullname}</h2>
              <p className="text-[12px] text-[#808080] leading-[16px] font-semibold">
                {`${profile?.jobTitle || ""} ${profile?.industry ? `@${data.industry}` : ""}`}
              </p>
              <div className="flex items-center text-[12px] ] font-bold leading-[19px]">
                <span className=" text-[#0C378B]">Admin</span> .{" "}
                {getTimeAgoFromUpdated(createdAt, updatedAt)} .
                <span>
                  <GlobeIcon />
                </span>
              </div>
            </div>
          </div>
        </Link>

        {!currentAccount && (
          <div className="flex justify-between gap-2 md:w-[145px] items-center md:h-[44px]">
            <div className="flex gap-2 items-center">
              <PlusIcon color="#0C378B" /> <p className="text-[#0C378B] md:block hidden">Connect</p>
            </div>
            <div>
              <MoreIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedProfile;
