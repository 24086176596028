/* eslint-disable prettier/prettier */
import React from "react";
import { useLocation } from "react-router-dom";
const ResourceSideIcon = () => {
  const { pathname } = useLocation();
  const color = pathname == "/app/resoures" ? "#fff" : "#0c378b";

  return (
    <svg
      width="18"
      color={color}
      height="22"
      viewBox="0 0 18 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1C18 0.447715 17.5523 0 17 0H3.5C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5V18.5C0 19.4283 0.368749 20.3185 1.02513 20.9749C1.6815 21.6313 2.57174 22 3.5 22H17C17.5523 22 18 21.5523 18 21V1ZM2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2H16V15H3.5C2.97616 15 2.46443 15.1174 2 15.3377V3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934ZM2 18.5C2 18.8978 2.15804 19.2794 2.43934 19.5607C2.72064 19.842 3.10218 20 3.5 20H16V17H3.5C3.10218 17 2.72064 17.158 2.43934 17.4393C2.15804 17.7206 2 18.1022 2 18.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ResourceSideIcon;
