import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import PostPanel from "components/Feed/PostPanel";
import { useGroupFeeds } from "hooks/group";
import Spinner from "components/Spinner";
import { useCreateComment } from "hooks/feeds";
import EmptyState from "components/EmptyState";

const GroupFeed = ({ groupId }) => {
  const [page, setPage] = useState(0);
  const { data: { data } = {}, isLoading, isError, error } = useGroupFeeds(groupId, page, 10);

  // Check if data exists and has the 'posts' property before accessing it
  const { posts = [], count: totalCount = 0 } = data || {};
  const hasMore = posts.length * page < totalCount;

  const loadMore = () => {
    setPage(page + 1);
  };

  const mutation = useCreateComment(["fetchGroupFeeds"]);
  const saveComment = async (e) => {
    try {
      return mutation.mutate({ commentData: e, type: "group" });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="">
      <InfiniteScroll
        dataLength={totalCount}
        next={loadMore}
        hasMore={hasMore}
        loader={<Spinner />}
        endMessage={<EmptyState />}
      >
        {posts.map((post) => (
          <PostPanel key={post.id} post={post} postType="group" saveComment={saveComment} />
        ))}
      </InfiniteScroll>

      {/* {isLoading && <Spinner />}
      {isError && <div>Error loading posts: {error.message}</div>} */}
    </div>
  );
};

export default GroupFeed;
