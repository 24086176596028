/** @format */

import React from "react";

const BellIcon = ({ color = "#0C378B" }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 1C10.1436 1 8.36309 1.7375 7.05033 3.05025C5.73758 4.36301 5.00008 6.14348 5.00008 8C5.00008 11.3527 4.28301 13.4346 3.62218 14.6461C3.29039 15.2544 2.9673 15.6535 2.74288 15.8904C2.6304 16.0091 2.54202 16.0878 2.4888 16.1322C2.46218 16.1544 2.44432 16.168 2.43663 16.1737L2.43377 16.1758C2.07528 16.4221 1.91664 16.8725 2.04299 17.2898C2.17068 17.7115 2.55941 18 3.00008 18H21.0001C21.4407 18 21.8295 17.7115 21.9572 17.2898C22.0835 16.8725 21.9249 16.4221 21.5664 16.1758L21.5635 16.1737C21.5558 16.168 21.538 16.1544 21.5114 16.1322C21.4581 16.0878 21.3698 16.0091 21.2573 15.8904C21.0329 15.6535 20.7098 15.2544 20.378 14.6461C19.7171 13.4346 19.0001 11.3527 19.0001 8C19.0001 6.14349 18.2626 4.36301 16.9498 3.05025C15.6371 1.7375 13.8566 1 12.0001 1ZM18.6222 15.6039C18.6983 15.7434 18.7747 15.8753 18.8508 16H5.1494C5.22549 15.8753 5.30188 15.7434 5.37797 15.6039C6.21715 14.0654 7.00008 11.6473 7.00008 8C7.00008 6.67392 7.52686 5.40215 8.46454 4.46447C9.40223 3.52678 10.674 3 12.0001 3C13.3262 3 14.5979 3.52678 15.5356 4.46447C16.4733 5.40215 17.0001 6.67392 17.0001 8C17.0001 11.6473 17.783 14.0654 18.6222 15.6039Z"
          fill={color}
        />
        <path
          d="M11.1351 20.4982C10.858 20.0205 10.2461 19.8579 9.76833 20.135C9.2906 20.4121 9.12798 21.024 9.4051 21.5018C9.66881 21.9564 10.0473 22.3337 10.5028 22.5961C10.9582 22.8584 11.4745 22.9965 12.0001 22.9965C12.5257 22.9965 13.042 22.8584 13.4974 22.5961C13.9529 22.3337 14.3314 21.9564 14.5951 21.5018C14.8722 21.024 14.7096 20.4121 14.2319 20.135C13.7541 19.8579 13.1422 20.0205 12.8651 20.4982C12.7772 20.6498 12.651 20.7756 12.4992 20.863C12.3474 20.9504 12.1753 20.9965 12.0001 20.9965C11.8249 20.9965 11.6528 20.9504 11.501 20.863C11.3492 20.7756 11.223 20.6498 11.1351 20.4982Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default BellIcon;
