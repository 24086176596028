import React, { useEffect, useState } from "react";
import DesktopComponent from "./userBoard-Component/DesktopComponent";
import MobileComponent from "./userBoard-Component/MobileComponent";

const UserBoard = ({ data }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWidth = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    document.addEventListener("resize", handleWidth());

    return () => document.removeEventListener("resize", handleWidth());
  }, [width]);

  return (
    <div className="w-[100%]">
      {width > 700 ? <DesktopComponent data={data} /> : <MobileComponent data={data} />}
    </div>
  );
};

export default UserBoard;
