/** @format */

import React from "react";
import UserBoard from "../usable/UserBoard";
import { useGetConnect } from "hooks/friend";
import Spinner from "components/Spinner";

const ConnectRequest = () => {
  const { data: { data: connectData = [] } = {}, isLoading } = useGetConnect();

  return (
    <div className="w-[100%] md:h-[633px] scrolling-pg grid py-3 ">
      <div className="flex gap-4 flex-col items-center mb-[1rem] scrolling-pg">
        {isLoading ? (
          <Spinner />
        ) : connectData.length > 0 ? (
          connectData.map((user) => (
            <div key={user.id} className=" md:h-[72px] w-[100%]">
              <div>
                <UserBoard data={user} />
              </div>
            </div>
          ))
        ) : (
          <p>No pending connection requests.</p>
        )}
      </div>
    </div>
  );
};

export default ConnectRequest;
