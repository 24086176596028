// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.about-content .container {
  position: relative;
  overflow: hidden;
}

.about-content .container::before {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  background: linear-gradient(transparent, #fff);
}

@media (max-width: 750px) {
  .about-content .container::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    background: linear-gradient(transparent, #fff);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/profile/component/about/about.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,8CAA8C;AAChD;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8CAA8C;EAChD;AACF","sourcesContent":["/** @format */\n\n.about-content .container {\n  position: relative;\n  overflow: hidden;\n}\n\n.about-content .container::before {\n  content: \" \";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 15rem;\n  background: linear-gradient(transparent, #fff);\n}\n\n@media (max-width: 750px) {\n  .about-content .container::before {\n    content: \" \";\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 4rem;\n    background: linear-gradient(transparent, #fff);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
