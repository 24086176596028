/** @format */

import React from "react";

const MessageIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5049 3.99997C8.36549 3.99997 5.00979 7.35566 5.00979 11.4951C5.00979 12.6932 5.29018 13.823 5.788 14.825C5.90562 15.0618 5.92472 15.3354 5.84112 15.5862L4.57227 19.3926L8.41716 18.1529C8.66577 18.0727 8.93587 18.093 9.16971 18.2094C10.173 18.7089 11.3047 18.9903 12.5049 18.9903C16.6444 18.9903 20.0001 15.6346 20.0001 11.4951C20.0001 7.35566 16.6444 3.99997 12.5049 3.99997ZM3.00979 11.4951C3.00979 6.25109 7.26092 1.99997 12.5049 1.99997C17.749 1.99997 22.0001 6.25109 22.0001 11.4951C22.0001 16.7392 17.749 20.9903 12.5049 20.9903C11.1364 20.9903 9.83327 20.7001 8.65567 20.1774L3.3057 21.9025C2.94688 22.0182 2.5535 21.9222 2.28823 21.6543C2.02296 21.3864 1.9309 20.9921 2.05012 20.6345L3.81747 15.3326C3.29799 14.1581 3.00979 12.8591 3.00979 11.4951Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default MessageIcon;
