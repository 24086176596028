/** @format */

import React from "react";

const DeleteIcon = ({ width = 14 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2440_1380)">
        <path
          d="M8.68034 5.19408C8.88374 5.39747 8.88374 5.72724 8.68034 5.93063L7.48614 7.12483L8.68034 8.31903C8.88374 8.52242 8.88374 8.85219 8.68034 9.05559C8.47695 9.25898 8.14718 9.25898 7.94378 9.05559L6.74959 7.86139L5.55539 9.05559C5.35199 9.25898 5.02223 9.25898 4.81883 9.05559C4.61544 8.85219 4.61544 8.52242 4.81883 8.31903L6.01303 7.12483L4.81883 5.93063C4.61544 5.72724 4.61544 5.39747 4.81883 5.19408C5.02223 4.99068 5.35199 4.99068 5.55539 5.19408L6.74959 6.38827L7.94378 5.19408C8.14718 4.99068 8.47695 4.99068 8.68034 5.19408Z"
          fill="#333333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.02051 7.12483C1.02051 3.96075 3.5855 1.39575 6.74959 1.39575C9.91367 1.39575 12.4787 3.96075 12.4787 7.12483C12.4787 10.2889 9.91367 12.8539 6.74959 12.8539C3.5855 12.8539 1.02051 10.2889 1.02051 7.12483ZM6.74959 2.4374C4.16079 2.4374 2.06216 4.53604 2.06216 7.12483C2.06216 9.71363 4.16079 11.8123 6.74959 11.8123C9.33838 11.8123 11.437 9.71363 11.437 7.12483C11.437 4.53604 9.33838 2.4374 6.74959 2.4374Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_2440_1380">
          <rect
            width="12.4998"
            height="12.4998"
            fill="white"
            transform="translate(0.499756 0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
