import AuthServiceInstance from "services";

export class ChatService {
  getUser(users, userId) {
    const user = users.find((user) => user.userId === userId);
    if (user) {
      return {
        name: user.firstName + " " + user.lastName,
        avatarUrl: user.avatar,
        userId: user.userId
      };
    }
    return {};
  }
  getNextUser(users) {
    const user = AuthServiceInstance.getNextUser(users);
    if (user) {
      return {
        name: user.firstName + " " + user.lastName,
        avatarUrl: user.avatar,
        userId: user.userId
      };
    }
    return {
      name: "No User",
      avatarUrl:
        "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80"
    };
  }
}
