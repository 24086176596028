import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spinner from "components/Spinner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreProvider from "store";
import OnboardingLayout from "templates/Onboarding";
import UserLayout from "templates/layouts/UserLayout";
import Profile from "pages/Dashboard/profile";
import MessageBoard from "pages/Dashboard/message";
import UserProfile from "pages/SearchPage/UserProfile/UserProfile";
import SendRequest from "pages/Dashboard/friendRequest/component/SentRequest";
import ConnectRequest from "pages/Dashboard/friendRequest/component/ConnectRequest";
import Networks from "pages/Dashboard/friendRequest/component/Networks";
import Groups from "pages/Dashboard/group/component/GroupList";
import Resources from "pages/Dashboard/group/GroupResources/Resources";
import GroupFeeds from "pages/Dashboard/group";
import RequestGroup from "pages/Dashboard/group/component/RequestGroup";
import ResourceOverView from "pages/Dashboard/Resources/resource-page/ResourceOverView";
import ResourceCourseOverview from "components/Resource/ResourceCourseOverview";
import CreateResourcePage from "pages/Resources/create_resource";

// import ResourceLearningBoard from "pages/Dashboard/Resources/ResourceLearning";
// import ResourceOverview from "pages/Dashboard/Resources/ResourceLearning/component/ResourceOverview";
// import ResourceModuleOverview from "pages/Dashboard/Resources/ResourceLearning/component/ResourceModuleOverview";
import "styles/index.css";
import "./index.css";
import { PublicLayout } from "templates/layouts/PublicLayout";

const queryClient = new QueryClient();

const Login = lazy(() => import("./pages/Auth/SignIn"));
const MenteeSignUp = lazy(() => import("./pages/Auth/MenteeSignUp"));
const MentorSignUp = lazy(() => import("./pages/Auth/MentorSignUp"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const OrganizationSignUp = lazy(() => import("./pages/Auth/OrgnaizationSignUp"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const MentorOnboarding = lazy(() => import("./pages/Onboarding/MentorOnboarding"));

const MenteeOnboarding = lazy(() => import("./pages/Onboarding/MenteeOnboarding"));
const Timeline = lazy(() => import("./pages/Timeline"));
const DashboardLayout = lazy(() => import("./pages/Dashboard"));
const Message = lazy(() => import("./pages/Message"));
const FriendLayout = lazy(() => import("./pages/Dashboard/friendRequest"));
const GroupMemberSearch = lazy(() => import("./pages/Dashboard/group/searchmembers"));
const GroupLanding = lazy(() => import("./pages/Dashboard/group/GroupLandingPage"));
const GroupLayout = lazy(() => import("./templates/layouts/GroupPageLayout"));
const ResourcesPage = lazy(() => import("./pages/Resources"));

const SearchLayout = lazy(() => import("./pages/SearchPage/SearchLayout"));
const MentorLayout = lazy(() => import("./pages/Dashboard/mentors"));
const ResourceModule = lazy(() => import("./pages/Resources/resource_module"));

const AboutUs = lazy(() => import("./pages/AboutUs"));
const Services = lazy(() => import("./pages/Services"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Suspense fallback={<Spinner />}>
        <StoreProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<PublicLayout />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/home" element={<LandingPage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/auth">
                  <Route path="sign-in" element={<Login />} caseSensitive />
                  <Route path="mentee-sign-up" element={<MenteeSignUp />} caseSensitive />
                  <Route path="mentor-sign-up" element={<MentorSignUp />} caseSensitive />
                  <Route path="email-sent" element={<OrganizationSignUp />} caseSensitive />
                  <Route path="forgot-password" element={<ForgotPassword />} caseSensitive />
                  <Route path="reset-password/:token" element={<ResetPassword />} caseSensitive />
                </Route>
              </Route>

              <Route path="/onboarding" element={<OnboardingLayout />}>
                <Route path="mentor" element={<MentorOnboarding />} />
                <Route path="mentor/:step" element={<MentorOnboarding />} />
                <Route path="mentee" element={<MenteeOnboarding />} />
                <Route path="mentee/:step" element={<MenteeOnboarding />} />
              </Route>

              <Route path="/app" element={<UserLayout />}>
                <Route path="" element={<DashboardLayout />}>
                  <Route index element={<Profile />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="message" element={<MessageBoard />} />
                  <Route path="friend-request" element={<FriendLayout />}>
                    <Route index element={<ConnectRequest />} />
                    <Route path="request-sent" element={<SendRequest />} />
                    <Route path="networks" element={<Networks />} />
                    {/* <Route path="groups" element={<Groups />} /> */}
                  </Route>
                  {/* <Route path="groups" element={<Group />} /> */}
                  <Route path="search-group-members" element={<GroupMemberSearch />} />
                </Route>
                <Route path="groups" element={<GroupLanding />}>
                  <Route index element={<Groups />} />

                  <Route path="requests" element={<RequestGroup />} />
                </Route>

                <Route path="groups/:id" element={<GroupLayout />}>
                  <Route index element={<GroupFeeds />} />
                  <Route path="resource" element={<Resources />} />

                  <Route path="resource/:id" element={<ResourceOverView />}>
                    <Route index element={<ResourceCourseOverview />} />
                    <Route path="module" element={<div>Outline</div>} />
                  </Route>
                </Route>

                <Route path="mentorships" element={<MentorLayout />} />
                <Route path="timeline" element={<Timeline />} />

                <Route path="message" element={<Message />} />
                {/* Resources  */}
                <Route path="resources" element={<ResourcesPage />} />

                <Route path="create-resource" element={<CreateResourcePage />} />
                <Route path="resources/:slug" element={<ResourceModule />} />
                <Route path="search" element={<SearchLayout />} />
                <Route path=":userId/profile" element={<UserProfile />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </StoreProvider>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
