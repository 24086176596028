/** @format */

import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Spacer,
  Textarea
} from "@chakra-ui/react";
import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import SaveIcon from "assets/icons/SaveIcon";
import PlusIcon from "assets/icons/PlusIcon";
import useWidth from "utils/useWidth";

const AboutModal = ({ isOpen, toggle, input, handleAboutDetail, submit }) => {
  const initialRef = React.useRef(null);

  const { size } = useWidth("xs", "lg");

  return (
    <>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={toggle} isCentered size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Flex alignItems="center" gap={2}>
                <Box
                  border="1px solid gray"
                  p="4px"
                  rounded="4px"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <ArrowLeftIcon />
                </Box>

                <Box
                  lineHeight={{ base: "14px", lg: "25px" }}
                  fontSize={{ base: "14px", lg: "16px" }}
                  fontWeight={700}
                  ml="3px"
                >
                  About
                </Box>
              </Flex>
              <Spacer />
              <Box>
                <Flex alignItems="center" gap={2} onClick={() => toggle(0)}>
                  <Box border="1px solid gray" p="4px" rounded="4px">
                    <PlusIcon />
                  </Box>
                  <Flex
                    onClick={() => submit()}
                    p="4px"
                    gap={1}
                    w="80px"
                    color="#fff"
                    bgColor="#0C378B"
                  >
                    <SaveIcon />
                    <Box
                      fontWeight={700}
                      lineHeight={{ base: "16px", md: "21px" }}
                      fontSize={{ base: "14px", md: "16px" }}
                    >
                      Save
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <form></form>
            <textarea
              className="leading-[14px] border-[#808080] p-3 w-full lg:leading-[25px] text-[14px] font-semibold focus:border-[1px] focus:border-[#808080]"
              value={input}
              name="bio"
              onChange={(e) => handleAboutDetail(e)}
              placeholder="Write Something"
            />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AboutModal;
