import { useDisclosure } from "@chakra-ui/react";
import CommentIcon from "assets/icons/CommentIcon";
import LikeIcon from "assets/icons/LikeIcon";
import ReportIcon from "assets/icons/ReportIcon";
import SendIcon from "assets/icons/SendIcon";
import SendModal from "pages/Dashboard/group/groupModals/SendModal";
import React, { useState } from "react";
import RepostModal from "../DropDown/RepostModal";
import { isCurrentUserLiked } from "utils/helpers";
import { refetchKeys } from "constant";
import { useFeedAction } from "hooks/feeds";

const FeedInteractivePanel = ({ likes, id, postType, showComment }) => {
  const mutation = useFeedAction(refetchKeys[postType]);

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [onFocus, setOnFocus] = useState(false);
  const [openResend, setOpenResend] = useState(false);

  const handleModal = () => {
    setOpen((prev) => !prev);
  };

  const handleLike = () => {
    try {
      return mutation.mutate({
        id,
        type: postType,
        targetType: "post",
        action: isLikedByCurrentUser ? "unlike" : "like"
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const isLikedByCurrentUser = isCurrentUserLiked(likes);

  return (
    <div className="z-40 flex justify-between items-center text-[#333333] text-[12px] leading-[16px] w-[100%]">
      <div
        onClick={handleLike}
        className={`flex md:gap-2 gap-1 justify-center items-center hover:rounded-[25px] ${
          isLikedByCurrentUser ? "#0C378B" : "#333"
        } px-[9px] py-[4px]  md:px-[20px] md:py-[10px] hover:bg-[#CCCCCC]`}
      >
        <LikeIcon color={likes ? "#0C378B" : "#333"} />
        <p className={`${isLikedByCurrentUser ? "text-[#0C378B]" : "text-[#333]"}`}>Like</p>
      </div>

      <div
        onClick={() => showComment(id)}
        className="flex md:gap-2 gap-1 px-[9px] py-[5px]  items-center hover:rounded-[25px] md:px-[20px] md:py-[10px] hover:bg-[#CCCCCC]"
      >
        <CommentIcon />
        <p>Comment</p>
      </div>

      <div
        onClick={() => setOpenResend((prev) => !prev)}
        className="relative flex justify-center px-[9px] py-[4px]  md:gap-2 gap-1 items-center hover:rounded-[25px] md:px-[20px] md:py-[10px] hover:bg-[#CCCCCC]"
      >
        <ReportIcon />
        <p>Repost</p>
        <div className="absolute top-[3rem] right-[-0.1rem]">{openResend && <RepostModal />}</div>
      </div>

      <div
        onClick={() => {
          handleModal();
          onOpen();
        }}
        className="flex md:gap-2 px-[9px] py-[4px]  gap-1 justify-center items-center hover:rounded-[25px]  md:px-[20px] md:py-[10px] hover:bg-[#CCCCCC]"
      >
        <SendIcon />
        {open && (
          <SendModal
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            onFocus={onFocus}
            setOnFocus={setOnFocus}
            input={input}
            setInput={setInput}
            handleModal={handleModal}
          />
        )}

        <p>Send</p>
      </div>
    </div>
  );
};

export default FeedInteractivePanel;
