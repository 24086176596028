/** @format */

import React, { useState } from "react";
import Text from "pages/Dashboard/component/Text";
import EditIcon from "assets/icons/EditIcon";
import AboutModal from "../../modal/AboutModal";
// import { useDisclosure } from "@chakra-ui/react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

const ProfessionAndCareer = ({ certificate }) => {
  const {
    careerStore: {
      modal: { open },
      toggleModal,
      input,
      setInput
    }
  } = useStore();

  // const { isOpen, onClose, onOpen } = useDisclosure();
  const handleAboutDetail = (e) => {
    setInput(e.target.value);
  };
  return (
    <>
      <div className="w-[100%] relative border-1 shadow-[1px_3px_4px_rgba(0,0,0,0.1)] rounded-md p-2 px-3 md:p-6 bg-secondary-bg">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <h3 className="font-bold leading[27px] pb-2 text-[20px] ">Career Achievement</h3>
            <div className="lg:mr-2" onClick={() => toggleModal()}>
              <EditIcon size="18" />
            </div>
          </div>
          <div className="border-[1px] rounded-[4px] border-[#CCCCCC]  p-3 md:border-none">
            <Text text={certificate} />
          </div>
        </div>
      </div>

      {open && (
        <AboutModal
          isOpen={open}
          onClose={() => toggleModal()}
          // OnOpen={onOpen}
          toggle={() => toggleModal()}
          input={input}
          handleAboutDetail={handleAboutDetail}
          text={"Achievements"}
        />
      )}
    </>
  );
};

export default observer(ProfessionAndCareer);
