import React from "react";
import { SearchIcon } from "@heroicons/react/solid";
import ConversationUser from "./ConversationUser";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

const ConversationList = () => {
  const {
    chatStore: { sortedActiveConversations, conversations }
  } = useStore();

  return (
    <div className="bg-white h-full">
      <div className="max-w-lg w-full lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative text-blue-100 focus-within:text-gray-400">
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-blue-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
            placeholder="Search"
            type="search"
          />
        </div>
      </div>
      <ul role="list" className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
        {Array.from(conversations.values()).map((conversation) => (
          <ConversationUser
            key={`conversation_${conversation.id}`}
            conversation={conversation}
            isActive={sortedActiveConversations.includes(conversation)}
          />
        ))}
      </ul>
    </div>
  );
};

export default observer(ConversationList);
