import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Button,
  ModalBody,
  Box,
  Flex,
  Text,
  Input
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { dummyProfile } from "constant/profile";
import ArrowdownIcon from "assets/icons/ArrowdownIcon";
import { FaRegFileImage } from "react-icons/fa";
import useWidth from "utils/useWidth";
import { useStore } from "store";
import { click } from "@testing-library/user-event/dist/click";
import { useCreatePost } from "hooks/feeds";

const TimeLinePostCard = ({ isOpen, toggleModal, closePostModal, viewOption }) => {
  const [file, setFile] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const {
    postStore: {
      modal: { viewType, closePost },
      togglePostViewBy
    }
  } = useStore();

  // Post Feeds
  const mutation = useCreatePost();

  const { size } = useWidth("sm", "2xl");
  const inputRef = useRef(null);
  const handleInput = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    setFile((prev) => [...prev, ...file]);

    const imageFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setSelectedImages(imageFiles);
  };

  const deleteImage = (image) => {
    const deleteOne = selectedImages.filter((img) => img.preview !== image.preview);
    setSelectedImages([...deleteOne]);
    setFile([...deleteOne]);
  };

  useEffect(() => {
    // Cleanup function to revoke object URLs
    return () => {
      selectedImages.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [selectedImages]);

  return (
    <>
      <Box onClick={toggleModal}>
        <Modal
          size={size}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={toggleModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent p={size === "sm" ? 2 : 4}>
            <Flex justify="space-between">
              <Box
                _hover={{ background: "#CCC", rounded: "8px" }}
                rounded-lg
                className="flex gap-6 items-center"
                p={2}
                onClick={() => {
                  togglePostViewBy();
                }}
              >
                <img src={dummyProfile.src} className="h-[57px] w-[58px] rounded-full" />

                <div className="flex flex-col justify-start">
                  <div className="flex gap-6 items-center ">
                    <p className=" text-[16px] text-[#333333] font-semibold ">
                      Oluwabusayo Akinniyi
                    </p>
                    <Box>
                      <ArrowdownIcon />
                    </Box>
                  </div>

                  <p className="text-[14px] text-[#808080] leading-[19px] font-semibold ">
                    Post to {viewOption}
                  </p>
                </div>
              </Box>

              <Box justify="center" alignItems="center" onClick={closePostModal}>
                <Text
                  _hover={{ background: "#ccc" }}
                  p="8px"
                  borderRadius="50%"
                  fontSize={{ md: "12px" }}
                >
                  X
                </Text>
              </Box>
            </Flex>

            <ModalBody p={3} my={2}>
              <textarea className="w-[100%] h-[200px] p-2 placeholder='What do you want to post?'  rounded-lg border"></textarea>

              <Box>
                <Flex alignItems="center" gap={4}>
                  <div>
                    <div
                      onClick={() => {
                        handleInput();
                      }}
                    >
                      <FaRegFileImage size={35} color="#ccc" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2 cursor-pointer">
                    {selectedImages.map((image, index) => (
                      <div key={index} className="relative">
                        <div
                          onClick={() => deleteImage(image)}
                          className="w-[20px] absolute top-[0px] left-[-1px] hover:rounded-full hover:bg-white flex justify-center items-center "
                        >
                          <p className="text-[14px] font-extrabold text-black">&times;</p>
                        </div>
                        <img
                          src={image.preview}
                          alt={`selected-img-${index}`}
                          className=""
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter>
              <form encType="multipart/form">
                <input
                  type="file"
                  accept="image/*"
                  className="w-full h-full border-spacing-0 hidden"
                  ref={inputRef}
                  multiple
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </form>

              <Button
                colorScheme="blue"
                onClick={() => {
                  toggleModal();
                }}
              >
                Post
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default observer(TimeLinePostCard);
