/** @format */

import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import ImageCard from "components/Image/ImageCard";
import { dummyProfile } from "constant/profile";
import { chatMessages } from "constant/message";

const ChatMessageCard = () => {
  const userId = "id2";
  return (
    <div className="scrolling-pg gap-4 h-[100%]  justify-end flex flex-col">
      {chatMessages.map((message, i) => (
        <div
          className={`flex justify-self-end ${
            message.sender.id === userId ? "justify-end" : "justify-start"
          } gap-[16px]`}
          key={i}
        >
          <div
            className={` flex gap-4 px-[16px] py-[8px] rounded-[16px]  bg-[#E2EAF5] ${
              message.sender.id === userId ? "flex-row-reverse" : "flex-row"
            }`}
          >
            <Box alignSelf={"flex-end"}>
              <ImageCard src={message.sender.image || dummyProfile.src} height={48} width={48} />
            </Box>
            <Box>
              <Flex align="center" gap={3}>
                <Text fontSize="14px" fontWeight={600} lineHeight="19px" color="#333">
                  {message.sender.id === userId
                    ? "You"
                    : message.sender.firstname + " " + message.sender.lastname}
                </Text>
                .
                <Text color="#808080" fontSize="14px" fontWeight={600} lineHeight="19px">
                  4:45 PM
                </Text>
              </Flex>
              <Text fontSize="12px" lineHeight="16px" fontWeight={400} color="#333">
                {message.text}
              </Text>
            </Box>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessageCard;
