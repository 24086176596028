import React from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Button,
  Text
} from "@chakra-ui/react";
import useWidth from "utils/useWidth";

const CancelTimeLinePost = ({ onClose, isOpen, toggleModal, setPostToDraft }) => {
  const { size } = useWidth("sm", "2xl");
  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize={{ base: "12px", md: "20px" }} fontWeight={600}>
              Save this post as draft?
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={{ md: "6px" }} borderTop="1px solid #ccc" borderBottom="1px solid #ccc">
            <Text
              color="#8C2C1B"
              lineHeight={{ md: "21px" }}
              fontSize={{ base: "12px", md: "16px" }}
              my={{ base: "", md: "12px" }}
            >
              Do you have to go do something really quick?
            </Text>
            <Text my={{ base: "", md: "12px" }}>
              The post you started will be here when you return.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                onClose();
                toggleModal();
              }}
            >
              Discard
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                setPostToDraft("Add to post");
                onClose();
                toggleModal();
              }}
            >
              Save to Draft
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CancelTimeLinePost;
