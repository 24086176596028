/** @format */

import React from "react";

const PencilIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0.878662C15.1722 0.878662 14.3783 1.20751 13.7929 1.79288L1.29291 14.2929C1.16476 14.421 1.07384 14.5816 1.02988 14.7574L0.0298787 18.7574C-0.055315 19.0982 0.0445341 19.4587 0.292914 19.7071C0.541295 19.9555 0.901782 20.0553 1.24256 19.9701L5.24256 18.9701C5.41839 18.9262 5.57897 18.8352 5.70713 18.7071L18.2071 6.20709C18.497 5.91725 18.7269 5.57316 18.8837 5.19446C19.0406 4.81576 19.1213 4.40988 19.1213 3.99998C19.1213 3.59008 19.0406 3.1842 18.8837 2.8055C18.7269 2.42681 18.497 2.08272 18.2071 1.79288C17.9173 1.50303 17.5732 1.27312 17.1945 1.11626C16.8158 0.959397 16.4099 0.878662 16 0.878662ZM15.2071 3.20709C15.4174 2.9968 15.7026 2.87866 16 2.87866C16.1473 2.87866 16.2931 2.90767 16.4291 2.96402C16.5652 3.02037 16.6888 3.10296 16.7929 3.20709C16.897 3.31121 16.9796 3.43483 17.036 3.57087C17.0923 3.70692 17.1213 3.85273 17.1213 3.99998C17.1213 4.14724 17.0923 4.29305 17.036 4.42909C16.9796 4.56514 16.897 4.68875 16.7929 4.79288L4.48877 17.097L2.37439 17.6256L2.90299 15.5112L15.2071 3.20709Z"
          fill="#333333"
        />
        <path
          d="M10 18C9.44774 18 9.00002 18.4477 9.00002 19C9.00002 19.5523 9.44774 20 10 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H10Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};

export default PencilIcon;
