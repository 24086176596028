/** @format */

import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  FormControl,
  Input,
  ModalOverlay,
  FormLabel,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Spacer
} from "@chakra-ui/react";

import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import SaveIcon from "assets/icons/SaveIcon";
import PlusIcon from "assets/icons/PlusIcon";
import useWidth from "utils/useWidth";
import ModalButton from "components/Button/ModalButton";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import DustbinIcon from "assets/icons/DustbinIcon";
import { useStore } from "store";
import {
  useAddEducationMutation,
  useEditEducationMutation,
  useEducationDeleteMutation
} from "hooks/user/education";

const EducationModal = () => {
  const {
    educationStore: {
      modal: { isOpen, mode },
      setRemoveEducationModal,
      setInitialValues,
      initialValues: values,
      toggleModal
    }
  } = useStore();
  const { size } = useWidth("sm", "lg");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitialValues(name, value);
  };

  const { mutate: addEducation } = useAddEducationMutation(() => {
    toggleModal();
  });
  const { mutate: deleteEducation } = useEducationDeleteMutation(() => {
    setRemoveEducationModal();
  });
  const { mutate: editEduaction } = useEditEducationMutation(() => {
    toggleModal();
  });

  const handleSubmit = () => {
    mode == "Add" ? addEducation(values) : editEduaction(values);
  };

  const removeEducation = () => {
    if (mode === "Edit") {
      deleteEducation(values.id);
    }
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={toggleModal}
        scrollBehavior="outside"
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Flex alignItems="center" gap={2}>
                <Box
                  border="1px solid gray"
                  p="4px"
                  rounded="4px"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <ArrowLeftIcon />
                </Box>
                <Box
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={700}
                  ml="3px"
                >
                  {mode} Education
                </Box>
              </Flex>
              <Spacer />
              <Box>
                <Flex alignItems="center" gap={2}>
                  <Box
                    border="1px solid gray"
                    p="4px"
                    rounded="4px "
                    onClick={() => toggleModal("Add")}
                  >
                    <PlusIcon />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                Institution Name
              </FormLabel>

              <Input
                name="institutionName"
                value={values.institutionName}
                type="text"
                placeholder="Institution name"
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Degree
              </FormLabel>

              <Input
                name="degree"
                value={values.degree}
                type="text"
                placeholder="Degree and feild of study"
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            {/* <Flex mt={4} justify="space-between" w="100%"> */}
            <Box>
              <Box
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Start Date
              </Box>
              <Box>
                <Input
                  id="startDate"
                  fontSize={{ base: "1rem", md: "0.875rem" }}
                  bgColor="#F3F5F7"
                  name="startDate"
                  type="date"
                  value={
                    values.startDate
                      ? dayjs(values.startDate).format("YYYY-MM-DD")
                      : dayjs(userInfo?.startDate).format("YYYY-MM-DD")
                  }
                  onChange={handleChange}
                  aria-label="startDate"
                />
              </Box>
            </Box>
            <Spacer />
            <Box>
              <Box
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                End Date
              </Box>
              <Box>
                <Input
                  id="endDate"
                  fontSize={{ base: "1rem", md: "0.875rem" }}
                  bgColor="#F3F5F7"
                  name="endDate"
                  type="date"
                  value={
                    values.endDate
                      ? dayjs(values.endDate).format("YYYY-MM-DD")
                      : dayjs(userInfo?.endDate).format("YYYY-MM-DD")
                  }
                  onChange={handleChange}
                  aria-label="endDate"
                />
              </Box>
            </Box>
            {/* </Flex> */}
          </ModalBody>

          <ModalFooter>
            <Flex alignItems="center" gap={4}>
              {mode === "Edit" && (
                <ModalButton bg="#fff" handleSubmit={removeEducation} text="#0C378B" title="Delete">
                  <DustbinIcon />
                </ModalButton>
              )}

              <ModalButton title="Save" handleSubmit={handleSubmit}>
                <Box>
                  <SaveIcon />
                </Box>
              </ModalButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(EducationModal);
