/** @format */

const { makeAutoObservable, observable, action } = require("mobx");

class AboutStore {
  modal = {
    isOpen: false
  };

  value = {
    about: ""
  };
  constructor() {
    makeAutoObservable(this, {
      modal: observable,
      value: observable,
      setValue: action,
      toggleModal: action
    });
  }

  setValue = (field, value) => {
    this.value = {
      ...this.value,
      [field]: value
    };
  };

  toggleModal = () => {
    this.modal = {
      ...this.modal,
      isOpen: !this.modal.isOpen
    };
  };
}

export default AboutStore;
