/** @format */
import React from "react";

const DividerIcon = () => {
  return (
    <div>
      <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L0.999999 15" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
};

export default DividerIcon;
