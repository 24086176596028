/** @format */

import React from "react";
import { useLocation } from "react-router-dom";
const MyNetworkIcon = ({ size = 22 }) => {
  const { pathname } = useLocation();
  const color = pathname == "/app/friend-request/networks" ? "#fff" : "#0C378B";
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        color={color}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9957 0.0666636C14.4225 0.0529931 12.9069 0.657759 11.7753 1.75071L11.765 1.76083L10.045 3.47083C9.6533 3.86021 9.65145 4.49338 10.0408 4.88504C10.4302 5.2767 11.0634 5.27855 11.455 4.88916L13.1696 3.18453C13.9235 2.4589 14.9318 2.05749 15.9783 2.06659C17.0271 2.0757 18.0304 2.49638 18.772 3.23801C19.5136 3.97964 19.9343 4.98289 19.9434 6.03168C19.9525 7.07773 19.5514 8.0856 18.8264 8.83937L15.8329 11.8329C15.4274 12.2385 14.9392 12.5524 14.4018 12.7528C13.8644 12.9533 13.2902 13.0359 12.7181 12.9949C12.146 12.9539 11.5894 12.7904 11.086 12.5154C10.5827 12.2404 10.1443 11.8603 9.80077 11.401C9.46996 10.9588 8.84327 10.8684 8.40102 11.1992C7.95877 11.53 7.86843 12.1567 8.19923 12.599C8.71458 13.2879 9.37206 13.858 10.1271 14.2705C10.8821 14.683 11.717 14.9283 12.5752 14.9898C13.4334 15.0512 14.2947 14.9274 15.1008 14.6267C15.907 14.326 16.639 13.8555 17.2472 13.247L20.2471 10.2471L20.2593 10.2347C21.3522 9.1031 21.957 7.58748 21.9433 6.0143C21.9297 4.44112 21.2986 2.93624 20.1862 1.8238C19.0738 0.711347 17.5689 0.0803341 15.9957 0.0666636Z"
          fill={color}
        />
        <path
          d="M9.42474 7.01021C8.56657 6.94875 7.70522 7.07257 6.89911 7.37327C6.09305 7.67395 5.36109 8.14446 4.75285 8.75288L1.75285 11.7529L1.74067 11.7653C0.647719 12.8969 0.0429528 14.4125 0.0566233 15.9857C0.0702938 17.5589 0.701307 19.0637 1.81376 20.1762C2.9262 21.2886 4.43108 21.9197 6.00426 21.9333C7.57744 21.947 9.09305 21.3422 10.2247 20.2493L10.2371 20.2371L11.9471 18.5271C12.3376 18.1366 12.3376 17.5034 11.9471 17.1129C11.5565 16.7224 10.9234 16.7224 10.5328 17.1129L8.82932 18.8164C8.07555 19.5414 7.06768 19.9425 6.02164 19.9334C4.97285 19.9243 3.9696 19.5036 3.22797 18.762C2.48634 18.0203 2.06566 17.0171 2.05655 15.9683C2.04746 14.9223 2.44851 13.9144 3.17355 13.1606L6.16706 10.1671C6.57257 9.76144 7.06071 9.44761 7.59811 9.24714C8.13552 9.04667 8.70976 8.96413 9.28187 9.0051C9.85398 9.04607 10.4106 9.20961 10.9139 9.48462C11.4173 9.75962 11.8556 10.1397 12.1992 10.599C12.53 11.0412 13.1567 11.1316 13.5989 10.8008C14.0412 10.4699 14.1315 9.84326 13.8007 9.40101C13.2854 8.71206 12.6279 8.14199 11.8729 7.72948C11.1178 7.31697 10.2829 7.07167 9.42474 7.01021Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default MyNetworkIcon;
