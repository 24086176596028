import React from "react";
import { FaPlus, FaArrowRightLong } from "react-icons/fa6";

export const ModuleActions = ({ handleActiveModule, handleSubmit }) => {
  return (
    <div className="flex items-center gap-4">
      <div
        onClick={() => handleActiveModule("add", 0)}
        className="cursor-pointer hidden lg:flex items-center lg:gap-4 border-[1px] border-[#1455D9] lg:p-2 rounded-lg text-[#1455D9]"
      >
        <FaPlus color="#1455D9" size={16} />
        <p className="font-bold text-[14px]">Add a module</p>
      </div>

      <div
        onClick={handleSubmit}
        className="cursor-pointer flex items-center lg:gap-4 p-2 text-white border-[#1455D9] rounded-lg bg-[#1455D9]"
      >
        <p className="font-bold text-[14px]">Save to draft</p>
        <FaArrowRightLong size={16} color="#fff" />
      </div>
    </div>
  );
};
