/** @format */
import React from "react";
import SearchIcon from "assets/icons/SearchIcon";

const SearchInput = ({
  input,
  setInput,
  onFocus,
  setOnFocus,
  width = "270px",
  resizeWidth = "374px",
  placeholder,
  placeholderColor = "#333",
  borderColor = "#1455D9",
  navigateTo
}) => {
  const handleFocus = () => {
    setOnFocus(true);
  };

  const handleBlur = () => {
    setOnFocus(false);
  };

  return (
    <div className="w-[100%] lg:inline-block">
      <form>
        <div className="relative">
          <div className="absolute top-2 left-8 lg:left-3">
            <SearchIcon />
          </div>
          <input
            className={`p-[7px] pl-16 lg:text-lg text-sm lg:pl-[50px] w-[${width}] outline-none ${
              onFocus
                ? `lg:w-[${resizeWidth}] transition-all ease-in duration-30 placeholder-[${placeholderColor}]`
                : " "
            } rounded-md text-[#333] border-[1px] border-[${borderColor}] placeholder-[${placeholderColor}] `}
            placeholder={placeholder}
            onFocus={() => handleFocus}
            onBlur={() => handleBlur}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={navigateTo}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
