/** @format */

export const footerAbouts = [
  { title: "About", href: "" },
  { title: "Privacy Policy", href: "" },
  { title: "Terms & Agreement", href: "" },
  { title: "Networks", href: "" }
];

export const footerMentees = [
  { title: "Mentees", href: "" },
  { title: "Careers", href: "" },
  { title: "Opportunity", href: "" },
  { title: "Advertising", href: "" }
];

export const footerFAQ = [
  { title: "FAQ’s", href: "" },
  { title: "Portfolio", href: "" },
  { title: "Marketing", href: "" },
  { title: "Sponsors", href: "" }
];
