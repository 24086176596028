/** @format */

import React, { useState } from "react";
import { useStore } from "store";
import { useParams } from "react-router-dom";

import { useGroupDetails } from "hooks/group";
import { useCreatePost } from "hooks/feeds";
import GroupAdmins from "./component/GroupAdmins";
import GroupsHeader from "./component/GroupsHeader";
import CreatePost from "components/CreatePost";
import GroupMediaPollComposer from "./component/GroupMediaComposer";
import GroupMember from "./component/GroupMember";
import GroupFeed from "./component/GroupFeed";
import MoveableFooter from "components/footer/component/MoveableFooter";
import ArrowdownIcon from "assets/icons/ArrowdownIcon";

const GroupFeeds = () => {
  const [show, setShow] = useState(false);
  const [postData, setPostData] = useState("");
  const { id } = useParams();
  const mutation = useCreatePost();
  const {
    groupStore: { setGroupInfo }
  } = useStore();
  useGroupDetails(id, setGroupInfo);

  const sendPost = async (e) => {
    try {
      const payload = {
        postData: {
          ...e,
          postType: "group",
          id
        },
        refetchKeys: ["fetchGroupFeeds"]
      };
      await mutation.mutate(payload);
      setPostData("");
    } catch (error) {
      console.error(error);
    }
  };

  const options = {
    id,
    postType: "group",
    send: sendPost,
    postData,
    setPostData
  };
  return (
    <main className="w-full lg:flex gap-7 ">
      <main className=" lg:w-[70%] grid grid-cols-1 gap-8">
        <div className="w-full">
          <GroupsHeader />
        </div>

        <div className="w-full">
          <CreatePost options={options}>
            <GroupMediaPollComposer />
          </CreatePost>
        </div>

        <div className="lg:hidden">
          <div
            onClick={() => setShow(!show)}
            className="flex justify-center bg-[] md:bg-[#fff] md:block  px-[18px] "
          >
            <button className="flex gap-2 items-center text-[14px] font-semibold leading-[21px]">
              Most recent
              <span>
                <ArrowdownIcon />
              </span>
            </button>
          </div>
          {show ? (
            <div>
              <GroupMember />
              <GroupAdmins />
            </div>
          ) : null}
        </div>

        <div className="w-full">
          <GroupFeed groupId={id} />
        </div>
      </main>

      <section className="hidden h-fit lg:inline-block lg:w-[30%] lg:px-6 ">
        <div className="w-full">
          <div className="flex w-fill flex-col gap-5">
            <GroupMember />
            <GroupAdmins />
            <MoveableFooter />
          </div>
        </div>
      </section>
    </main>
  );
};

export default GroupFeeds;
