import React from "react";
import MoveableFooter from "components/footer/component/MoveableFooter";
import ResourecMediaCard from "../component/ResourecMediaCard";
import ResourceForm from "./ResourceForm";

import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useAddResource } from "hooks/resource";
import { ResourceNav } from "../component/ResourceNav";

const CreateResourcePage = () => {
  const {
    resourceStore: { initialValues: values, setInitialValues }
  } = useStore();
  const { mutate: addResourceData } = useAddResource();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitialValues(name, value);
  };

  const handleSubmit = () => {
    const formdata = new FormData();

    if (values.file) formdata.append("file", values.file);
    formdata.append("title", values.title);
    formdata.append("description", values.description);
    formdata.append("privacy", values.privacy);
    formdata.append("isPaid", values.isPaid);
    formdata.append("price", values.price);

    addResourceData(formdata);
  };

  return (
    <section className="flex justify-center items-center w-full mb-1  bg-bg-primary">
      <section className="  lg:h-[cal(100%-150px)] lg:w-[calc(1300px-104px)] w-full flex gap-4 flex-col ">
        <div>
          {/* <CreateResourceNav handleForm={handleSubmit} />
           */}
          <ResourceNav title="Create Resource" showModuleActions={false} onSave={handleSubmit} />
        </div>

        <div className="relative grid grid-flow-col-2  lg:grid-cols-4 sm:grid-cols-1 sm:grid-flow-dense lg:gap-8 lg:w-[100% ] relative">
          <div className="  lg:col-start-1 lg:col-span-3 flex flex-col gap-4 ">
            <ResourceForm values={values} handleChange={handleChange} />
          </div>

          <div className="gap-3 lg:col-start-4   items-start  lg:h-[135dvh]">
            <ResourecMediaCard file={values.file} setInitialValues={setInitialValues} />
            <div className="lg:inline-block hidden">
              <div className="mt-5 ">
                <MoveableFooter />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default observer(CreateResourcePage);
