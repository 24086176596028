import React, { useState } from "react";
import Icon from "../../assets/Icon";
import PhotoIcon from "../../assets/svgs/photo-icon.svg";
import PostInput from "components/PostInput/PostInput";
import PostAvater from "components/PostInput/PostAvater";
import TimeLinePostCard from "components/Timeline/timeline-modals/TimeLinePostCard";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import CancelTimeLinePost from "components/Timeline/timeline-modals/CancelTimeLinePost";
import PostSettingsModal from "components/Timeline/timeline-modals/PostSettingsModal";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { BiWorld } from "react-icons/bi";
import { IoIosLink } from "react-icons/io";
import { RiGroupFill } from "react-icons/ri";
import PollModal from "components/Timeline/timeline-modals/PollModal";

// Comment on cretae
const ArticleImageUploader = () => {
  return (
    <div className="flex items-center justify-end space-x-6 ml-auto">
      <button
        type="button"
        className="text-sm flex items-center space-x-2 shadow py-1 px-2 rounded-lg"
        onClick={() => setIsOpenPhoto(true)}
      >
        <img src={PhotoIcon} alt="" />
        <span>Photo</span>
      </button>

      {/* NOTE HTML BUG  This will be a child to createPost for adding Video and all */}
      {/* TODO: Add once backend is ready */}
      <button
        type="button"
        className="text-sm flex items-center space-x-2 shadow py-1 px-2 rounded-lg"
        onClick={() => getShow(true)}
      >
        <Icon id="video-icon" width={20} height={20} />
        <span className="">Video</span>
      </button>
      <button
        type="button"
        className="text-sm flex items-center space-x-2 shadow py-1 px-2 rounded-lg"
        onClick={() => getArticle(true)}
      >
        <Icon id="article-icon" width={19} height={20} />

        <span className="">Article</span>
      </button>
    </div>
  );
};

const CreatePost = (props) => {
  const [viewOption, setViewOption] = useState("Anyone");
  const [viewPostOption, setViewPostOption] = useState([
    {
      name: "Anyone",
      selected: true,
      icon1: <BiWorld color="#808080" />,
      icon2: ""
    },
    {
      name: "Network Only",
      selected: false,
      icon1: <IoIosLink color="#808080" />,
      icon2: ""
    },
    {
      name: "Groups",
      selected: false,
      icon1: <RiGroupFill color="#808080" />,
      icon2: <ArrowCircleRightIcon width={12} height={12} />
    }
  ]);

  const handleSelectedOption = (arg) => {
    setViewPostOption((prev) =>
      prev.map((opt) => {
        if (opt.name === arg) {
          setViewOption(opt.name);
          return { ...opt, selected: true };
        }
        if (opt.name !== arg) {
          return { ...opt, selected: false };
        }
        return opt;
      })
    );
  };
  const { children, options } = props;
  const [inputFocus, setInputFocus] = useState(false);
  const {
    postStore: {
      modal: { isOpen, closePost, postViewedBy, postTextDraft, isPollOpen },
      toggleModal,
      closePostModal,
      setPostToDraft,
      togglePostViewBy,
      toggleCommentControl
    }
  } = useStore();

  return (
    <>
      <div className="bg-white h-full w-full  border-1 shadow-[1px_3px_4px_rgba(0,0,0,0.1)] rounded-md relative ">
        <div className=" px-4 py-3 md:py-4 md:px-7 flex gap-2 items-start">
          <PostAvater />
          <div className="flex flex-col w-full gap-2  items-center">
            <PostInput
              options={options}
              isOpen={isOpen}
              inputFocus={inputFocus}
              setInputFocus={() => setInputFocus((prev) => !prev)}
              toggleModal={toggleModal}
              type="createNewPost"
            />
            {children}
          </div>
        </div>
      </div>

      {closePost && (
        <CancelTimeLinePost
          toggleModal={toggleModal}
          onClose={closePostModal}
          isOpen={closePost}
          setPostToDraft={setPostToDraft}
        />
      )}

      {isOpen && (
        <TimeLinePostCard
          isOpen={isOpen}
          toggleModal={toggleModal}
          closePostModal={closePostModal}
          viewOption={viewOption}
        />
      )}

      {postViewedBy && (
        <PostSettingsModal
          onClose={togglePostViewBy}
          isOpen={postViewedBy}
          handleSelectedOption={handleSelectedOption}
          viewPostOption={viewPostOption}
          toggleCommentControl={toggleCommentControl}
        />
      )}

      {isPollOpen && <PollModal />}
    </>
  );
};

export default observer(CreatePost);
