/** @format */

import GoldRatingIcon from "assets/icons/GoldRatingIcon";
import RatingIcon from "assets/icons/RatingIcon";
import React from "react";

const StarRating = ({ maxRating, rating, size = 15 }) => {
  return (
    <div className="flex gap-1 items-center">
      {Array.from({ length: maxRating }, (_, i) => {
        return (
          <div key={i}>
            {rating >= i + 1 ? <GoldRatingIcon size={size} /> : <RatingIcon size={size} />}
          </div>
        );
      })}
    </div>
  );
};
export default StarRating;
