import React from "react";
import { dummyProfile } from "constant/profile";
import { useNavigate } from "react-router-dom";

const FriendsProfileCard = React.memo(({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      className="cursor-pointer"
      onClick={() => navigate(`/app/${data.userId}/profile`, { replace: true })}
    >
      <div className="h-[72px] md:w-[231px] flex md:gap-[24px] gap-3 items-center text-[#333]">
        <div className="md:w-[62] md:h-[62px]">
          <img
            src={data?.profilePic || dummyProfile.src}
            className="object-cover rounded-[100%] w-[72px] h-[72px] md:w-[62px] md:h-[62px]"
          />
        </div>

        <div className="flex flex-col gap-1 md:gap-2">
          <p className="font-medium text-[14px] md:text-[16px] leading-[21px] md:leading-[21px]">
            {data?.firstName} {data.lastName}
          </p>
          <p className="text-[12px] text-[#333333] leading-[19px] font-normal">
            {`${data?.jobTitle || ""} ${data?.industry ? `@${data.industry}` : ""}`}
          </p>
          <p className="font-normal text-[12px]   md:text-[14px] leading-[16px] text-[#808080]  md:leading-[19px]">
            3 Mutual Connections
          </p>
        </div>
      </div>
    </div>
  );
});

FriendsProfileCard.displayName = "FriendsProfileCard";

export default FriendsProfileCard;
