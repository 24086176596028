/** @format */

import EditIcon from "assets/icons/EditIcon";
import LocationIcon from "assets/icons/LocationIcon";
import React, { useState } from "react";
import Connections from "./Connections";
import Group from "./Group";
import Elliples from "assets/icons/Elliples";
import CameraIcon from "assets/icons/CameraIcon";
import { Spinner, useDisclosure } from "@chakra-ui/react";
import CoverPhotoImage from "../profile/modal/CoverPhotoModal.js";
import ProfileEditModal from "../profile/modal/ProfileModal";
import dummyCover from "assets/images/cover-photo.jpg";
import dummyImg from "assets/images/blank-profile-picture-gb818349d8_1280.png";
import { useGetProfile } from "hooks/user";

const ProfileBoard = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [coverImage, setCoverImage] = useState({
    fileName: "CoverImage",
    selectedFile: ""
  });
  const [profileBackgroundImage, setProfileBackgroundImage] = useState("");

  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const { data: { data: { profile } = {} } = {}, isLoading } = useGetProfile();

  if (isLoading) {
    return (
      <div className="w-[100%] flex justify-center items-center lg:h-[500px] h-[100px] p-2 relative border-1  rounded-lg bg-secondary-bg">
        <Spinner />
      </div>
    );
  }

  return (
    <section className="w-[100%] p-2 relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.2)] rounded-lg bg-secondary-bg">
      <div className=" lg:h-[180px] w-full h-[107px] relative">
        <div onClick={() => setOpen((prev) => !prev)} className="absolute right-4 top-4">
          {open && (
            <CoverPhotoImage
              coverPhoto={coverImage}
              setCoverPhot0={setCoverImage}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
              toggle={() => setOpen((prev) => !prev)}
            />
          )}
          <div className="relative">
            <Elliples />
            <div className="absolute top-[0.57rem] left-[0.62rem]">
              <CameraIcon />
            </div>
          </div>
        </div>

        <img
          src={profile?.coverPhoto || dummyCover}
          className="object-cover h-[110px] lg:h-[168px] w-[100%]"
          alt="coverPhoto"
        />
      </div>
      <div className="absolute left-[40%] top-[52px] lg:top-13  lg:top-[5.4rem] lg:left-10 lg:w-[185px] lg:h[185px] h-[103px] w-[103px] lg:-h[185px] rounded-[50%] ">
        <div className="">
          <img
            className="object-cover rounded-full h-[104px] w-[104px] lg:h-[161px] lg:w-[161px]"
            src={profile?.profilePic || dummyImg}
            alt="pic"
          />
        </div>
      </div>
      <div
        onClick={() => {
          setShowProfileEditModal((prev) => !prev);
          onOpen();
        }}
        className=" right-8 top-[11rem] absolute lg:top-[14rem] lg:right-7 "
      >
        {showProfileEditModal && (
          <ProfileEditModal
            profile={profile}
            profileId={profile?.userId}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            toggle={() => setShowProfileEditModal(!showProfileEditModal)}
          />
        )}

        <div>
          <EditIcon />
        </div>
      </div>

      <div className="mt-[4rem] lg:mt-[7rem] px-4  flex flex-col gap-2 lg:gap-3  relative text-[#333333]">
        <div className="lg:flex justify-between items-center">
          <h3 className="font-bold md:leading-[34.1px] text-[20px] lg:text-[25px] lg:leading-[34px]">
            {profile?.lastName} {profile?.firstName}
          </h3>
          <p className="font-bold text-[#1455D9] text-[13px] leading-[16.82px] lg:relative hidden lg:inline-block">
            Contact Info
          </p>
        </div>
        <p className="font-semibold leading-[19px] text-[#333] text-[14px] lg:text-[14px] lg:leading-[21px]">
          {profile?.jobTitle}
          {profile?.industry ? ` at ${profile?.industry}` : null}
        </p>

        <p className="flex items-center gap-2 text-[#808080] font-semibold leading-[19px] text-[14px] lg:text-[16px] lg:leading-[21px]">
          <LocationIcon />
          {profile?.state && (
            <>
              {profile?.state}
              {profile?.country && `, ${profile?.country}`}
            </>
          )}
        </p>

        <p className="font-semibold leading-[19px] text-[14px] lg:text-[16px] lg:leading-[21px]">
          {data?.mentees} Mentees
        </p>

        {data?.mentees ? <p className="font-semibold leading-[19px] text-[14px]"></p> : ""}
        <p className="font-bold  text-[#1455D9] text-[12px] lg:text-[14px] leading-[19px] lg:relative lg:hidden">
          Contact Info
        </p>
      </div>
      <div className="flex gap-2 flex-col lg:flex-row lg:justify-between m-3 lg:gap-4">
        <Connections color="connections" />
        <Group color="Group" />
      </div>
    </section>
  );
};

export default ProfileBoard;
