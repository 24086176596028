import { ENCRYPTION_KEY } from "constant";
import CryptoJS from "crypto-js";
export const encryptData = (data) => {
  if (data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  }
  return null;
};

export const decryptData = (data) => {
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};
