import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  postResources,
  getAllResource,
  updateResourceModule,
  getResource,
  getAuthorResources,
  postResourceModule
} from "api/resources";
import { Routes } from "constant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useGetResources = ({ keyword, page, limit }) => {
  const query = useQuery({
    queryKey: ["resource", keyword, page, limit],
    queryFn: () => getAllResource({ keyword, page, limit }),
    staleTime: 6000
  });

  return query;
};

export const useGetAuthorResources = ({ keyword, page, limit }) => {
  return useQuery({
    queryKey: ["authorResources", keyword, page, limit],
    // queryFn: () => getAuthorResources({ keyword, page, limit }),
    queryFn: async () => {
      const query = { keyword, page, limit };
      const response = await getAuthorResources(query);
      if (response?.status) {
        return response.data;
      } else {
        throw new Error(response?.message);
      }
    },
    staleTime: 60000
  });
};

export const useAddResource = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutate = useMutation({
    mutationFn: postResources,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["resource"]);
      // Routes
      console.log("Clecing the slug of the new resource", data.data.slug);
      navigate(Routes.Resource(data.data.slug));
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const addResourceData = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: addResourceData
  };
};

// get resource by slug
export const useGetResource = (slug) => {
  const query = useQuery({
    queryKey: ["resource", slug],
    queryFn: () => getResource(slug),
    staleTime: 6000
  });

  return query;
};

// Module ADD and Update Module

export const usePostModule = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: postResourceModule,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["resource"]);
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const addModuleData = (body) => {
    mutate.mutate(body);
  };

  return {
    mutate: addModuleData
  };
};

export const useUpdateResourceModule = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: updateResourceModule,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["resource"]);
    },
    onError: (data) => {
      toast(data?.message);
    }
  });

  const updateResourceData = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: updateResourceData
  };
};
