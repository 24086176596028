/** @format */

import React from "react";
import { useLocation } from "react-router-dom";

const Message = () => {
  const { pathname } = useLocation();
  const color = pathname === "/app/message" ? "#fff" : "#0c378b";
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5049 3.99997C8.36546 3.99997 5.00977 7.35566 5.00977 11.4951C5.00977 12.6932 5.29016 13.823 5.78798 14.825C5.9056 15.0618 5.92469 15.3354 5.8411 15.5862L4.57225 19.3926L8.41714 18.1529C8.66574 18.0727 8.93585 18.093 9.16968 18.2094C10.173 18.7089 11.3047 18.9903 12.5049 18.9903C16.6444 18.9903 20.0001 15.6346 20.0001 11.4951C20.0001 7.35566 16.6444 3.99997 12.5049 3.99997ZM3.00977 11.4951C3.00977 6.25109 7.26089 1.99997 12.5049 1.99997C17.749 1.99997 22.0001 6.25109 22.0001 11.4951C22.0001 16.7392 17.749 20.9903 12.5049 20.9903C11.1364 20.9903 9.83325 20.7001 8.65564 20.1774L3.30567 21.9025C2.94686 22.0182 2.55347 21.9222 2.28821 21.6543C2.02294 21.3864 1.93088 20.9921 2.0501 20.6345L3.81745 15.3326C3.29796 14.1581 3.00977 12.8591 3.00977 11.4951Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Message;
