/** @format */

import React from "react";
import BackIcon from "assets/icons/BackIcon";
import { admins } from "constant/groups";
import { dummyProfile } from "constant/profile";

const GroupAdmins = () => {
  return (
    <div className=" bg-white w-full  lg:border-1 lg:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] rounded-lg">
      <div className="p-5 flex flex-col gap-3 w-full">
        <h2 className="text-[16px] text-black leading-[21px] font-bold">Admins</h2>

        <div className="mt-2 px-2  flex flex-col items-centerjustify-center gap-4">
          {admins.map((admin, i) => (
            <div key={i} className="flex gap-4 text-[#333333]">
              <img
                src={admin?.src || dummyProfile.src}
                alt="group-admin-image"
                className=" rounded-full lg:w-[60px] lg:h-[60px]"
              />
              <div className=" flex flex-col gap-1">
                <h2 className="text-[15px] leading-[19px] font-bold">{admin?.name}</h2>
                <p className="text-[11px] leading-[16px] font-semibold">
                  {admin?.jobTitle} @ {admin.company}
                </p>
                <div className="w-[66px] lg:h-[24px]  bg-[#1455D9] flex justify-center items-center rounded-lg">
                  <p className="text-white text-center text-[12px] leading-[16px] font-semibold ">
                    {admin.groupOner == true ? "Owner" : admin.groupManager ? "Manager" : "Member"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center gap-2 mt-2">
          <p className=" hover:text-[#1455D9] text-[#808080] font-semibold leading-[21px] text-[16px]">
            See all
          </p>
          <BackIcon />
        </div>
      </div>
    </div>
  );
};

export default GroupAdmins;
