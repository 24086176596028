export const text =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nullam ut aenean iculis. Orci ut pharetra, hac tortor facilisi. Sed sit sem e ulvinar. Dolor pharetra netus et sed morbi sed sed habitasse sapien. Ac at diam enim seer sagittis libero amet ultricies. Cursus viverra ante justo, consequat imperdiet volutpat. Ut sollicitudin nibh sed suspend risus. Montes, ut integer risus quis dignissim. A ut commodo velit. sempersagittis libero amet ultricies. Cursus viverra ante justo, consequat imperdiet volutpat. Ut sollicitudin nibh";

export const about = [
  "IBM Certification Intro to Cyber Security",
  "Google Certification In UI/UX Principles",
  "Udemy Certi on Principles of Typography",
  "Cousera Certification In HTML & CSS"
];
export const data = {
  firstName: "Oluwabusayo",
  lastName: "Akinniyi",
  src: "/images/backdrop.png",
  srcTwo: "/images/profile.png",
  title: "Product Manager",
  role: "",
  workAt: "Google",
  mentees: 123,
  industry: "IT Industry",
  certificate: [
    "Increased sales by 15% in the past year.",
    "Led a team of 10 engineers to develop a new product that saved the company $1 million.",
    "Received the Employee of the Year award for outstanding performance.",
    "Published a paper in a top academic journal.",
    "Won a national award for innovation.",
    "Successfully managed a complex project on time and within budget.",
    "Developed a new training program that improved employee productivity by 20%.",
    "Grew a small business into a successful enterprise.",
    "Founded a non-profit organization that has helped thousands of people."
  ],
  experience: {
    title: "Product Manager",
    workAt: "Google",
    date: "June 2019",
    endYear: "present",
    industry: "IT Industry",
    skill: ["UI Design", "communication", "colaboration", "Prototyping", "Probblem Solving"]
  }
};

export const searchProfile = {
  firstName: "Funke ",
  lastName: "Opeke",
  src: "/images/profile_2.png",
  srcTwo: "/images/imgtwo.png",
  title: "Product Manager",
  role: "",
  workAt: "Google",
  mentees: 123,
  industry: "IT Industry",
  education: [
    {
      img: "/images/school.png",
      schoool: "Obafemi Awolowo University",
      course: " Bsc Sociology ",
      startYear: "June 2019",
      endYear: "present"
    },
    {
      img: "/images/school.png",
      schoool: "University of Ilorin",
      course: " Bsc Sociology ",
      startYear: "June 2019",
      endYear: "present"
    }
  ],
  certificate: [
    "Increased sales by 15% in the past year.",
    "Led a team of 10 engineers to develop a new product that saved the company $1 million.",
    "Received the Employee of the Year award for outstanding performance.",
    "Published a paper in a top academic journal.",
    "Won a national award for innovation.",
    "Successfully managed a complex project on time and within budget.",
    "Developed a new training program that improved employee productivity by 20%.",
    "Grew a small business into a successful enterprise.",
    "Founded a non-profit organization that has helped thousands of people."
  ],
  experience: {
    title: "Product Manager",
    workAt: "Google",
    date: "June 2019",
    endYear: "present",
    industry: "IT Industry",
    skill: ["UI Design", "communication", "colaboration", "Prototyping", "Probblem Solving"]
  }
};

export const avaterArray = [
  "https://bit.ly/sage-adebayo",
  "https://bit.ly/prosper-baba",
  "https://bit.ly/code-beast",
  "https://bit.ly/code-beast"
];

export const dummyProfile = {
  name: "Busayo Akinniyi",
  jobTitle: "Product Manager",
  src: "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
  company: "Google"
};
