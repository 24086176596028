import decode from "jwt-decode";
import { ACCESS_TOKEN, REFRESH_TOKEN_KEY, ROLES, Routes, userProfile } from "constant";
import storage from "../utils/storage";
import { encryptData, decryptData } from "../utils/security";
import axios from "axios";

class AuthService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_BASE_URL,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  /* Set token. This is only done after signin. */
  tokenExpirationTime = 0;

  async setTokens(accessToken, refreshToken) {
    return new Promise((resolve, reject) => {
      const encryptedAccessToken = encryptData(accessToken);
      const encryptedRefreshToken = encryptData(refreshToken);

      if (encryptedAccessToken && encryptedRefreshToken) {
        storage.set(ACCESS_TOKEN, encryptedAccessToken);
        storage.set(REFRESH_TOKEN_KEY, encryptedRefreshToken);

        // Resolve the promise once storage is set
        resolve(true);
      } else {
        // Reject the promise if encryption fails
        reject(new Error("Encryption failed"));
      }
    });
  }

  async setProfile(profile) {
    return new Promise((resolve, reject) => {
      try {
        storage.set(userProfile, JSON.stringify(profile));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getProfile() {
    return new Promise((resolve, reject) => {
      try {
        const storedProfile = storage.get(userProfile);
        const parsedProfile = storedProfile ? JSON.parse(storedProfile) : null;
        resolve(parsedProfile);
      } catch (error) {
        reject(error);
      }
    });
  }

  getNextUser(users) {
    const profile = this.getUser();
    if (!profile) return null;
    const { user: currentUser } = profile;
    return users.find((user) => user.userId !== currentUser.userId);
  }
  isCurrentUser(userId) {
    const currentAccount = this.getUser();
    return userId === currentAccount?.user?.userId;
  }
  // Function to get access and refresh tokens from local storage
  getTokens() {
    const accessToken = storage.get(ACCESS_TOKEN);
    const refreshToken = storage.get(REFRESH_TOKEN_KEY);
    if (accessToken && refreshToken) {
      return {
        accessToken: decryptData(accessToken),
        refreshToken: decryptData(refreshToken)
      };
    }
    return {};
  }

  /* Token deletion */
  deleteToken() {
    this.token = "";
    localStorage.setItem(ACCESS_TOKEN, null);
    localStorage.setItem(REFRESH_TOKEN_KEY, null);
    localStorage.clear();
  }

  /* Set refresh token. This is only done after signin. */
  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  /** @returns {null|object} - Date of expiration. */
  getTokenExpirationDate(token) {
    if (!token) return null;
    const decoded = decode(token);
    if (!decoded.exp) {
      return null;
    }
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  /** @returns {boolean|number} - Time till token expiration */
  getTokenExpirationTime(token) {
    if (!token) {
      return 0;
    }
    const date = this.getTokenExpirationDate(token);
    /* offsetSeconds */
    if (date === null) {
      return false;
    }
    return date.valueOf();
  }

  /** @returns {boolean} - Indicating if token is expired. */
  isTokenExpired(token) {
    if (!token) {
      return true;
    }
    const currentTime = new Date().valueOf();
    const tokenExpirationTime = this.tokenExpirationTime || this.getTokenExpirationTime(token);
    return currentTime > tokenExpirationTime;
  }

  /** Refreshes the access token and sets it in the storage */
  async refreshAccessToken() {
    try {
      const { refreshToken } = this.getTokens();
      if (!refreshToken) {
        throw new Error("Refresh token is missing");
      }
      const { data } = await this.axiosInstance.get("/api/auth/refresh/", {
        headers: { Authorization: `Bearer ${refreshToken}` }
      });

      if (data.status) {
        const {
          data: { accessToken, refreshToken: refresh }
        } = data;

        //  console.log({ accessToken });
        await this.setTokens(accessToken, refresh);
        return accessToken;
      }
      return null;
    } catch (error) {
      console.log("Error refreshing access token", error);
      throw error;
    }
  }

  /* A user is authenticated if 1. there is a token && 2. the token is not expired. */
  isAuthenticated() {
    const { accessToken = null } = this.getTokens();
    if (!accessToken) return false;
    const notAuthenticated = this.isTokenExpired(accessToken);
    if (notAuthenticated) this.refreshAccessToken();
    return !notAuthenticated;
  }

  /**
   * @param {Object} Config - Config object.
   * @param {String} Config.key - The name of the claim we are trying to access.
   * @param {Boolean} [Config.custom] - Wether or not the claim is a custom claim set by us manually.
   * @returns {undefined|string} - Claim if available. Undefined if not.
   * */
  getClaim({ key, custom }) {
    const { accessToken } = this.getTokens();
    if (!accessToken) return null;
    const { user } = decode(accessToken);
    if (custom) return user[`custom:${key}`];
    return user[key];
  }

  isProfileCreated() {
    return !!this.getClaim({ key: "profileCreated" });
  }

  /**
   * @returns {object} - returns the user object .
   *  */
  getUser = () => {
    const { accessToken } = this.getTokens();
    if (!accessToken) return null;
    const decoded = decode(accessToken);
    return decoded;
  };

  getRouteToNavigateTo = () => {
    let { accessToken } = this.getTokens();
    if (!accessToken) return Routes.Login;
    const roles = this.getClaim({ key: "role" })?.toLowerCase();
    if (roles === ROLES.mentor.toLowerCase()) {
      return Routes.MentorOnboarding;
    } else if (roles === ROLES.mentee.toLowerCase()) {
      return Routes.MenteeOnboarding;
    } else {
      return Routes.Mentee;
    }
  };
}
const AuthServiceInstance = new AuthService();

export default AuthServiceInstance;
