import request from "..";

export const createPost = (postData) => {
  const formData = new FormData();
  if (postData.text) {
    formData.append("text", postData.text);
  }

  if (postData.id) {
    formData.append("id", postData.id);
  }
  if (postData.postType) {
    formData.append("postType", postData.postType);
  }

  // Check if files exist and append them if needed
  if (postData.files) {
    if (Array.isArray(postData.files)) {
      postData.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    } else {
      formData.append("files", postData.files);
    }
  }

  return request.post("feed", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const createComment = (postData, type = "feed") => {
  return request.post(`${type}/comment`, postData);
};

export const performAction = (id, type = "feed", targetType, action) => {
  return request.put(`${type}/action/${targetType}/${id}/${action}`);
};

export const getPost = ({ skip = 0, limit = 10 } = {}) => {
  return request.get(`feed/timeline/${skip ?? 0}/${limit ?? 10}`);
};
