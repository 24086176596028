import request from "api";

export const getMyFriends = async () => {
  return await request.get("/friends");
};

export const addFriends = async (id) => {
  return await request.post(`/friends/send-request/${id}`);
};

export const getFriendRequestType = async (params) => {
  return await request.get(`/friends/requests/${params}`);
};

export const exploreOtherFriends = async () => {
  return await request.get("/friends/explore");
};

export const respondToFriendRequest = async ({ status, id }) => {
  return await request.put(`/friends/${status}/request/${id}`);
};
