/** @format */

import React from "react";

const AddInputImageIcon = () => {
  return (
    <div>
      <svg
        width="62"
        height="61"
        viewBox="0 0 62 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2350_3112)">
          <path
            d="M61.6906 4.9563V46.8938H59.4031C59.5938 46.3219 59.5938 45.5594 59.5938 44.9875C59.5938 42.5094 59.0219 40.4125 57.8781 38.5063V8.7688H4.3125V42.8907H6.98125L19.3719 22.1125L26.8062 34.3125L32.7156 26.4969L38.4344 33.9313C37.2906 34.6938 36.3375 35.6469 35.575 36.6L32.7156 32.7875L26.425 40.9844L19.3719 29.5469L11.5563 42.5094H33.0969C33.0969 42.7 33.0969 42.7 33.0969 42.8907C32.9062 43.4625 32.9062 44.225 32.9062 44.7969C32.9062 45.3688 32.9062 45.75 32.9062 46.3219C32.9062 46.5125 32.9062 46.5125 32.9062 46.7032H0.5V4.9563H61.6906ZM55.0187 17.9188C55.0187 21.5407 51.9688 24.5907 48.3469 24.5907C44.725 24.5907 41.675 21.5407 41.675 17.9188C41.675 14.2969 44.725 11.2469 48.3469 11.2469C51.9688 11.2469 55.0187 14.4875 55.0187 17.9188ZM51.2062 17.9188C51.2062 16.3938 49.8719 15.0594 48.3469 15.0594C46.8219 15.0594 45.4875 16.3938 45.4875 17.9188C45.4875 19.4438 46.8219 20.7782 48.3469 20.7782C49.8719 20.7782 51.2062 19.6344 51.2062 17.9188Z"
            fill="#808080"
          />
          <path
            d="M48.1562 42.8907H51.9687V46.7032H48.1562V50.5157H44.3437V46.7032H40.5312V42.8907H44.3437V39.0782H48.1562V42.8907ZM57.3062 44.7969C57.3062 51.0875 52.35 56.0438 46.0594 56.0438C39.7687 56.0438 34.8125 51.0875 34.8125 44.7969C34.8125 38.5063 39.7687 33.55 46.0594 33.55C52.35 33.55 57.3062 38.6969 57.3062 44.7969ZM53.4937 44.7969C53.4937 40.7938 50.2531 37.3625 46.0594 37.3625C41.8656 37.3625 38.625 40.6032 38.625 44.7969C38.625 48.9907 41.8656 52.2313 46.0594 52.2313C50.2531 52.2313 53.4937 49.1813 53.4937 44.7969Z"
            fill="#808080"
          />
        </g>
        <defs>
          <clipPath id="clip0_2350_3112">
            <rect width="61" height="61" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AddInputImageIcon;
