/** @format */

import React from "react";
import DeleteIcon from "assets/icons/DeleteIcon";
import EditIcon from "assets/icons/EditIcon";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { useStore } from "store";
import DeleteConfirmationModal from "components/Modals/DeleteConfirmationModal";
import { useExperienceDeleteMututation } from "hooks/user/experience";

const ExperienceItem = ({ experience, actionable = true }) => {
  const {
    experienceStore: {
      modal: { removeExperience: open },
      setSelectedExperience,
      setRemoveExperienceModal
    }
  } = useStore();

  const { mutate: deleteExperience } = useExperienceDeleteMututation(() => {
    setRemoveExperienceModal();
  });

  const handleDeleteExperience = () => {
    deleteExperience(experience.id);
  };

  const toggleDeleteModal = () => {
    setRemoveExperienceModal(experience.id);
  };
  return (
    <>
      <div className="flex justify-between gap-1" key={experience?.id}>
        <div className="flex flex-col gap-[4px] w-full">
          <div className="flex justify-between  items-center">
            <h3 className="font-bold text-[16px] leading-[30px] text-[#333] font-nunito-sans not-italic">
              {experience?.title}
            </h3>

            {actionable && (
              <div className="mr-2 pointer flex items-center gap-4">
                <button className="" onClick={toggleDeleteModal}>
                  <DeleteIcon width={22} />
                </button>
                <button onClick={() => setSelectedExperience(experience)}>
                  <EditIcon size="18" width={22} />
                </button>
              </div>
            )}
          </div>

          <p className=" font-[600] text-[14px] text-[#808080] leading-[19px]">
            {dayjs(experience?.startDate).format("MMM-YYYY")}
            <span className="text-[14px] leading-[24px] text-[#333] font-nunito-sans"> - </span>
            {new Date(experience?.endDate).valueOf() > Date.now()
              ? "Present"
              : `${dayjs(experience?.endDate).format("MMM-YYYY")}`}
          </p>
          <p className="font-semibold text-[16px] leading-[24px] text-[#333] font-nunito-sans">
            {experience?.company}
          </p>

          <div className="flex justify-start gap-2 item-center w-full pt-1">
            <p className=" pt-1 font-[600] text-[14px] md:text-[16px] leading-[18px]">Skill:</p>
            <div className="justify-self-start flex gap-3 flex-wrap ">
              {experience?.skills?.map((skill, i) => (
                <div
                  key={i}
                  className="text-[11px] flex gap-2 items-center border-[1px] border-black justify-between rounded-[4px] px-[6px] py-[4px]"
                >
                  <p>{skill}</p>
                  {actionable && (
                    <p>
                      <DeleteIcon />
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {open && actionable ? (
        <DeleteConfirmationModal text="experience" deleteFn={handleDeleteExperience} />
      ) : null}
    </>
  );
};

export default observer(ExperienceItem);
