/** @format */

import React, { useEffect, useState } from "react";
import dummyImg from "assets/images/blank-profile-picture-gb818349d8_1280.png";
import { useStore } from "store";

const PostAvater = () => {
  const { authService } = useStore();

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const result = await authService.getProfile();

        setProfile(result?.profile);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [authService]);

  return (
    <div className="flex gap-7 ">
      <div className="h-[60px] w-[60px] lg:h-[50px] lg:w-[50px]">
        <img
          src={profile?.profilePic || dummyImg}
          alt="profile-image"
          className="object-cover rounded-full h-[60px] w-[60px] lg:h-[50px] lg:w-[50px]"
        />
      </div>
    </div>
  );
};

export default PostAvater;
