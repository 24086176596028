/** @format */

import React, { useState, useEffect } from "react";
import { AvatarBody } from "./Connections";

const Group = ({ color, num }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [spacing, setSpacing] = useState(-6);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function resizeWidth() {
    setWidth(window.innerWidth);
    if (width > 680) {
      return setSpacing(-6);
    } else setSpacing(-4);
  }

  useEffect(() => {
    window.addEventListener("resize", resizeWidth);

    return () => window.removeEventListener("resize", resizeWidth);
  }, [resizeWidth, width, spacing]);

  return (
    <div className="flex justify-center items-center ">
      <div className="h-[70px] lg:w-[247px] w-full  flex justify-between items-center  border-[1px] bg-[rgba(140,44,27,0.05)] border-[#d91414] rounded-[5px]">
        <AvatarBody color={color} num={num} spacing={spacing}>
          <p className="border-[1] text-[14px] text-[#c0565a] lg:font-bold">
            {num}
            {color}
          </p>
        </AvatarBody>
      </div>
    </div>
  );
};

export default Group;
