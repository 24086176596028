/** @format */

import React, { useState } from "react";
import FeedProfile from "./FeedProfile";
import FeedText from "./component/FeedText";
import FeedImage from "./component/FeedImage";
import FeedInteractivePanel from "./component/FeedInteractivePanel";
import CommentPanel from "components/Feed/component/CommentPanel";
import PostAvater from "components/PostInput/PostAvater";
import PostInput from "components/PostInput/PostInput";
import Ellipse from "assets/icons/Ellipse";

const PostPanel = ({ post, postType, saveComment }) => {
  const [showComments, setShowComments] = useState(undefined);

  const [postData, setPostData] = useState("");
  const { text, likes, images, comments = [] } = post;
  const totalLikes = likes.length;
  const totalComments = comments.length;

  const options = {
    id: post.id,
    postType,
    postData,
    setPostData,
    send: async (e) => {
      try {
        const payload = {
          ...e,
          postType,
          postId: post.id
        };

        await saveComment(payload);
        setPostData("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const displayCurrentCommet = (id) => {
    return id !== showComments ? setShowComments(id) : setShowComments(undefined);
  };

  return (
    <section className="w-[100%] flex flex-col gap-4 py-4 bg-white h-full border-1 shadow-[1px_3px_4px_rgba(0,0,0,0.1)] rounded-md relative ">
      <div>
        <FeedProfile owner={post.owner} createdAt={post.createdAt} updatedAt={post.updatedAt} />
      </div>
      <div className="w-full">
        <FeedText text={text} />
      </div>

      <div className="w-full">
        {images.length > 0 && <FeedImage src="/images/group.jpg" />}

        <div className=" px-6  text-[#808080] text-[12px] font-semibold leading-[19px] ">
          <div className="border-b-[1px] border-[#CCCCCC] py-1 flex justify-between items-center">
            <p>
              {totalLikes} impression{totalLikes > 1 ? "s" : null}
            </p>
            <div className="flex  items-center gap-2">
              <p> {totalComments} comment</p>
              <Ellipse />
              <p> 12 repost</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" z-10 w-full flex justify-center items-center">
        <div className="md:w-[85%] w-[90%]">
          <FeedInteractivePanel
            showComment={displayCurrentCommet}
            likes={likes}
            postType={postType}
            id={post.id}
          />
        </div>
      </div>

      <div className="w-full">
        <div className="flex px-4 gap-2 items-center ">
          <PostAvater />
          <PostInput options={options} type="comment" />
        </div>
      </div>
      <div>
        <CommentPanel
          showComment={showComments}
          comments={comments}
          postType={postType}
          id={post.id}
        />
      </div>
    </section>
  );
};

export default PostPanel;
