/** @format */

import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  FormControl,
  Input,
  ModalOverlay,
  FormLabel,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  Flex,
  Spacer,
  Textarea
} from "@chakra-ui/react";
import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import SaveIcon from "assets/icons/SaveIcon";
import PlusIcon from "assets/icons/PlusIcon";
import useWidth from "utils/useWidth";
import ModalButton from "components/Button/ModalButton";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import DustbinIcon from "assets/icons/DustbinIcon";
import { useStore } from "store";

const CertificateModal = ({
  isOpen,
  mode,
  toggleModal,
  value,
  handleChange,
  handleSubmit,
  deleteCertifcate
}) => {
  const { size } = useWidth("sm", "lg");

  const removeCertificate = () => {
    if (mode === "Edit") {
      const id = value.id;
      deleteCertifcate(id);
    }
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={toggleModal}
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Flex alignItems="center" gap={2}>
                <Box
                  border="1px solid gray"
                  p="4px"
                  rounded="4px"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <ArrowLeftIcon />
                </Box>
                <Box
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={700}
                  ml="3px"
                >
                  {mode} Certificate
                </Box>
              </Flex>

              <Spacer />
              <Box>
                <Flex alignItems="center" gap={2}>
                  <Box
                    border="1px solid gray"
                    p="4px"
                    rounded="4px "
                    onClick={() => toggleModal("Add")}
                  >
                    <PlusIcon />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <Flex justify="flex-end">
              <Flex gap={1} align="center" fontSize={{ sm: "12px", lg: "14px" }} p="1rem">
                <Text color="red">*</Text> Indicates required
              </Flex>
            </Flex>
            <FormControl>
              <FormLabel lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                <Flex gap={1} align="center" fontSize={{ sm: "12px", lg: "14px" }}>
                  Title<Text color="red">*</Text>
                </Flex>
              </FormLabel>

              <Input
                name="title"
                value={value.title}
                type="text"
                placeholder="Ex: IBM Certification Intro to Cyber Security"
                _placeholder={{ text: "#808080", fontSize: "12px", p: "1rem" }}
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                <Flex gap={1} align="center" fontSize={{ sm: "12px", lg: "14px" }}>
                  InstitutionLogo <Text color="red">*</Text>
                </Flex>
              </FormLabel>

              <Input
                name="institutionLogo"
                value={value.institutionLogo}
                type="text"
                placeholder="Ex: IBM"
                _placeholder={{ text: "#808080", fontSize: "12px", p: "1rem" }}
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                <Flex gap={1} align="center" fontSize={{ sm: "12px", lg: "14px" }}>
                  Organization<Text color="red">*</Text>
                </Flex>
              </FormLabel>

              <Input
                name="organization"
                value={value.organization}
                type="text"
                placeholder="Ex: IBM"
                _placeholder={{ text: "#808080", fontSize: "12px", p: "1rem" }}
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <Box mt={4} w="100%">
              <Box>
                <Flex
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontWeight={600}
                  gap={1}
                  align="center"
                  fontSize={{ sm: "12px", lg: "14px" }}
                >
                  AwardDate <Text color="red">*</Text>
                </Flex>
                <Box py="8px">
                  <Input
                    id="awardDate"
                    fontSize={{ base: "1rem", md: "0.875rem" }}
                    bgColor="#F3F5F7"
                    name="awardDate"
                    type="date"
                    placeholder="MM/YYYY"
                    value={dayjs(value.awardDate).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    aria-label="date"
                  />
                </Box>
              </Box>
            </Box>

            {/*  */}

            {/* <Box mt={4} w="100%">
              <Box>
                <Flex
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontWeight={600}
                  gap={1}
                  align="center"
                  fontSize={{ sm: "12px", lg: "14px" }}
                >
                  Description
                </Flex>
                <Box py="8px">
                  <Textarea
                    id="description"
                    name="description"
                    value={value.description}
                    type="text"
                    placeholder="Ex: Certificate Description"
                    _placeholder={{
                      text: "#808080",
                      fontSize: "12px",
                      p: "1rem",
                    }}
                    background="rgba(20, 85, 217, 0.05)"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            </Box> */}
            {/*  */}
          </ModalBody>

          <ModalFooter>
            <Flex alignItems="center" gap={4}>
              {mode === "Edit" && (
                <ModalButton
                  bg="#fff"
                  handleSubmit={() => removeCertificate()}
                  text="#0C378B"
                  title="Delete"
                >
                  <DustbinIcon />
                </ModalButton>
              )}

              <ModalButton title="Save" handleSubmit={() => handleSubmit()}>
                <Box>
                  <SaveIcon />
                </Box>
              </ModalButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default observer(CertificateModal);
