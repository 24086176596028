import React, { useEffect, useState } from "react";

const useWidth = (width1 = "xs", width2 = "4xl") => {
  const [width, setWidth] = useState(window.innerWidth);
  const [size, setSize] = useState(width2);
  const handleWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    document.addEventListener("resize", handleWidth);

    setWidth(window.innerWidth);
    return () => document.removeEventListener("resize", handleWidth);
  }, [width]);

  useEffect(() => {
    if (width > 700) setSize(width2);
    if (width < 700) setSize(width1);
  }, [size, width, width1, width2]);
  return { width, size };
};

export default useWidth;
