import React from "react";

const ResourceCourseOverview = () => {
  return (
    <main className="w-full  text-[14px] text-[#333] md:leading-6 font-semibold  py-2 lg:px-6  px-3 bg-secondary-bg md:border-1 md:shadow-[1px_2px_4px_rgba(0,0,0,0.3)] md:rounded-md ">
      <h3 className="lg:text-[20px] lg:leading-6 font-bold my-3">Overview</h3>
      <div className="text-[12px] leading-6 ">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
        ullamcorper mattis, pulvinar dapibus leo. Pellentesque vitae quam interdum, varius tellus
        eget, consequat eros. Aenean vel magna vitae felis sollicitudin efficitur. In lobortis orci
        a mi egestas, id tincidunt enim semper. Nunc ac massa tincidunt, hendrerit lorem eget,
        aliquet dolor. Quisque velit turpis, viverra ut urna eget, consectetur faucibus diam.
        Praesent sed nunc id mi semper porta. Nullam placerat turpis sed mi rhoncus, eget
        scelerisque tortor laoreet. Fusce et lorem in urna porttitor convallis. Ut ultrices tortor
        quis neque cursus, id ultrices lorem accumsan. Curabitur orci felis, fringilla et justo
        eget, ultrices interdum nisl. Mauris in lectus porta, pulvinar arcu quis, tincidunt ipsum.
        Nunc viverra lacinia lectus, ut consequat diam condimentum at. Donec a sem eget justo
        consectetur feugiat. Phasellus nec lacus lacinia, ornare ligula in, rutrum odio. Vestibulum
        ac sem sit amet lorem semper suscipit. Fusce convallis eros id ipsum pretium, et tristique
        risus cursus. Ut at eros at metus venenatis tempus. Donec sit amet lacus velit. Sed quis sem
        lacinia, ullamcorper lorem eu, sagittis nibh. Nunc eu eros eget nisi egestas ultrices. Morbi
        eget justo quis lorem dapibus semper. Nulla facilisi. Nullam eget justo sit amet ligula
        posuere posuere. Maecenas ac ipsum quis mauris tincidunt tempus. Maecenas et ligula a magna
        tincidunt scelerisque. Sed vulputate urna id arcu porta, sed facilisis eros gravida. In hac
        habitasse platea dictumst. Etiam aliquet lacinia ligula, in tincidunt risus aliquet quis.
        Aenean eget justo sit amet sem bibendum vulputate. Maecenas eget eros sed urna feugiat
        auctor. Maecenas sed nibh nec nunc posuere eleifend. Pellentesque habitant morbi tristique
        senectus et netus et malesuada Donec ac mi a enim iaculis consequat. Vivamus interdum felis
        ac sem porta, id scelerisque turpis tincidunt. Donec vel lacus et mauris pretium tincidunt.
        Phasellus sed risus sed purus tempus scelerisque. Nunc aliquam eros
      </div>
    </main>
  );
};

export default ResourceCourseOverview;
