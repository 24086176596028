/** @format */

import React from "react";
import { Avatar } from "@chakra-ui/react";
import DeleteIcon from "assets/icons/DeleteIcon";
import EditIcon from "assets/icons/EditIcon";
import dayjs from "dayjs";
import DeleteEducationModal from "components/Modals/DeleteEducationModal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useEducationDeleteMutation } from "hooks/user/education";

const EducationItem = ({ education, actionable = false, key = "" }) => {
  const {
    educationStore: {
      modal: { removeEducation: open },
      setSeletedEducation,
      setRemoveEducationModal
    }
  } = useStore();

  const { mutate: deleteEducation } = useEducationDeleteMutation(() => {
    setRemoveEducationModal();
  });

  const handleDeleteEducation = () => {
    deleteEducation(education.id);
  };

  const toggleDeleteModal = () => {
    setRemoveEducationModal(education.id);
  };

  return (
    <>
      <div className="flex gap-4 justify-items-start cursor-pointer" key={education.id || key}>
        <div className="lg:w-[44px] lg:h-[51px]">
          {education.institution ? (
            <img src={education?.institutionLogo} alt="school" className="object-cover" />
          ) : (
            <Avatar src="https://bit.ly/broken-link" />
          )}
        </div>
        <div className="flex flex-col gap-[4px] text-[#333] w-[100%]">
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-[16px] leading-[21px]">{education?.institutionName}</h3>
            {actionable && (
              <div className="mr-2 pointer flex items-center gap-4">
                <button onClick={toggleDeleteModal}>
                  <DeleteIcon width={22} />
                </button>
                <button onClick={() => setSeletedEducation(education)}>
                  <EditIcon size="18" width={22} />
                </button>
              </div>
            )}
          </div>
          <p className="font-semibold text-[14px] leading-[19px] ">{education?.degree}</p>
          <p className="font-semibold text-[14px] leading-[19px] text-[#808080]">
            {dayjs(education?.startDate).format("MMM-YYYY")}

            <span className="text-[14px] text-"> - </span>
            {dayjs(education?.endDate).format("MMM-YYYY")}
          </p>
        </div>
      </div>
      {open && actionable ? (
        <DeleteEducationModal text="education" deleteFn={handleDeleteEducation} />
      ) : null}
    </>
  );
};

export default observer(EducationItem);
