/** @format */

import React from "react";

const CircleIcon = () => {
  return (
    <div>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9.5" r="7.45455" fill="#1455D9" stroke="white" strokeWidth="2.90909" />
      </svg>
    </div>
  );
};

export default CircleIcon;
