import React from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ModuleActions } from "./ModuleActions";

export const ResourceNav = ({
  title,
  showModuleActions,
  onSave,
  handleActiveModule,
  handleSubmit
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-[100%] p-1 lg:px-3 py-3 relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.1)] md:h-[56px] bg-white flex justify-between items-center rounded-[5px]">
      <div className="flex items-center gap-3 lg:gap-8 w-[60%]">
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeftLong size={18} />
        </div>
        <div className="font-bold text-[16px] lg:text-[20px] text-[#333] leading-6">{title}</div>
      </div>

      <div className="flex items-center gap-3">
        {showModuleActions ? (
          <ModuleActions handleActiveModule={handleActiveModule} handleSubmit={handleSubmit} />
        ) : (
          <button
            className="py-[8px] px-[16px] lg:py-[9px] flex items-center gap-3 cursor-pointer lg:px-[20px] rounded-[8px] bg-[#1455d9] text-[#fff] font-bold lg:leading-6 text-base"
            onClick={onSave}
          >
            <p className="text-[16px]">Save</p>
            <FaArrowRightLong color="#fff" size={24} />
          </button>
        )}
      </div>
    </div>
  );
};
