/** @format */

import React, { useState, useEffect } from "react";
import { Avatar, AvatarGroup } from "@chakra-ui/react";
import AddIcon from "assets/icons/AddIcon";
import { avaterArray } from "constant/profile";
import useWidth from "utils/useWidth";

// filter the incoming

export const Avatars = ({ spacing, avaterArray }) => {
  return (
    <div className="w-full">
      <AvatarGroup spacing={spacing}>
        {avaterArray.map((avatar, i) => (
          <Avatar key={i} name="Prosper Otemuyiwa" src={avatar} />
        ))}
      </AvatarGroup>
    </div>
  );
};

export const AvatarBody = ({ children, color, num, spacing }) => {
  return (
    <div className="flex justify-between w-full">
      <div className="pr-2 pl-1">
        <Avatars avaterArray={avaterArray} spacing={spacing} />
      </div>

      <div className="flex justify-end justify-self gap-2-end flex-col items-end items-self-end pr-2">
        <p className=" text-[14px] lg:font-medium">
          <AddIcon />
        </p>
        {children}
      </div>
    </div>
  );
};

const Connections = ({ color, num }) => {
  const { width, size } = useWidth();
  const [spacing, setSpacing] = useState(-6);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function resizeWidth() {
    if (width > 680) {
      return setSpacing(-5);
    } else setSpacing(-3);
  }

  useEffect(() => {
    window.addEventListener("resize", resizeWidth);

    return () => window.removeEventListener("resize", resizeWidth);
  }, [resizeWidth, width]);
  return (
    <div className="bg-secondary-bg">
      <div className="flex justify-center items-center ">
        <div className="h-[70px] lg:w-[247px] w-full  flex justify-between items-center  border-[1px] bg-[rgba(20,85,217,0.05)] border-[#1455D9] rounded-[5px] ">
          <AvatarBody color={color} num={43} spacing={spacing}>
            <p className="border-[1] text-[14px] text-[#1455D9] lg:font-bold">
              {num}
              {color}
            </p>
          </AvatarBody>
        </div>
        <div className="h-[80px] lg:w-[267]"></div>
      </div>
    </div>
  );
};

export default Connections;
