/** @format */

import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import Nav from "components/Nav";
import ChatSystem from "components/ChatSystem";
import { Outlet, useNavigate } from "react-router-dom";
import { useStore } from "store";
import { Routes } from "constant";

export default function UserLayout() {
  const { authService } = useStore();
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const isAuthenticated = authService.isAuthenticated();
  useEffect(() => {
    !isAuthenticated && navigate(Routes.Login);
  }, [isAuthenticated, navigate]);

  return (
    <Disclosure as="div" className="relative bg-primary-bg" onClick={() => setShowNav(false)}>
      <>
        <Nav showNav={showNav} setShowNav={setShowNav} />

        <main className="relative lg:px-4 mt-5 lg:mt-10 rounded-md mx-2 md:mx-0">
          <div className="w-full">
            <Outlet />
          </div>
        </main>
      </>

      {/* Conditionally hide ChatSystem on mobile screens */}
      <div className="hidden lg:block">
        <ChatSystem />
      </div>
    </Disclosure>
  );
}
