import React from "react";
import { useStore } from "store";
import { ChatService } from "utils/chat";
import { getTimeAgo } from "utils/helpers";
import { observer } from "mobx-react-lite";

const chatService = new ChatService();

const ConversationUser = ({ conversation, isActive }) => {
  const {
    chatStore: { joinConversation }
  } = useStore();

  const { users, lastMessage, lastUpdated, lastSender } = conversation;
  const user = chatService.getNextUser(users);

  const handleJoinConversation = () => {
    joinConversation(user.userId);
  };
  const lastUpdatedRelative = getTimeAgo(lastUpdated);
  return (
    <li
      className={`flex items-center justify-between py-3 px-[12.76px] ${
        isActive ? "bg-gray-100" : ""
      }`}
      onClick={handleJoinConversation}
    >
      <div className="flex items-center">
        <img src={user.avatarUrl} alt="" className="h-8 w-8 rounded-full" />
        <div className="flex flex-col items-start ml-[9.02px]">
          <p className="text-sm font-medium text-gray-900 truncate">{user.name}</p>
          <p className="text-[11.2173px] text-[#989797] leading-[14px] font-[400] truncate">
            {lastSender}: {lastMessage}
          </p>
        </div>
      </div>
      <button
        type="button"
        className="ml-6 rounded-md bg-white text-sm font-medium text-blue-100 focus:outline-none"
      >
        {lastUpdatedRelative}
        <span className="sr-only"> {lastUpdatedRelative}</span>
      </button>
    </li>
  );
};

export default observer(ConversationUser);
