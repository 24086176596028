/** @format */

import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import {
  addUserCertificate,
  deleteUserCertificate,
  getUserCertificate,
  updateUserCertificate
} from "api/user";
import { toast } from "react-toastify";

export const useCertificate = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["certificate"],
    queryFn: () => getUserCertificate(),
    staleTime: 10000
  });

  return {
    data,
    isLoading
  };
};

export const useAddCertificate = (closeModal) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: addUserCertificate,
    onSuccess: (data) => {
      closeModal();
      toast(data.message);
      queryClient.invalidateQueries(["certificate"]);
    }
  });

  const addCertificate = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: addCertificate
  };
};

export const useUpdateCertificate = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: updateUserCertificate,
    onSuccess: (data) => {
      toast(data.message);
      queryClient.invalidateQueries(["certificate"]);
    }
  });

  const updateCertificate = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: updateCertificate
  };
};

export const useDeleteCertificate = (closeModal) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: deleteUserCertificate,
    onSuccess: (data) => {
      closeModal();
      toast(data.message);
      queryClient.invalidateQueries(["certificate"]);
    }
  });

  const deleteCertificate = (id) => {
    mutate.mutate(id);
  };

  return {
    mutate: deleteCertificate
  };
};
