/** @format */

import React, { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import useWidth from "utils/useWidth";
import ChatList from "./component/ChatList";
import ChatCard from "./component/ChatCard";
import EmptyComponent from "components/EmptyState/EmptyComponent";

const MessageBoard = () => {
  const { width } = useWidth();
  const [w, setW] = useState(width);
  const [currentUser, setCurrentUser] = useState(false);

  useEffect(() => {
    setW(width);
  }, [width, w]);

  return (
    <div className="lg:h-[998px] lg:flex bg-white w-[100%] lg:border-1 lg:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] rounded-[8px]">
      {w < 992 ? (
        currentUser ? (
          <div className="w-[100%] lg:h-full">
            <ChatCard />
          </div>
        ) : (
          <div className="w-[100%] lg:h-full">
            <ChatList setCurrentUser={setCurrentUser} />
          </div>
        )
      ) : (
        <div className="flex w-[100%]">
          <div className="lg:w-[35%] w-[100%] lg:h-full border-r-[2px] border-[#CCCCCC]">
            <ChatList setCurrentUser={setCurrentUser} />
          </div>
          <div className="lg:w-[65%] lg:h-full">
            {currentUser ? <ChatCard /> : <EmptyComponent />}
          </div>
        </div>
      )}
    </div>
  );
};
export default MessageBoard;
