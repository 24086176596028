/** @format */

import Footer from "components/footer/Footer";
import RightSideBar from "pages/Dashboard/component/RightSideBar";
import Text from "pages/Dashboard/component/Text";
import About from "pages/Dashboard/profile/component/about/About";
import ProfessionAndCareer from "pages/Dashboard/profile/component/career/ProfessionAndCareer";

import React from "react";
import { useParams } from "react-router-dom";
import { useGetFriendProfile } from "hooks/user";
import UserProfileCard from "./component/UserProfileCard";
import ExperienceItem from "pages/Dashboard/profile/Experience/ExperienceItem";
import Spinner from "components/Spinner";
import EducationItem from "pages/Dashboard/profile/component/Education/EducationItem";

const UserProfile = () => {
  const { userId } = useParams();

  const { data: { data: profile = {} } = {}, isLoading } = useGetFriendProfile(userId);

  return (
    <div className="flex justify-center items-center w-full mb-1 sm:px-3">
      <section className="lg:w-[68%]  sm:w-full ">
        <div className="grid lg:grid-cols-4 sm:grid-cols-1 sm:grid-flow-dense lg:gap-8 md:w-[100% ] relative">
          <div className=" hidden md:inline-block ">
            <RightSideBar />
          </div>

          <div className=" md:col-start-2 md:col-span-4 ">
            <div className="w-full lg:flex gap-4">
              <section className=" md:w-[68%] grid grid-cols-1 gap-8">
                <div>
                  <UserProfileCard data={profile} />
                </div>

                <div className=" lg:hidden">
                  <About
                    // text={profile?.bio}
                    header="About"
                    actionable={false}
                    userBio={profile?.bio}
                  />
                </div>

                <div className="w-[100%] bg-secondary-bg relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.1)] rounded-md md:p-6 px-3 p-2 ">
                  <div className="flex justify-between items-center">
                    <h3 className="font-bold leading[27px] py-2 text-[20px]">Experience</h3>
                  </div>

                  <div className="flex flex-col gap-5 border-[1px]  border-[#CCCCCC] pl-3 md:border-none rounded-[4px]">
                    {isLoading ? (
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    ) : Array.isArray(profile?.experiences) && profile?.experiences?.length > 0 ? (
                      profile?.experiences?.map((exp) => (
                        <ExperienceItem key={exp.id} experience={exp} actionable={false} />
                      ))
                    ) : (
                      <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
                        No experience data available.
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-[100%]  relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.2)] rounded-md p-2 px-3 md:p-6 bg-secondary-bg">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <h3 className="font-bold leading[27px] py-2 text-[20px] ">Education</h3>
                    </div>
                    <div className="flex flex-col gap-2 md:gap-6 border-[1px] rounded-[4px] border-[#CCCCCC] pl-3 md:border-none">
                      {isLoading ? (
                        <div className="flex justify-center items-center">
                          <Spinner />
                        </div>
                      ) : profile && profile?.educations?.length > 0 ? (
                        profile?.educations?.map((education, i) => (
                          <EducationItem key={i} education={education} checkProfile={false} />
                        ))
                      ) : (
                        <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
                          No education data available.
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <ProfessionAndCareer certificate={profile?.certificates} />
                </div>
              </section>

              <section className="md:w-[30%] mb:px-6 ">
                <div className="w-full  mb-5  mb:border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.2)] rounded-md p-4">
                  <div className="flex justify-between items-center">
                    <h3 className="font-bold leading[27.28px] py-2 text-[20px] ">certificate</h3>
                  </div>
                  <div className="border-[1px] border-[#CCCCCC] p-2 md:border-none">
                    <Text text={profile?.certificates} />
                  </div>
                </div>

                <div className="w-[100%] hidden lg:inline-block">
                  <About
                    // text={profile?.bio}
                    header="About"
                    actionable={false}
                    userBio={profile?.bio}
                  />
                </div>
              </section>
            </div>
          </div>
          <div className="md:col-start-1 md:col-span-5 ">
            <Footer />
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserProfile;
