import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Box } from "@chakra-ui/react";
import { ProxSureWhiteLogo } from "styles/icons";
import { Link } from "react-router-dom";

export const LandingPageNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState(false);

  function handleClick() {
    setisOpen(!isOpen);
  }

  return (
    <div className="relative bg-blue">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4  sm:px-6  md:space-x-10 lg:px-10">
        <div className="flex justify-start lg:w-0 lg:flex-1 py-4 px-4">
          <Box boxSize="80px" position="relative">
            <a href="/home">
              <span className="sr-only">Proxsure</span>
              <ProxSureWhiteLogo className="text-9xl" position="absolute" top="0px" />
            </a>
          </Box>
        </div>
        <div className="mr-2 -my-2 lg:hidden">
          <button
            type="button"
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
            onClick={handleClick}
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav className="hidden lg:flex lg:space-x-10 space-x-8">
          <Link to="/home">
            <p className="py-2 text-base font-bold text-white hover:text-light-blue">Home</p>
          </Link>
          <Link to="/about">
            <p className="py-2 text-base font-bold text-white hover:text-light-blue">About</p>
          </Link>

          <Link to="/services">
            <p className="py-2 text-base font-bold text-white hover:text-light-blue">Services</p>
          </Link>

          <Link to="/auth/mentor-sign-up">
            <p className="py-2 text-base font-bold text-white hover:text-light-blue">Register</p>
          </Link>

          <button
            className="inline-flex items-center text-base font-bold text-blue hover:text-light-blue border border-transparent rounded-md  bg-white hover:bg-gray-50 px-4 py-2"
            onClick={() => navigate("/auth/sign-in")}
          >
            LOG IN
          </button>
        </nav>
      </div>
      {/* mobile */}
      <div
        className={`absolute z-30 top-0 inset-x-0 transition transform origin-top-right md:hidden ${
          isOpen ? "block" : "hidden"
        } `}
      >
        <div className="rounded-lg shadow-lg bg-blue  divide-y-2 divide-blue-100">
          <div className="pt-2 pb-2 px-5">
            <div className="flex items-center justify-between">
              <ProxSureWhiteLogo className="text-9xl" />
              <div className="-mr-2">
                <button
                  type="button"
                  className=" rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none"
                  onClick={handleClick}
                >
                  <span className="sr-only">Close menu</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="grid grid-cols-1 gap-4">
              <a href="/home" className="px-4 text-base font-bold hover:text-light-blue text-white">
                Home
              </a>

              <a
                href="/about"
                className="px-4 text-base font-bold hover:text-light-blue text-white"
              >
                About
              </a>
              <a
                href="/services"
                className="px-4 text-base font-bold hover:text-light-blue text-white"
              >
                Services
              </a>
              <a
                href="/auth/mentor-sign-up"
                className="px-4 text-base font-bold hover:text-light-blue text-white"
              >
                Register
              </a>
              <button
                href="/login"
                className="inline-flex items-center text-base w-full text-center font-medium text-blue hover:text-light-blue border border-transparent rounded-md  bg-white hover:bg-gray-50 px-4 py-2"
                onClick={() => navigate("/auth/sign-in")}
              >
                LOG IN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
