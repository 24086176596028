import React from "react";
import { Button, Modal, ModalBody, ModalContent, ModalOverlay, Flex, Text } from "@chakra-ui/react";

import ArrowBackIcon from "assets/icons/ArrowBackIcon";
import DustbinIcon from "assets/icons/DustbinIcon";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import useWidth from "utils/useWidth";

const DeleteEducationModal = ({ text, deleteFn }) => {
  const { size } = useWidth("xs", "lg");
  const {
    educationStore: { modal, setRemoveEducationModal }
  } = useStore();
  const { removeEducation: open } = modal;

  return (
    <>
      <Modal isCentered onClose={setRemoveEducationModal} size={size} isOpen={open}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex height={{ md: "140px" }} justify={"center"} alignItems="center">
              <Text color="#333" fontWeight={600} fontSize="20px" lineHeight="27px">
                Do you want to remove {text}?
              </Text>
            </Flex>
          </ModalBody>
          <Flex
            alignItems="center"
            color="#1455D9"
            justify="center"
            gap="4"
            paddingY="16px"
            bg="#E2EAF5"
            overflow="hidden"
          >
            <Button display="flex" gap="4" onClick={setRemoveEducationModal}>
              <ArrowBackIcon /> Return
            </Button>
            <Button
              onClick={deleteFn}
              _hover={{ bg: "#1455D9" }}
              display="flex"
              gap="4"
              bg="#1455D9"
              color="white"
            >
              <DustbinIcon color="#fff" /> Delete
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(DeleteEducationModal);
