/** @format */

import React from "react";

const NotificationIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C10.1435 1 8.36302 1.7375 7.05027 3.05025C5.73751 4.36301 5.00002 6.14348 5.00002 8C5.00002 11.3527 4.28295 13.4346 3.62212 14.6461C3.29033 15.2544 2.96724 15.6535 2.74281 15.8904C2.63034 16.0091 2.54195 16.0878 2.48874 16.1322C2.46212 16.1544 2.44426 16.168 2.43656 16.1737L2.43371 16.1758C2.07522 16.4221 1.91658 16.8725 2.04292 17.2898C2.17062 17.7115 2.55935 18 3.00002 18H21C21.4407 18 21.8294 17.7115 21.9571 17.2898C22.0835 16.8725 21.9248 16.4221 21.5663 16.1758L21.5635 16.1737C21.5558 16.168 21.5379 16.1544 21.5113 16.1322C21.4581 16.0878 21.3697 16.0091 21.2572 15.8904C21.0328 15.6535 20.7097 15.2544 20.3779 14.6461C19.7171 13.4346 19 11.3527 19 8C19 6.14349 18.2625 4.36301 16.9498 3.05025C15.637 1.7375 13.8565 1 12 1ZM18.6221 15.6039C18.6982 15.7434 18.7746 15.8753 18.8507 16H5.14934C5.22543 15.8753 5.30181 15.7434 5.37791 15.6039C6.21708 14.0654 7.00002 11.6473 7.00002 8C7.00002 6.67392 7.5268 5.40215 8.46448 4.46447C9.40216 3.52678 10.6739 3 12 3C13.3261 3 14.5979 3.52678 15.5356 4.46447C16.4732 5.40215 17 6.67392 17 8C17 11.6473 17.7829 14.0654 18.6221 15.6039Z"
          fill="white"
        />
        <path
          d="M11.135 20.4982C10.8579 20.0205 10.246 19.8579 9.76826 20.135C9.29054 20.4121 9.12792 21.024 9.40504 21.5018C9.66875 21.9564 10.0473 22.3337 10.5027 22.5961C10.9581 22.8584 11.4745 22.9965 12 22.9965C12.5256 22.9965 13.042 22.8584 13.4974 22.5961C13.9528 22.3337 14.3313 21.9564 14.595 21.5018C14.8722 21.024 14.7095 20.4121 14.2318 20.135C13.7541 19.8579 13.1422 20.0205 12.865 20.4982C12.7771 20.6498 12.651 20.7756 12.4991 20.863C12.3473 20.9504 12.1752 20.9965 12 20.9965C11.8248 20.9965 11.6527 20.9504 11.5009 20.863C11.3491 20.7756 11.2229 20.6498 11.135 20.4982Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default NotificationIcon;
