/** @format */

import request from "api";

export const getUserById = async (id) => {
  return await request.get(`/users/${id}`);
};

export const getMyProfile = async () => {
  return await request.get("/user/profile");
};

export const getFriendProfile = async (userId) => {
  return await request.get(`/user/${userId}/info`);
};
export const getProfileSummary = async (userId) => {
  return await request.get(`/search/${userId}/summary`);
};

export const addProfilePic = async (body, headers) => {
  return await request.put("/user/upload/profilePic", body, headers);
};

export const uploadCoverPhoto = async (body, header) => {
  return await request.put("/user/upload/coverPhoto", body, header);
};

export const updateProfile = async (body) => {
  return await request.put("/user/profile", body);
};

export function getUserFiles(userId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/v1/files/user/${userId}`);
}

// PATCH UserDate

export const patchProfile = async (body) => {
  return await request.patch("/user/profile-update", body);
};

// Eductaion
export const getUserEducation = async () => {
  return await request.get("/education");
};

export const addUserEducation = async (body) => {
  return await request.post("/education", body);
};

export const editUserEducation = async (body) => {
  const id = body.id;
  delete body.id;
  return await request.put(`/education/${id}`, body);
};

export const deleteUserEducation = async (id) => {
  return await request.delete(`/education/${id}`);
};

// Experience
export const getUserExpirence = async () => {
  return await request.get("/experiences");
};

export const addUserExperience = async (body) => {
  return await request.post("/experiences", body);
};

export const editUserExperience = async (body) => {
  const id = body.id;
  delete body.id;
  return await request.put(`/experiences/${id}`, body);
};

export const deleteUserExperience = async (id) => {
  return await request.delete(`/experiences/${id}`);
};

// Certificate
export const getUserCertificate = async () => {
  return await request.get("/certification");
};

export const addUserCertificate = async (body) => {
  return await request.post("/certification", body);
};

export const updateUserCertificate = async (body) => {
  const id = body.id;
  delete body.id;
  return await request.put(`/certification/${id}`, body);
};

export const deleteUserCertificate = async (id) => {
  return await request.delete(`/certification/${id}`);
};
