/** @format */

import { dummyProfile } from "constant/profile";
import React from "react";

const ImageCard = ({ src, height, width }) => {
  return (
    <div className={`w-[${width}px] h-[${height}px]`}>
      <img
        src={src || dummyProfile.src}
        className={`object-contain rounded-full w-[${width}px] h-[${height}px]`}
      />
    </div>
  );
};

export default ImageCard;
