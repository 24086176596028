/** @format */

export const messageData = {
  firstName: "Funke ",
  lastName: "Opeke",
  src: "/images/Ellipse6.png",
  CreatedAt: "Oct 20",
  text: "Congratulations on your new job",
  jobTitle: "CEO",
  company: "Google"
};

export const chatMessages = [
  {
    sender: {
      firstname: "Funke",
      lastname: "Opeke",
      image: "image",
      id: "id"
    },
    id: "id",
    createdAt: "",
    text: "hello world"
  },
  {
    sender: {
      firstname: "firstname",
      lastname: "lastname",
      image: "image",
      id: "id2"
    },
    id: "id",
    createdAt: "",
    text: " might be hard to start a life by then"
  }
];

export const chatList = [
  {
    firstName: "Funke ",
    lastName: "Opeke",
    src: "/images/Ellipse6.png",
    CreatedAt: "Oct 20",
    text: "Congratulations on your new job"
  },
  {
    firstName: "Funke ",
    lastName: "Opeke",
    src: "/images/messageframe.png",
    CreatedAt: "Oct 20",
    text: "Congratulations on your new job"
  },
  {
    firstName: "Funke ",
    lastName: "Opeke",
    src: "/images/messageframe.png",
    CreatedAt: "Oct 20",
    text: "Congratulations on your new job"
  }
];
