import { observable, action, makeAutoObservable } from "mobx";

export default class PostStore {
  constructor() {
    makeAutoObservable(this, {
      modal: observable,
      toggleModal: action,
      changeViewType: action,
      setPostToDraft: action,
      closePostModal: action,
      togglePostViewBy: action,
      toggleCommentControl: action,
      togglePoll: action
    });
  }
  modal = {
    isOpen: false,
    isPollOpen: false,
    controlComment: false,
    viewType: false,
    closePost: false,
    postViewedBy: false,
    postTextDraft: ""
  };

  toggleModal = () => {
    this.modal = {
      ...this.modal,
      isOpen: !this.modal.isOpen
    };
  };

  setPostToDraft = (value) => {
    this.modal.postTextDraft = value;
  };

  changeViewType = () => {
    this.modal = {
      ...this.modal,
      viewType: !this.modal.viewType
    };
  };

  closePostModal = () => {
    this.modal = {
      ...this.modal,
      closePost: !this.modal.closePost
    };
  };

  togglePostViewBy = () => {
    this.modal = {
      ...this.modal,
      postViewedBy: !this.modal.postViewedBy
    };

    if (this.modal.postViewedBy === true) {
      this.modal.isOpen = false;
    } else this.modal.isOpen = true;
  };

  toggleCommentControl = () => {
    this.modal = {
      ...this.modal,
      isOpen: false,
      postViewedBy: false,
      controlComment: !this.modal.controlComment
    };
  };

  togglePoll = () => {
    this.modal = {
      ...this.modal,
      isPollOpen: !this.modal.isPollOpen
    };
  };
}
