/** @format */

import React from "react";

const SmillingEmojiIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.40006 13.2C7.84049 12.8697 8.46474 12.9576 8.79692 13.3958L8.80156 13.4018C8.80751 13.4093 8.81883 13.4234 8.83537 13.4431C8.86852 13.4826 8.92225 13.5442 8.99548 13.6206C9.14278 13.7743 9.36413 13.9828 9.65073 14.1913C10.2278 14.6109 11.0256 15 12.0001 15C12.9745 15 13.7723 14.6109 14.3494 14.1913C14.636 13.9828 14.8573 13.7743 15.0046 13.6206C15.0779 13.5442 15.1316 13.4826 15.1647 13.4431C15.1813 13.4234 15.1926 13.4093 15.1986 13.4018L15.2032 13.3958C15.5354 12.9576 16.1596 12.8697 16.6001 13.2C17.0419 13.5314 17.1314 14.1582 16.8001 14.6L16.0001 14C16.8001 14.6 16.8003 14.5997 16.8001 14.6L16.799 14.6013L16.7979 14.6029L16.7952 14.6065L16.7879 14.6159L16.7666 14.6432C16.7494 14.6649 16.7261 14.6938 16.6967 14.7288C16.6381 14.7987 16.5551 14.8933 16.4486 15.0044C16.2365 15.2257 15.9266 15.5172 15.5257 15.8087C14.7278 16.3891 13.5256 17 12.0001 17C10.4745 17 9.27233 16.3891 8.47439 15.8087C8.07349 15.5172 7.76358 15.2257 7.55151 15.0044C7.44506 14.8933 7.36207 14.7987 7.30342 14.7288C7.27407 14.6938 7.25071 14.6649 7.23347 14.6432L7.21217 14.6159L7.20496 14.6065L7.20222 14.6029L7.20107 14.6013C7.20082 14.601 7.20006 14.6 8.00006 14L7.20006 14.6C6.86869 14.1582 6.95823 13.5314 7.40006 13.2Z"
          fill="#808080"
        />
        <path
          d="M8 9C8 8.44772 8.44772 8 9 8H9.01C9.56229 8 10.01 8.44772 10.01 9C10.01 9.55229 9.56229 10 9.01 10H9C8.44772 10 8 9.55229 8 9Z"
          fill="#808080"
        />
        <path
          d="M15 8C14.4477 8 14 8.44772 14 9C14 9.55229 14.4477 10 15 10H15.01C15.5623 10 16.01 9.55229 16.01 9C16.01 8.44772 15.5623 8 15.01 8H15Z"
          fill="#808080"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          fill="#808080"
        />
      </svg>
    </div>
  );
};

export default SmillingEmojiIcon;
