import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Icon from "../../assets/Icon";
import { observer } from "mobx-react-lite";
import ConversationList from "./ConversationList";
import ChatBox from "./ChatBox";
import { useStore } from "store";

const MessageHeader = styled.div`
  height: 46.42px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 12.2161px 12.2161px 0px 0px;
  padding: 4.88643px 15.8809px;
`;

const ChatSystem = () => {
  const { chatStore } = useStore();
  const { sortedActiveConversations, closeConversation, userTyping } = chatStore;
  const [height, setHeight] = useState("h-message-min");

  const messageHeightHandler = (e) => {
    e.preventDefault();
    setHeight((prevState) =>
      prevState === "h-message-min" ? "h-[calc(100vh-6rem)]" : "h-message-min"
    );
  };
  useEffect(() => {
    chatStore.initSocket();
    // Clean up the socket connection when the component unmounts
    return () => {
      chatStore.socket.disconnect();
    };
  }, [chatStore]);

  // const count = activeConversations.size;
  // console.log({ count  });
  // console.log("Active => ", toJS(activeConversations));
  return (
    <div className="fixed bottom-0 w-full flex justify-between z-[9000]">
      <div className="flex flex-[70%] flex-row-reverse items-end space-x-4 pr-5">
        {sortedActiveConversations.map((chat, index) => (
          <ChatBox
            key={chat.id}
            conversation={chat}
            recipient={chat.name}
            onClose={() => closeConversation(chat.id)}
            style={{ zIndex: 100 + index }}
            userTyping={userTyping}
          />
        ))}
      </div>

      <div
        className={`shadow-lg bg-white transition-all duration-300 ease-in mt-6 w-[20%]  ${height}`}
      >
        <div className="list">
          <MessageHeader>
            <div className="cursor-pointer" onClick={messageHeightHandler}>
              <li className="flex items-center justify-between space-x-4">
                <img
                  src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80"
                  alt=""
                  className="h-8 w-8 rounded-full"
                />
                <p className="text-sm font-medium text-gray-900">Messages</p>
                <Icon id="chat-radio" width="15" height="3" />
                <Icon id="message-write" size="19" />
                <button
                  type="button"
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  {height !== "h-message-min" ? (
                    <Icon id="chevron-down" width="14" height="9" />
                  ) : (
                    <Icon id="chevron-up" width="18" height="10" />
                  )}
                </button>
              </li>
            </div>
          </MessageHeader>
          <ConversationList />
        </div>
      </div>
    </div>
  );
};
export default observer(ChatSystem);
