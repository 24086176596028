/** @format */

import { dummyProfile } from "constant/profile";
import { useLogout } from "hooks/logout/useLogout";
import { useGetProfile } from "hooks/user";
import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";

const menuItems = [
  { name: "Help", href: "" },
  { name: "Posts & Activity", href: "" },
  { name: "Bookmarks", href: "" },
  { name: "Language", href: "" },
  { name: "FAQ's", href: "" }
];

const MenuDropdown = forwardRef((props, ref) => {
  const {
    data: {
      data: { profile = {} },
      isLoading
    }
  } = useGetProfile();

  // const { setShowNav, showNav } = props;
  const { authService } = useStore();

  console.log(authService);

  useEffect(() => {}, []);

  const { logOut } = useLogout();
  const navigate = useNavigate();

  return (
    <div
      className="md:w-[258px] bg-secondary-bg p-3 flex flex-col gap-4 rounded-[8px_0px_8px_8px]  shadow-[3px_5px_6px_rgba(0,0,0,0.1)]"
      // onClickCapture={(e) => {
      //   e.stopPropagation();
      // }}
    >
      <div className="flex gap-4 items-center">
        <img
          src={profile?.profilePic || dummyProfile.src}
          className="md:w-[52px] md:h-[52px] rounded-full"
        />
        <div className="flex flex-col justify-between">
          <p className="text-[16px] leading-[27px] font-bold text-[#333]">
            {profile?.lastName} {profile?.firstName}
          </p>
          <p className=" font-semibold text-[14px] leading-[21px] text-[#808080]">
            {profile?.jobTitle}
          </p>
        </div>
      </div>

      <button
        className="text-[14px] leading-[21px] font-semibold w-[100%] py-2 border-[#1455D9] border-1 text-[#1455D9]  rounded-[8px]"
        onClick={() => {
          console.log("Clicking on the navbar");
          navigate("/app");
          // setShowNav((prev) => !prev);
        }}
      >
        View Profile
      </button>

      <div className="border-t-1 border-[#CCCCCC] ">
        <p className="text-[16px] leading-[27px] font-bold text-[#333] mt-3">Account</p>

        <div className="flex flex-col mt-3">
          {menuItems.map((item, i) => (
            <p
              key={i}
              className=" rounded-md text-[16px] leading-[21px] text-[#808080] font-semibold hover:bg-[#ccc] px-3 py-2"
            >
              {item.name}
            </p>
          ))}

          <p
            className="rounded-md text-[16px] leading-[21px] text-[#808080] font-semibold hover:bg-[#ccc] px-3 py-2"
            onClick={() => {
              logOut();
            }}
          >
            Logout
          </p>
        </div>
      </div>
    </div>
  );
});

MenuDropdown.displayName = "MenuDropdown";
export default MenuDropdown;
