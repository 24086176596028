import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import AuthServiceInstance from "services";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);

export function getTimeAgo(timestamp) {
  const now = dayjs();
  const date = dayjs(timestamp);

  if (!date.isValid()) {
    return "Invalid Date";
  }

  // Check if the date is in the future
  if (date.isAfter(now)) {
    return "In the future";
  }

  // Calculate the time difference
  const diffSeconds = now.diff(date, "second");
  const diffMinutes = now.diff(date, "minute");
  const diffHours = now.diff(date, "hour");
  const diffDays = now.diff(date, "day");

  // Handle different time ranges
  if (diffSeconds < 60) {
    return "Just now";
  } else if (diffMinutes < 60) {
    return `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
  } else {
    return date.format("YYYY-MM-DD HH:mm:ss");
  }
}

export function getTimeAgoFromUpdated(createdAt, updatedAt) {
  // Parse the createdAt timestamp using dayjs
  const createdAtTime = dayjs(createdAt);

  // If updatedAt is provided, parse it using dayjs
  // Otherwise, set it to null
  const updatedAtTime = updatedAt ? dayjs(updatedAt) : null;

  // Check if updatedAt is provided and greater than or equal to createdAt
  if (updatedAtTime && updatedAtTime.isSameOrAfter(createdAtTime, "year")) {
    return updatedAtTime.fromNow();
  } else {
    return createdAtTime.fromNow();
  }
}
export function isCurrentUserLiked(likes) {
  const currentUserID = AuthServiceInstance.getProfile()?.userId;
  if (!currentUserID) {
    // Current user is not authenticated
    return false;
  }

  // Check if the current user's ID is present in the likes array
  const userLiked = likes.some((like) => like.user.userId === currentUserID);
  return userLiked;
}
