/** @format */

import React from "react";

const ImageIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 10.5C8.5 9.11932 9.61929 8.00003 11 8.00003C12.3807 8.00003 13.5 9.11932 13.5 10.5C13.5 11.8807 12.3807 13 11 13C9.61929 13 8.5 11.8807 8.5 10.5ZM11 10C10.7239 10 10.5 10.2239 10.5 10.5C10.5 10.7762 10.7239 11 11 11C11.2761 11 11.5 10.7762 11.5 10.5C11.5 10.2239 11.2761 10 11 10Z"
          fill="#0C378B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 7.00003C4.5 5.34318 5.84315 4.00003 7.5 4.00003H21.5C23.1569 4.00003 24.5 5.34318 24.5 7.00003V21C24.5 22.6569 23.1569 24 21.5 24H7.5C5.84315 24 4.5 22.6569 4.5 21V7.00003ZM7.5 6.00003C6.94772 6.00003 6.5 6.44775 6.5 7.00003V21C6.5 21.4289 6.76993 21.7946 7.14914 21.9367L17.793 11.2929C18.1835 10.9024 18.8167 10.9024 19.2072 11.2929L22.5 14.5857V7.00003C22.5 6.44775 22.0523 6.00003 21.5 6.00003H7.5ZM22.5 17.4142L18.5001 13.4142L9.91429 22H21.5C22.0523 22 22.5 21.5523 22.5 21V17.4142Z"
          fill="#0C378B"
        />
        <rect x="1.5" y="1.00003" width="26" height="26" rx="5" stroke="#0C378B" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default ImageIcon;
