import request from "api";

export const createGroup = async (postData) => {
  return await request.post("/group/", postData);
};

// Get Group by Mentor (by-owner)
export const getMyGroups = async () => {
  return await request.get("/group");
};
export const fetchGroupDetails = async (id) => {
  return await request.get(`/group/${id}/details`);
};
export const fetchGroupFeeds = async (id, skip, limit) => {
  return await request.get(`/feed/timeline/${skip}/${limit}?type=group&id=${id}`);
};

export const getGroupMentor = async () => {
  return await request.get("/group/by-owner");
};

export const getGroupMentee = async () => {
  return await request.get("/group/by-member");
};

// Add Mentee to Group
export const addMenteeToGroup = async () => {
  return await request.post("/group/onboard-mentee");
};

export const acceptGroupInvite = async () => {
  return await request.post("/group/accept-invite");
};

//Invite Mentee
export const failedInvite = async () => {
  return await request.post("/group/invite-mentee");
};

export const sendInvite = async () => {
  return await request.post("/group/invite-mentee");
};

export const inviteSuccessful = async () => {
  return await request.post();
};
