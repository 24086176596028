import React, { useRef, useState } from "react";
import UploadIcon from "assets/icons/UploadIcon";
import { observer } from "mobx-react-lite";

const ResourecMediaCard = ({ setInitialValues }) => {
  const [img, setImg] = useState("");
  const [src, setSrc] = useState({ srcFile: "", srcName: "" });
  const inputRef = useRef();

  const openFile = () => {
    inputRef.current.click();
  };

  const loadImage = (e) => {
    const result = e.target.result;
    setImg(result);
  };

  const handleFile = (e) => {
    setInitialValues("file", e.target.files[0]);
  };

  const handleChange = (name, file) => {
    setSrc((prev) => ({ ...prev, srcName: name, srcFile: file }));
    const reader = new FileReader();
    reader.onload = loadImage;
    reader.readAsDataURL(file);
  };

  return (
    <div className=" lg:border-[1px] lg:border-[#CCCCCC] mt-5 lg:mt-0 lg:p-4  lg:w-[100%] h-[295px] lg:bg-[#FFFFFF] rounded-[8px]">
      <h4 className="text-base font-semibold mb-1">Upload Image</h4>
      <div className=" " onClick={openFile}>
        <form encType="multipart/form" className=" w-[100%] h-[100%]">
          <input
            className="hidden"
            type="file"
            ref={inputRef}
            accept="image/*"
            onChange={(e) => {
              handleChange(e.target.value, e.target.files[0]);
              handleFile(e);
            }}
          />

          <div className="ralative  hidden  w-[100%] h-[235px] lg:flex justify-center items-center rounded-[8px] border-[1px] border-[#CCCCCC]">
            {img !== "" && (
              <img src={img} className="w-[100%]  h-[100%] object-scale-down" alt="resource" />
            )}

            <div
              className={`text-[#808080] text-sm font-semibold  text-center ${img == "" ? "block" : "hidden"}`}
            >
              <div className=" flex flex-col items-center gap-1 shadow-[1px_2px_5px_rgba(51, 51, 51, 0.08)]">
                <p className=" w-[60%] p-1 border-[1px] shadow-[1px_2px_5px_rgba(51,51,51,0.08)] shadow-[] rounded-md">
                  Add Media
                </p>
              </div>
              <p className="p-1">Or drop a media to upload</p>
            </div>
          </div>

          <div className="flex justify-start lg:hidden  gap-3 flex-col" onClick={() => openFile()}>
            <div className=" mt-4 w-[174px] flex justify-center items-center p-3 bg-[#0C378B] rounded-lg">
              <div className="flex gap-2 items-center ">
                <p className="text-[14px] text-white">Upload</p>
                <UploadIcon />
              </div>
            </div>
            <p>{img ? <p className="italic text-[14px] text-green"> Image Added</p> : null}</p>
          </div>
        </form>
      </div>
    </div>
  );
};
export default observer(ResourecMediaCard);
