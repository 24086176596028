import GroupAdmins from "pages/Dashboard/group/component/GroupAdmins";
import GroupMember from "pages/Dashboard/group/component/GroupMember";
import React from "react";
import { LearningHeader } from "./resource-learning/LearningHeader";
import { Link, Outlet } from "react-router-dom";

const ResourceOverView = () => {
  return (
    <main className="w-full lg:flex gap-7">
      <section className="flex flex-col  md:w-[68%] mt-5 md:mt-0  px-4 gap-3">
        <LearningHeader />

        <div className="w-full text-[14px] md:leading-6 font-semibold flex items-center py-2 lg:px-6 px-3 md:gap-8 gap-4 bg-secondary-bg md:border-1 md:shadow-[1px_2px_4px_rgba(0,0,0,0.3)] md:rounded-md ">
          <Link to="">Overview</Link>
          <Link to="module"> Course Module</Link>
        </div>

        <div>
          <Outlet />
        </div>
      </section>

      {/* RightSideBar for resource */}
      <section className="hidden h-fit md:inline-block md:w-[30%]">
        <div className="w-full">
          <div className="flex w-fill flex-col gap-5">
            <GroupMember />
            <GroupAdmins />
          </div>
        </div>
      </section>
    </main>
  );
};

export default ResourceOverView;
