/** @format */

import { makeAutoObservable, observable, action } from "mobx";

class GroupStore {
  isLoading = false;
  message = "";
  groupInfo = {
    groupName: "",
    groupIcon: null,
    groupCover: null,
    description: "",
    totalMembers: 0
  };
  isgroupFormModalOpen = false;
  initialValues = {
    groupName: "",
    groupCover: "",
    groupIcon: "",
    description: ""
  };
  constructor() {
    makeAutoObservable(this, {
      isLoading: observable,
      groupInfo: observable,
      initialValues: observable,
      isgroupFormModalOpen: observable,
      setGroupInfo: action,
      setInitialValues: action,
      toggleGroupForm: action
    });
  }
  setGroupInfo = async (data) => {
    if (data) {
      this.groupInfo = data;
    } else {
      this.groupInfo = {
        groupName: "",
        groupIcon: null,
        groupCover: null,
        description: "",
        totalMembers: 0
      };
    }
  };
  setInitialValues = (data) => {
    if (data) {
      this.initialValues = {
        ...this.initialValues,
        groupName: data.groupName || "",
        groupCover: data.groupCover || "",
        groupIcon: data.groupIcon || "",
        description: data.description || ""
      };
    } else {
      this.initialValues = {
        groupName: "",
        groupCover: "",
        groupIcon: "",
        description: ""
      };
    }
  };

  toggleGroupForm = () => {
    this.isgroupFormModalOpen = !this.isgroupFormModalOpen;
  };
}
export default GroupStore;
