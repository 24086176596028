/** @format */

import React from "react";

const ResourceProgressBar = ({ percentage }) => {
  return (
    <div className="w-[100%] h-1 bg-gray-200 rounded-full relative">
      <div className={`w-[${percentage}%] h-[100%] bg-black`}></div>
    </div>
  );
};

export default ResourceProgressBar;
