import { refetchKeys } from "constant";
import { useRespondToRequest } from "hooks/friend";
import React from "react";
import { getTimeAgoFromUpdated } from "utils/helpers";
const MobileComponent = ({ data }) => {
  const { mutate: handleRequestType } = useRespondToRequest(refetchKeys.connectRequest);

  return (
    <div className="w-[100%] flex gap-4 items-center">
      <div className=" w-[95px] h-[84px] ">
        <div className="h-[84px]">
          <img src={data.image} className="object-cover rounded-[100%] w-[95px] h-[84px] " />
        </div>
      </div>

      <div className=" flex flex-col w-[100%]">
        <div className="flex justify-between items-center">
          <p className="font-normal text-[14px] leading-[21px]">{data.name}</p>
          <p className=""> {getTimeAgoFromUpdated(data.createdAt)}</p>
        </div>
        <p className="font-normal text-[14px] leading-[21px]">3 Mutual Connections</p>
        <div className="flex justify-between text-[#fff] ">
          <button
            onClick={() => {
              handleRequestType("accepted", data.id);
            }}
            className="font-bold rounded-[5px]  w-[132px] h-[39px] bg-[#1455D9] flex justify-center items-center"
          >
            Confirm
          </button>
          <div className="flex justify-between text-[#333] flex-col items-center">
            <button
              onClick={() => {
                handleRequestType("decline", data.id);
              }}
              className=" font-bold rounded-[5px] w-[132px] h-[39px] bg-[#F3F2F0] text-center flex justify-center items-center"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileComponent;
