/** @format */

import React from "react";
import About from "./component/about/About";
import Experience from "./Experience";
import ProfessionAndCareer from "./component/career/ProfessionAndCareer";
import Education from "./component/Education/index.js";
import ProfileBoard from "../component/ProfileCard";
import { text, data } from "constant/profile";
import Certificate from "./component/certificate";

const Profile = () => {
  return (
    <section className="w-full lg:flex gap-7">
      <section className=" lg:w-[68%] grid grid-cols-1 gap-8">
        <div>
          <ProfileBoard data={data} />
        </div>

        <div className="lg:hidden">
          <About text={text} header="About" />
        </div>

        <div>
          <Experience />
        </div>

        <div>
          <Education />
        </div>

        <div>
          <ProfessionAndCareer certificate={data.certificate} />
        </div>
      </section>

      {/*  */}

      <section className="lg:w-[32%] mb:px-6 lg:mt-0 mt-8">
        <div>
          <Certificate />
        </div>

        <div className="hidden lg:inline-block w-full bg-secondary-bg ">
          <About text={text} header="About" actionable={true} />
        </div>
      </section>
    </section>
  );
};

export default Profile;
