/** @format */

import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

const ModalButton = ({ handleSubmit, children, bg = "#0C378B", text = "#fff", title, lg }) => {
  return (
    <button onClick={handleSubmit}>
      <Flex
        py="7px"
        px={{ base: "20px", lg: "40px" }}
        borderRadius="5px"
        gap={1}
        w="80px"
        color="#fff"
        bgColor={bg}
        justify="center"
        alignItems="center"
        border="1px solid #0C378B"
        cursor="pointer"
      >
        {children}
        <Box
          fontWeight={700}
          lineHeight={{ base: "16px", md: "21px" }}
          fontSize={{ base: "12px", md: "14px" }}
          color={text}
        >
          {title}
        </Box>
      </Flex>
    </button>
  );
};

export default observer(ModalButton);
