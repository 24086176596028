import dashboard from "assets/svgs/dashboard.svg";
import mentee from "assets/svgs/mentee.svg";
import messages from "assets/svgs/messages.svg";
import settings from "assets/svgs/settings.svg";
import support from "assets/svgs/support.svg";
// import Resources from "pages/Dashboard/group/GroupResources/Resources";
// import ResourceModule from "pages/Resources/resource_edit_and_module";

export const userProfile = "_PX_USR_PRF_";
export const ACCESS_TOKEN = "_PX_USR-TK_";
export const REFRESH_TOKEN_KEY = "_PX_USR-RTK_";
export const cartKey = "_USR_CRT_";
export const cartPricing = "_USR_CRT_PC_";
export const referral = "_USR_REFERRAL_";
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY ?? "usr-encry-tks";

export const Routes = {
  Login: "/auth/sign-in",
  Signup: "/auth/signup",
  ForgotPassword: "/auth/forgot-password",
  EmailSent: "email-sent",
  ResetPassword: "/auth/reset-password/:token",
  UserDashboard: "/app/timeline",
  Mentor: "/mentor",
  MentorOnboarding: "/onboarding/mentor",
  MenteeOnboarding: "/onboarding/mentee",
  Mentee: "/mentee",
  Resources: "/app/resources",
  CreateResource: "/app/create-resource",
  Resource: (slug) => `/app/resources/${slug}`,
  ResourceModule: (slug) => `/app/resources/${slug}/module`,

  PlaceOrder: "/",
  ClientOrdersAddress: "/place-order/address",
  Contact: "/contact",
  AdminLogin: "/auth/admin/login",
  AdminDashboard: "/admin/dashboard",
  AdminOrders: "/admin/orders",
  AdminCustomer: "/admin/customers",
  AdminInventory: "/admin/inventory",
  AdminRunners: "/admin/runners",
  AdminPartners: "/admin/partners",
  Verify: "/verify",
  GuestCheckout: "/guest/checkout",

  Groups: "/app/groups",

  GroupDetails: (id) => `/app/groups/${id}`
};

export const SIDE_BAR_MENU = [
  { label: "Dashboard", icon: dashboard, route: "/" },
  { label: "Messages", icon: messages, route: "/messages" },
  { label: "Mentees", icon: mentee, route: "/mentees" },
  { label: "Support", icon: support, route: "/support" },
  { label: "Settings", icon: settings, route: "/settings" }
];

export const ROLES = {
  mentor: "Mentor",
  mentee: "Mentee"
};
export const industryOption = [
  {
    key: "oilAndGas",
    label: "Oil & Gas",
    areaOfExpertise: [
      { key: "drilling", label: "Drilling" },
      { key: "reservoir", label: "Reservoir Engineering" },
      { key: "hse", label: "Health, Safety & Environment" }
    ]
  },
  {
    key: "informationTechnology",
    label: "Information Technology",
    areaOfExpertise: [
      { key: "softwareDevelopment", label: "Software Development" },
      { key: "cyberSecurity", label: "Cyber Security" },
      { key: "cloudServices", label: "Cloud Service" }
    ]
  },
  {
    key: "investmentBanking",
    label: "Investment Banking",
    areaOfExpertise: [
      { key: "financeModeling", label: "Financial Modelling" },
      { key: "stockExchange", label: "Stock Exchange" },
      { key: "taxAudits", label: "Tax Audits" }
    ]
  }
];

export const genderData = [
  { key: "male", label: "Male" },
  { key: "female", label: "Female" },
  { key: "noAnswer", label: "Choose Not to Answer" }
];

export const refetchKeys = {
  group: ["fetchGroupFeeds"],
  feed: ["getPost"],
  sentRequest: ["sentRequests"],
  connectRequest: ["connectRequests", "getFriends"]
};

export const resourceNav = [{ name: "All" }, { name: "Publish" }, { name: "Drafts" }];
