/** @format */

export const admins = [
  {
    name: "Busayo Akinniyi",
    jobTitle: "Product Manager",
    src: "",
    company: "Google",
    groupOner: true,
    groupManager: true
  },
  {
    name: "Benson kallson",
    jobTitle: "CEO",
    company: "Airdrop",
    src: "",
    groupOner: false,
    groupManager: true
  },
  {
    name: "Mary Aragbabuwo",
    jobTitle: "COO",
    company: "MainOne",
    src: "",
    groupOner: false,
    groupManager: true
  }
];
