/** @format */

import React, { useState } from "react";
import EditIcon from "assets/icons/EditIcon";
import MessageMoreIcon from "assets/icons/MessageMoreIcon";
import SearchIcon from "assets/icons/SearchIcon";
import ChatProfileCard from "./ChatProfileCard";
import { chatList } from "constant/message";
const ChatList = ({ setCurrentUser }) => {
  const [input, setInput] = useState("");

  return (
    <section className="flex flex-col w-full">
      <div className="px-5 py-4 flex justify-between w-full items-center border-b-[2px] border-[#CCCCCC]">
        <h3 className="text-[14px] font-bold leading-5 text-[#333333]">Messaging</h3>
        <div className="flex gap-5 items-center">
          <div>
            <MessageMoreIcon />
          </div>
          <div>
            <EditIcon size="22.12" color="#808080" />
          </div>
        </div>
      </div>

      <div className="px-5 py-4 border-b-[2px] border-[#CCCCCC] w-full relative">
        <form>
          <input
            placeholder="Search chats"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="placeholder:text-[#0E3C99] md:border-1 border-2  placeholder:text-[14px] placeholder:font-semibold placeholder:leading-[21px] border-[#1455D9] rounded-[4px] lg:rounded-[8px] pl-[2.5rem] pr-[16px] py-[8px] focus:border-[#1455D9] outline-none w-[100%]"
          />
        </form>
        <div className="absolute left-7 top-[26px]">
          <SearchIcon size="24" color="#0C378B" />
        </div>
      </div>

      <div className="lg:h-[544p] px-5 py-4 scrolling-pg h-[522px] w-full flex flex-col gap-3 ">
        {chatList.map((user, i) => (
          <div
            key={i}
            className="w-full cursor-pointer "
            onClick={() => {
              setCurrentUser((prev) => !prev);
            }}
          >
            <ChatProfileCard data={user} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChatList;
