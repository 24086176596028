/** @format */

import React from "react";

const NavDropdownIcon = () => {
  return (
    <div>
      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.99993 7.33141L13.7313 0.600037L0.268555 0.600037L6.99993 7.33141Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default NavDropdownIcon;
