/** @format */

import EditIcon from "assets/icons/EditIcon";
import ReportIcon from "assets/icons/ReportIcon";
import React from "react";

const RepostModal = () => {
  return (
    <div className="z-10 w-[276px] hidden md:block rounded-md md:h-[142px] bg-[#fff] md:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] ">
      <div className="p-4 flex flex-col justify-evenly  h-full">
        <div className="flex items-center gap-5 hover:bg-[#ccc] hover:rounded-md ">
          <div className="py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[18px] font-bold">
            <EditIcon />
            <div className="flex flex-col gap-2">
              <p className="text-[14px] text-[#333] leading-[21px] font-semibold">Quote Repost</p>
              <p className="text-[12px] leading-[19px] font-normal text-[#333]">
                Add your thoughts and repost
              </p>
            </div>
          </div>
        </div>

        {/*  */}

        <div className="flex items-center gap-5 hover:bg-[#ccc] hover:rounded-md ">
          <div className="py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <ReportIcon />
            <div>
              <p className="text-[14px] text-[#333] leading-[21px] font-semibold">Repost</p>
              <p className="text-[12px] leading-[19px] font-normal text-[#333]">
                Repost to your timeline
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepostModal;
