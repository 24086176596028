import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  xxl: "1600px"
});

const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        fontFamily: "'Nunito Sans', sans-serif;"
      }
    },
    fontFamily: "body"
  }
});

export default theme;
