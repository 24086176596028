import { config } from "config";
import AuthServiceInstance from "services";
import io from "socket.io-client";

class WebSocketService {
  constructor() {
    this.socket = null;
  }
  connect() {
    try {
      AuthServiceInstance.isAuthenticated();
      const tokens = AuthServiceInstance.getTokens();

      if (!tokens) return;
      const { accessToken } = tokens;
      this.socket = io(config.baseURL, {
        auth: {
          token: accessToken
        }
      });

      // this.setupListeners();
      return this.socket;
    } catch (error) {
      console.error("WebSocketService initialization failed:", error);
    }
  }
  setupListeners() {
    this.socket.on("connect", () => {
      console.log("Connected to server");
    });
  }
  subscribeToEvent(eventName, callback) {
    if (!this.socket) return;
    return this.socket.on(eventName, callback);
  }
  emit(event, payload) {
    // You can use a state management library or an event emitter
    // to notify other parts of your application about WebSocket events.
    if (!this.socket) return;
    // console.log(`WebSocket Event: ${event}`, payload);
    return this.socket.emit(event, payload);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
