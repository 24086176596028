/** @format */

import React from "react";

const CommentIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V21C2 21.4045 2.24364 21.7691 2.61732 21.9239C2.99099 22.0787 3.42111 21.9931 3.70711 21.7071L7.41421 18H19C19.7957 18 20.5587 17.6839 21.1213 17.1213C21.6839 16.5587 22 15.7957 22 15V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7957 2 19 2H5ZM4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H7C6.73478 16 6.48043 16.1054 6.29289 16.2929L4 18.5858V5C4 4.73478 4.10536 4.48043 4.29289 4.29289Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};

export default CommentIcon;
