import React from "react";
import { XIcon, MinusIcon } from "@heroicons/react/outline";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

const ChatBoxHeader = ({ id, recipient, isTyping, handleToggleMinimize }) => {
  const { chatStore } = useStore();
  const { closeConversation } = chatStore;

  const chatHeaderClassName =
    "flex w-full justify-between bg-gray-200 px-2 py-1 h-[48px] items-center z-[1000]";

  const typingClassName = "text-xs italic text-gray-500";

  return (
    <>
      <div className={chatHeaderClassName}>
        <h2 className="text-sm  font-medium">{recipient}</h2>
        {isTyping && <div className={typingClassName}>Typing...</div>}
        <div className="flex space-x-3">
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={handleToggleMinimize}
          >
            <MinusIcon className="h-5 w-5" />
          </button>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={() => closeConversation(id)}
          >
            <XIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(ChatBoxHeader);
