/** @format */

import request from "api";
import { config } from "../../config";
import { makeAutoObservable, observable, runInAction, action } from "mobx";
import { getMyProfile, updateProfile, addProfilePic, uploadCoverPhoto } from "api/user";
import AuthServiceInstance from "services";
import { resetPassword } from "api/auth";

class UserStore {
  isLoading = false;
  message = "";
  profile = {};

  selectCoverPhoto = {
    seletedFile: ""
  };

  handleCoverPhoto = (e) => {
    this.seletCoverPhoto = {
      ...this.seletCoverPhoto,
      seletedFile: e.target.files[0]
    };
  };

  constructor() {
    makeAutoObservable(this, {
      selectCoverPhoto: observable,
      isLoading: observable,
      profile: observable,
      message: observable,
      certificateModal: observable,
      getProfile: action,
      postProfileImage: action,
      updateProfile: action,
      handleCoverPhoto: action,
      toggleCoverPhoto: action,
      setCoverPhoto: action
    });
  }
  toggleCoverPhoto = () => {
    this.selectCoverPhoto = {
      ...this.seletCoverPhoto,
      seletedFile: ""
    };
  };
  getProfile = async () => {
    try {
      this.isLoading = true;
      const response = await getMyProfile();
      if (response.status) {
        const { profile } = response.data;
        this.profile = profile;
        this.isLoading = false;

        return {
          status: true,
          message: response.message,
          loading: this.loading,
          profile
        };
      }

      return {
        status: false,
        message: response.message
      };
    } catch (error) {
      this.isLoading = false;

      return {
        status: false,
        message: error.response.message
      };
    } finally {
      this.isLoading = false;
    }
  };

  postProfileImage = async (data, config) => {
    try {
      const response = await addProfilePic(data, config);
      if (response.status) {
        this.getProfile();
        this.message = response.message;

        return {
          status: true,
          message: response.message
        };
      }
    } catch (error) {
      this.message = error.response.message;
      throw error;
    } finally {
      this.message = "";
    }
  };

  uploadCoverPhoto = async (data, config) => {
    console.log(data);
    try {
      const response = await uploadCoverPhoto(data, config);
      if (response.status) {
        this.getProfile();
        return {
          status: true,
          message: response.message
        };
      }

      return {
        status: response.status,
        message: response.message
      };
    } catch (error) {
      return {
        status: false,
        message: error.response.message
      };
    }
  };

  setCoverPhoto = (value) => {
    console.log(value);
    this.selectCoverPhoto = {
      ...this.selectCoverPhoto,
      seletedFile: value
    };
  };
  updateProfile = async (body) => {
    try {
      const response = await updateProfile(body);

      if (response.status) {
        this.getProfile();
        this.message = response.message;
        return {
          status: true,
          message: response.message
        };
      }
      this.message = "";
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return {
        status: false,
        message: error.response.message
      };
    }
  };
}
export default UserStore;
