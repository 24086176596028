/** @format */

import React, { useState } from "react";
import useWidth from "utils/useWidth";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Box,
  Text
} from "@chakra-ui/react";
import SearchDisplay from "components/List-component/SearchDisplay";
import SearchInput from "components/Search/SearchInput";
import CancelIcon from "assets/icons/CancelIcon";
import { friedsData } from "constant/friends";

const SendModal = ({
  isOpen,
  onOpen,
  onClose,
  handleModal,
  input,
  setInput,
  onFocus,
  setOnFocus,
  postId
}) => {
  const { size } = useWidth("xs", "xl");
  const suggestedFriends = Array.from(friedsData.slice(0, 12));
  return (
    <Modal
      isOpen={() => {
        isOpen();
      }}
      onClose={() => {
        onClose();
      }}
      isCentered
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <Flex
          justify="space-between"
          w="full"
          alignItems="center"
          borderBottom="2px solid #F2F2F2 "
        >
          <Box p={{ base: "1rem" }}>
            <Text
              lineHeight={{ base: "19px", md: "34px" }}
              fontSize={{ base: "20px", md: "25px" }}
              fontWeight={400}
              ml="3px"
              color="#333333"
            >
              Invite connections
            </Text>
          </Box>
          <Box
            border="2px solid #F5F5F5"
            p="16px"
            justifySelf="end"
            borderRadius="4px"
            mr="16px"
            position="relative"
          >
            <Box
              onClick={() => handleModal()}
              position="absolute"
              top={"2"}
              right={"2.5"}
              cursor="pointer"
            >
              <CancelIcon />
            </Box>
          </Box>
        </Flex>

        <ModalBody width="full">
          <Box width="full">
            <SearchInput
              setInput={setInput}
              input={input}
              width={"100%"}
              onFocus={onFocus}
              setOnFocus={setOnFocus}
              placeholder={"Search by name"}
            />
          </Box>

          <Box className=" selected-scrolling" overflow="auto" height="256px">
            {input ? null : <Text>Suggested</Text>}

            <Flex flexDirection="column" gap={3} alignItems="flex-start">
              {suggestedFriends.map((element) => (
                <div key={element.id}> {element.name}</div>
              ))}
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            width="100%"
            borderRadius="24px"
            bg="#1455D9"
            onClick={() => {
              // handleModal();
              // onClose();
            }}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendModal;
