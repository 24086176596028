/** @format */

import React from "react";
import { Spinner } from "@chakra-ui/react";

import EditIcon from "assets/icons/EditIcon";
import { useExperience } from "hooks/user/experience";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import ExperienceItem from "./ExperienceItem";
import ExperienceModal from "../modal/ExperienceModal";

const Experience = () => {
  const {
    experienceStore: {
      modal: { isOpen },
      toggleModal
    }
  } = useStore();

  const { data: { data: experienceData = [] } = {}, isLoading } = useExperience();

  return (
    <>
      <div className="w-[100%] bg-secondary-bg relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.1)] rounded-md md:p-6 px-3 p-2 ">
        <div className="flex justify-between items-center px-2 pr-2 lg:pl-0 lg:px-0  mb-2">
          <h3 className="font-bold leading[27px] py-2 text-[20px]">Experience</h3>
          <button className="lg:mr-2  " onClick={() => toggleModal()}>
            <EditIcon size="18" />
          </button>
        </div>
        <div className="flex py-2 flex-col gap-2 md:gap-6 border-[1px] rounded-[4px] border-[#CCCCCC] pl-3 md:border-none">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : Array.isArray(experienceData) && experienceData.length > 0 ? (
            experienceData.map((exp) => (
              <ExperienceItem key={exp.id} experience={exp} actionable={true} />
            ))
          ) : (
            <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
              No experience data available.
            </p>
          )}
        </div>
      </div>

      {isOpen && <ExperienceModal />}
    </>
  );
};

export default observer(Experience);
