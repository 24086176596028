/** @format */

import React from "react";

const ShowLessIcon = () => {
  return (
    <div>
      <svg width="10" height="9" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.6481 1.1018C1.29012 0.743818 0.709718 0.743818 0.351737 1.1018C-0.00624321 1.45978 -0.00624321 2.04018 0.351737 2.39816L5.85174 7.89816C6.20972 8.25614 6.79012 8.25614 7.1481 7.89816L12.6481 2.39816C13.0061 2.04018 13.0061 1.45978 12.6481 1.1018C12.2901 0.743818 11.7097 0.743818 11.3517 1.1018L6.49992 5.95362L1.6481 1.1018Z"
          fill="#1455D9"
        />
      </svg>
    </div>
  );
};

export default ShowLessIcon;
