/** @format */

import React from "react";

const ReportIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3011_720)">
          <path
            d="M17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893C15.9024 0.683417 15.9024 1.31658 16.2929 1.70711L18.5858 4H7C5.67392 4 4.40215 4.52678 3.46447 5.46447C2.52678 6.40215 2 7.67392 2 9V11C2 11.5523 2.44772 12 3 12C3.55228 12 4 11.5523 4 11V9C4 8.20435 4.31607 7.44129 4.87868 6.87868C5.44129 6.31607 6.20435 6 7 6H18.5858L16.2929 8.29289C15.9024 8.68342 15.9024 9.31658 16.2929 9.70711C16.6834 10.0976 17.3166 10.0976 17.7071 9.70711L21.7071 5.70711C22.0976 5.31658 22.0976 4.68342 21.7071 4.29289L17.7071 0.292893Z"
            fill="#333333"
          />
          <path
            d="M7.70711 14.2929C8.09763 14.6834 8.09763 15.3166 7.70711 15.7071L5.41421 18H17C17.7956 18 18.5587 17.6839 19.1213 17.1213C19.6839 16.5587 20 15.7956 20 15V13C20 12.4477 20.4477 12 21 12C21.5523 12 22 12.4477 22 13V15C22 16.3261 21.4732 17.5979 20.5355 18.5355C19.5979 19.4732 18.3261 20 17 20H5.41421L7.70711 22.2929C8.09763 22.6834 8.09763 23.3166 7.70711 23.7071C7.31658 24.0976 6.68342 24.0976 6.29289 23.7071L2.29289 19.7071C1.90237 19.3166 1.90237 18.6834 2.29289 18.2929L6.29289 14.2929C6.68342 13.9024 7.31658 13.9024 7.70711 14.2929Z"
            fill="#333333"
          />
        </g>
        <defs>
          <clipPath id="clip0_3011_720">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ReportIcon;
