import request, { makeRequest } from "api";

// Mentee
export const enrollForReource = async (body) => {
  const resourceId = body.id;
  return await request.post(`/enrollments/${resourceId}/enroll`);
};

export const getAllResource = ({ keyword, page, limit }) => {
  // Build the query string with optional parameters
  let url = `${HOST}/resources`;
  const params = new URLSearchParams();

  if (keyword) params.append("keyword", keyword);
  if (page) params.append("page", page);
  if (limit) params.append("limit", limit);
  if (status) params.append("status");

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  return request.get(url);
};

export const getAuthorResources = async (query) => {
  // Convert query object to query string
  const queryString = new URLSearchParams(query).toString();
  return await request.get(`/resources/author/resources?${queryString}`);
};

export const getAllResources = async () => {
  return await request.get("/resources/resources");
};

export const getResource = async (slug) => {
  return await request.get(`/resources/${slug}`);
};

export const postResources = async (body) => {
  return await makeRequest("post", "/resources", body, {
    "Content-Type": "multipart/form-data"
  });
};

// module
export const postResourceModule = async (body) => {
  console.log(body);
  return await request.post("/resources/course-modules", body);
};

export const updateResourceModule = async (body) => {
  const id = body.resourceId;
  return await request.put(`/resources/course-modules/${id}`, body);
};
