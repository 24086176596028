import { makeAutoObservable, observable, action } from "mobx";
import { loginApi, onboardingCompletion, registerApi } from "api/auth";

class AuthStore {
  isAuthenticated = false;
  message = "";
  userType = "";
  onboardingData = {};
  constructor(rootStore) {
    makeAutoObservable(this, {
      isAuthenticated: observable,
      message: observable,
      userType: observable,
      onboardingData: observable,
      login: action,
      setOnboardingData: action,
      completeOnboarding: action,
      register: action
    });
    this.rootStore = rootStore;
  }
  login = async (data) => {
    try {
      const response = await loginApi(data);
      if (response.status) {
        const { token, refreshToken, profile } = response.data;
        await this.rootStore.authService.setTokens(token, refreshToken);
        await this.rootStore.authService.setProfile(profile);
        this.isAuthenticated = true;
        return {
          status: true,
          message: response.message,
          profile
        };
      }
      return {
        status: false,
        message: response.message
      };
    } catch (error) {
      console.log(error);
      return {
        status: false,
        message: error.response.message
      };
    }
  };
  register = async (data) => {
    try {
      const response = await registerApi(data);
      if (response.status) {
        const {
          auth: { token, refreshToken },
          profile
        } = response.data;
        this.rootStore.authService.setTokens(token, refreshToken);
        this.rootStore.authService.setProfile(profile);
        this.isAuthenticated = true;
        return {
          status: true,
          message: response.message
        };
      }
      return {
        status: false,
        message: response.message
      };
    } catch (error) {
      console.log(error);
      return {
        status: false,
        message: error.response.message
      };
    }
  };
  setOnboardingData = (data) => {
    if (data) {
      this.onboardingData = {
        ...this.onboardingData,
        ...data
      };
    }
  };

  completeOnboarding = async () => {
    const response = await onboardingCompletion(this.onboardingData);
    if (response.status) {
      return {
        status: true,
        message: response.message
      };
    } else {
      return {
        status: false,
        message: response.message
      };
    }
  };
}
export default AuthStore;
