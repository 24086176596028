import React from "react";
import FriendsProfileCard from "../usable/FriendsProfileCard";
import Spinner from "components/Spinner";
import { useGetSentRequest, useRespondToRequest } from "hooks/friend";
import { refetchKeys } from "constant";

const SentRequest = () => {
  const { data: { data: sentData = [] } = {}, isLoading } = useGetSentRequest();
  const { mutate: CancelSentRequest } = useRespondToRequest(refetchKeys.sentRequest);

  if (isLoading) {
    return (
      <div className="h-[100vh] w-[100vw] flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] flex flex-col gap-4 py-3">
      {sentData.length > 0 ? (
        sentData.map((data) => (
          <div key={data.id} className="flex h-[72px] justify-between items-center">
            <FriendsProfileCard data={data} />
            <div>
              <button
                onClick={() => {
                  CancelSentRequest("Cancel", data.id);
                }}
                className="rounded-[5px] text-[12px] md:text-[14px] font-bold leading-[21px] text-[#0C378B] md:h-[37px] h-[42px] border-[1px] border-[#0C378B] md:w-[148px] w-[120px] flex justify-center items-center"
              >
                Cancel Request
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No pending friend requests sent.</p>
      )}
    </div>
  );
};

export default SentRequest;
