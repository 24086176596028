/** @format */

import Spinner from "components/Spinner";
import { useGetProfile } from "hooks/user";
import React from "react";

const Text = ({ text = [] }) => {
  const { data: { data: profile = {} } = {}, isLoading } = useGetProfile();
  return (
    <main className="text-[14px] text-[#000] font-medium leading-6 whitespace-normal text-start">
      {profile?.certificate ? (
        <ul className="flex gap-2 justify-start flex-col">
          <li>
            <div className=" flex gap-2 items-center justify-start">
              <p className="h-1 w-1 justify-self-start rounded-full bg-black font-semibold text-[12px] leading-[16px] items-start"></p>
              <p className="font-semibold text-[12px] leading-[16px]">{profile?.certificate}</p>
            </div>
          </li>
        </ul>
      ) : (
        <p className="italic text-[#808080] text-[14px]">No career achievement added</p>
      )}
    </main>
  );
};

export default Text;
