/** @format */

import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import RightSideBar from "pages/Dashboard/component/RightSideBar";
import SearchInput from "components/Search/SearchInput";
import { useSearch } from "hooks/search";
import { useNavigate } from "react-router-dom";

const HomeDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("left");
  const [searchQuery, setSearchQuery] = useState("");
  const [onFocus, setOnFocus] = useState(false);
  const navigate = useNavigate();

  const { refetch } = useSearch(searchQuery);
  const handleKeyDown = (e) => {
    if (searchQuery.trim() === "") return;

    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
      navigate(`/app/search?keyword=${searchQuery}`, { replace: true });
      onClose();
      setSearchQuery("");
    }
  };

  useEffect(() => {
    if (searchQuery.length > 3) refetch();
  }, [refetch, searchQuery]);
  return (
    <div className="m-0 p-0 w-[100%]">
      <Button colorScheme="white" onClick={onOpen}>
        <HamburgerIcon color="white" w="30px" height="30px" />
      </Button>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <SearchInput
              width="100%"
              setInput={setSearchQuery}
              input={searchQuery}
              onFocus={onFocus}
              setOnFocus={setOnFocus}
              placeholder={"Search"}
              navigateTo={handleKeyDown}
            />
          </DrawerHeader>
          <DrawerBody>
            <RightSideBar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default HomeDrawer;
