/** @format */

import React, { createContext, useContext } from "react";
import AuthServiceInstance from "services";
import AuthStore from "./AuthStore";
import ChatStore from "./ChatStore";
import UserStore from "./user/UserStore";
import { ChatService } from "utils/chat";
import ExperienceStore from "./user/ExperienceStore";
import EducationStore from "./user/EducationStore";
import GroupStore from "./GroupStore";
import AboutStore from "./user/AboutStore";
import CertificateStore from "./user/CertificateStore";
import CareerStore from "./user/CareerStore";
import PostStore from "./user/PostStore";
import ResourceStore from "./ResourceStore";
export class RootStore {
  constructor() {
    this.authService = AuthServiceInstance;
    this.authStore = new AuthStore(this);
    this.chatService = new ChatService();
    this.userStore = new UserStore(this);
    this.experienceStore = new ExperienceStore(this);
    this.educationStore = new EducationStore(this);
    this.groupStore = new GroupStore(this);
    this.aboutStore = new AboutStore(this);
    this.chatStore = new ChatStore(this);
    this.certificateStore = new CertificateStore(this);
    this.careerStore = new CareerStore(this);
    this.postStore = new PostStore(this);
    this.resourceStore = new ResourceStore(this);
    // Make all stores observable
    //  makeAutoObservable(this);
  }
}

const stores = new RootStore();
const StoreContext = createContext(stores);

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);

export default StoreProvider;
