/** @format */

import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getFriendProfile,
  getMyProfile,
  getProfileSummary,
  patchProfile,
  updateProfile,
  uploadCoverPhoto
} from "api/user";
import { toast } from "react-toastify";

export const useGetProfile = () => {
  const { data, isLoading } = useQuery(["getProfile"], getMyProfile, { staleTime: 10000 });
  return {
    data,
    isLoading
  };
};

export const useGetFriendProfile = (userId) => {
  const query = useQuery(["getFriendProfile", userId], () => getFriendProfile(userId), {
    staleTime: 10000
  });
  return query;
};

export const useGetProfileSummary = (userId) => {
  const query = useQuery({
    queryKey: ["getProfileSummary", userId],
    queryFn: () => getProfileSummary(userId),
    staleTime: 6000
  });
  return query;
};

export const useAddCoverPhoto = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: uploadCoverPhoto,
    onSuccess: (data) => {
      toast(data.message);
      queryClient.invalidateQueries(["getProfile"]);
    },
    onError: (data) => {
      toast(data?.message);
    }
  });
  const addCoverPhoto = (formData, header) => {
    mutate.mutate(formData, header);
  };

  return {
    mutate: addCoverPhoto
  };
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: updateProfile,
    onSuccess: (data) => {
      toast(data.message);
      queryClient.invalidateQueries(["getProfile"]);
    }
  });

  const updateUserProfile = (data) => {
    mutate.mutate(data);
  };

  return {
    mutate: updateUserProfile
  };
};

export const useUpdateUserBio = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: patchProfile,
    onSuccess: (data) => {
      toast(data.message);
      queryClient.invalidateQueries(["getProfile"]);
    }
  });

  const userProfilePatchfn = (body) => {
    mutate.mutate(body);
  };

  return {
    mutate: userProfilePatchfn
  };
};
