/** @format */

import React from "react";

const SendIcon = ({ color = "#333333", width = "24" }) => {
  return (
    <div>
      <svg
        width={width}
        height={width}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7071 1.29289C22.9306 1.51637 23.0262 1.81932 22.9939 2.11078C22.9848 2.19248 22.9657 2.27329 22.9366 2.35118L15.9439 22.3303C15.8084 22.7173 15.4504 22.9825 15.0408 22.9992C14.6311 23.0159 14.2527 22.7808 14.0862 22.4061L10.2424 13.7576L1.59387 9.91381C1.21919 9.74728 0.984122 9.36891 1.00084 8.95923C1.01755 8.54955 1.28265 8.19159 1.66965 8.05614L21.6488 1.06345C21.7272 1.03411 21.8085 1.01494 21.8907 1.00595C21.9511 0.999308 22.0117 0.998232 22.0717 1.00256C22.3032 1.0191 22.5301 1.11588 22.7071 1.29289ZM18.1943 4.39147L4.71108 9.1106L10.7785 11.8073L18.1943 4.39147ZM12.1928 13.2215L19.6085 5.80568L14.8894 19.2889L12.1928 13.2215Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default SendIcon;
