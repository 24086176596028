/** @format */

import React from "react";

const FlagIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.98438 2.90909C2.98438 2.48969 3.24608 2.11485 3.63979 1.97033C5.28306 1.36714 6.63315 1.09097 8.01494 1.11746C9.38109 1.14365 10.7107 1.46541 12.2944 1.95344C13.8509 2.43311 14.96 2.7142 16.0365 2.74872C17.0856 2.78236 18.168 2.5821 19.638 1.98303C19.9463 1.85737 20.297 1.89343 20.5733 2.07921C20.8496 2.26499 21.0154 2.57612 21.0154 2.90909V13.9091C21.0154 14.3156 20.7693 14.6817 20.3928 14.8351C18.7326 15.5117 17.3681 15.7925 15.9724 15.7477C14.6042 15.7038 13.2706 15.3471 11.7054 14.8647C10.1673 14.3908 9.05491 14.1378 7.97661 14.1171C7.07617 14.0998 6.15121 14.2443 4.98438 14.6211L4.98438 22C4.98438 22.5523 4.53666 23 3.98438 23C3.43209 23 2.98438 22.5523 2.98438 22V2.90909ZM8.01494 12.1175C7.02804 12.0986 6.0573 12.234 4.98438 12.5328V3.62114C6.15121 3.24428 7.07617 3.09983 7.97661 3.11709C9.05491 3.13776 10.1673 3.39074 11.7054 3.86474C13.2706 4.34709 14.6042 4.70382 15.9724 4.74769C16.9635 4.77948 17.9388 4.64714 19.0154 4.32551V13.2213C17.8514 13.6358 16.9318 13.7774 16.0365 13.7487C14.96 13.7142 13.8509 13.4331 12.2944 12.9534C10.7107 12.4654 9.38109 12.1437 8.01494 12.1175Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};

export default FlagIcon;
