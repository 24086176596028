/** @format */

import React from "react";
import dummyImg from "assets/images/blank-profile-picture-gb818349d8_1280.png";
import dummyCover from "assets/images/cover-photo.jpg";
import LocationIcon from "assets/icons/LocationIcon";
import MyNetworkIcon from "assets/icons/MyNetworkIcon";
import MessageIcon from "assets/icons/MessageIcon";
import StarRating from "components/Rating";
import { useAddFriendRequest } from "../../../../hooks/friend";

const UserProfileCard = ({ data }) => {
  const {
    lastName,
    firstName,
    jobTitle,
    industry,
    state,
    country,
    mentees,
    userId,
    profilePic,
    coverPhoto
  } = data;

  const { mutate: addFriend } = useAddFriendRequest();
  const handleAddFriend = (id) => {
    if (!id) return;
    addFriend(id);
  };

  return (
    <section className="w-full px-2 relative border-1 shadow-[3px_5px_6px_rgba(0,0,0,0.2)] rounded-lg bg-secondary-bg">
      <div className="lg:h-[180px] w-full h-[107px] relative">
        <img
          src={coverPhoto || dummyCover}
          className="object-cover h-[98px] lg:h-[168px] w-[100%]"
          alt="coverPhoto"
        />
      </div>

      <div className="absolute left-1/3 top-12 lg:top-13 lg:top-[5.4rem] lg:left-10 lg:w-[185px] lg:h[185px] h-[103px] w-[103px] lg:-h[185px] rounded-[50%]">
        <div>
          <img
            className="object-cover rounded-full lg:h-[161px] lg:w-[161px]"
            src={profilePic || dummyImg}
            alt="pic"
          />
        </div>
      </div>

      <div className="mt-[4rem] lg:mt-[5rem] px-4 flex flex-col gap-2 lg:gap-3 relative text-[#333333]">
        <div className="lg:flex justify-between items-center">
          <h3 className="font-bold leading-[27px] text-[18px] lg:text-[25px] lg:leading-[34px]">
            {lastName} {firstName}
          </h3>
        </div>

        <p className="font-semibold leading-[19px] text-[13px] lg:text-[16px] lg:leading-[21px]">
          {jobTitle} {industry && `at ${industry}`}
        </p>

        <p className="text-[#808080] font-semibold items-center leading-[19px] text-[13px] lg:text-[16px] lg:leading-[21px] flex gap-2">
          <LocationIcon /> {state}, {country}
        </p>

        {mentees && (
          <p className="font-semibold leading-[19px] text-[14px] lg:text-[16px] lg:leading-[21px]">
            {mentees} Mentees
          </p>
        )}

        {mentees && <p className="font-semibold leading-[19px] text-[14px]"></p>}
      </div>

      <div className="flex gap-2 flex-col lg:items-end lg:flex-row lg:justify-between m-3 lg:gap-4">
        {/* <Connections color="connections" />
        <Group color="Group" /> */}
        <div className="flex flex-col gap-2">
          <div className="flex gap-4 items-center">
            <StarRating rating={4} maxRating={5} />
            <p className="lg:text-[14px] text-xs lg:leading-[21px] font-bold">4.0</p>
          </div>
          <div>
            <p className="font-semibold text-[14px] text-[#808080] ">
              (100) <span>Reviews</span>
            </p>
          </div>
        </div>

        <div className="lg:flex px-4 gap-4 items-center place-items-end items">
          <div
            className="border-1 border-[#1455D9] rounded-[8px] px-[14px] py-[9px] flex gap-3 items-center justify-center"
            onClick={() => handleAddFriend(userId)}
          >
            <MyNetworkIcon />
            <p className="lg:text-[14px] text-xs lg:leading-[21px] font-bold text-[#1455D9] ">
              Connect
            </p>
          </div>

          <div className="border-1 border-white ">
            <div className=" px-[14px] rounded-[8px] py-[9px] flex gap-3 items-center justify-center bg-[#1455D9]">
              <MessageIcon />
              <p className="lg:text-[14px] text-xs lg:leading-[21px] font-bold text-white ">
                Message
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfileCard;
