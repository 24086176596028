import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import ChatBoxHeader from "./ChatBoxHeader";
import { getTimeAgo } from "utils/helpers";

const ChatBox = ({ conversation, recipient, userTyping }) => {
  const { typing, messages } = conversation;
  const { authService } = useStore();
  const currentAccount = authService.getUser();
  const [isMinimized, setIsMinimized] = useState(false);
  const messageContainerRef = useRef(null);

  const handleToggleMinimize = () => {
    setIsMinimized((prevIsMinimized) => !prevIsMinimized);
  };

  const handleTyping = (isTyping) => {
    userTyping(conversation.id, isTyping);
  };

  const isCurrentUser = (userId) => {
    return userId === currentAccount?.user?.userId;
  };

  const renderMessage = (message) => {
    const isUser = isCurrentUser(message.user.userId);
    const senderName = isUser ? "You" : recipient;

    return (
      <div key={message.id} className={`flex pb-2 ${isUser ? "justify-end" : "justify-start"} `}>
        {!isUser && (
          <img
            src={`https://i.pravatar.cc/30?u=${message.user.userId}`}
            alt={senderName}
            className="h-6 w-6 rounded-full mr-2 cursor-pointer"
            title={senderName}
          />
        )}
        <div
          className={`w-full break-words ${
            isUser ? "ml-auto bg-blue-500 text-black" : "mr-auto bg-gray-200 text-gray-700"
          } rounded-lg p-3 relative`}
        >
          {!isUser && (
            <p className="font-medium text-sm mb-1" title={senderName}>
              {senderName}
            </p>
          )}
          <p>{message.message}</p>
          <span className="text-xs text-gray-500 mt-1">{formatTime(message.createdAt)}</span>
        </div>
      </div>
    );
  };

  const chatBoxContainerClassName = `bottom-0 mr-1 ml-3 border border-gray-400 shadow-lg bg-[#fff] rounded-lg overflow-hidden ${
    isMinimized ? "h-10" : "min-h-[400px]"
  } max-h-[calc(100vh-100px)] z-[999] flex flex-col w-[300px]`;

  const messageContainerClassName = `p-4 ${
    isMinimized ? "hidden" : "h-[350px] overflow-y-auto flex-grow"
  }`;

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const formatTime = (timestamp) => {
    if (!timestamp || isNaN(new Date(timestamp).getTime())) {
      console.error("Invalid timestamp:", timestamp);
      return getTimeAgo(timestamp);
    }

    const options = { hour: "numeric", minute: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(timestamp));
  };

  return (
    <div
      className={chatBoxContainerClassName}
      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
    >
      <div className="flex flex-col content-between flex-grow">
        <ChatBoxHeader
          id={conversation.id}
          handleToggleMinimize={handleToggleMinimize}
          isTyping={typing.isTyping && !isCurrentUser(typing?.user.userId)}
          recipient={recipient}
        />
        <div ref={messageContainerRef} className={messageContainerClassName}>
          {messages.length > 0 ? (
            messages.map((message) => renderMessage(message))
          ) : (
            <p className="text-gray-500 text-center">No messages yet</p>
          )}
        </div>
        <div className="mt-2">
          <ChatInput conversation={conversation.id} handleTyping={handleTyping} />
        </div>
      </div>
    </div>
  );
};

export default observer(ChatBox);
