/** @format */

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Box,
  Text
} from "@chakra-ui/react";
import CancelIcon from "assets/icons/CancelIcon";
import SearchInput from "components/Search/SearchInput";
import useWidth from "utils/useWidth";
import { friedsData } from "constant/friends";
import SearchDisplay from "components/List-component/SearchDisplay";
import SelectedUser from "components/List-component/SelectedUser";
const InviteModal = ({ isOpen, onClose, handleModal }) => {
  const [input, setInput] = useState("");
  const [onFocus, setOnFocus] = useState(false);
  const { size } = useWidth();

  const suggestedFriends = Array.from(friedsData.slice(0, 4));
  const [searchedList, setSearchedList] = useState(friedsData);

  const [selectedList, setSelectedList] = useState(suggestedFriends);

  const handleSelected = (member) => {
    if (input) {
      const newList = selectedList.map((element) => {
        if (element.id === member.id) {
          return { ...element, checked: true };
        } else if (member.checked) {
          ({ ...element, checked: false });
        }
      });
      setSelectedList(newList);

      const checkMember = selectedList.find((element) => element.id === member.id);

      if (checkMember.checked) setSelectedList((prev) => [...prev, checkMember]);
      if (!checkMember.checked) {
        const removeAMember = selectedList.filter((element) => element.id !== checkMember.id);
        setSelectedList(removeAMember);
      }
    }
  };

  useEffect(() => {
    if (input.length > 2) {
      const filterdArray = searchedList.filter((friend) => {
        return friend.name.toLowerCase().includes(input.toLowerCase());
      });
      setSearchedList(() => [...filterdArray]);
    }
  }, [input, searchedList]);

  return (
    <Modal
      isOpen={() => {
        isOpen();
      }}
      onClose={() => {
        onClose();
      }}
      isCentered
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <Flex
          justify="space-between"
          w="full"
          alignItems="center"
          borderBottom="2px solid #F2F2F2 "
          // py="0.6rem"
        >
          <Box p={{ base: "1rem" }}>
            <Text
              lineHeight={{ base: "19px", md: "34px" }}
              fontSize={{ base: "20px", md: "25px" }}
              fontWeight={400}
              ml="3px"
              color="#333333"
            >
              Invite connections
            </Text>
          </Box>
          <Box
            border="2px solid #F5F5F5"
            p="16px"
            justifySelf="end"
            borderRadius="4px"
            mr="16px"
            position="relative"
          >
            <Box
              onClick={() => handleModal()}
              position="absolute"
              top={"2"}
              right={"2.5"}
              cursor="pointer"
            >
              <CancelIcon />
            </Box>
          </Box>
        </Flex>

        <ModalBody width="full">
          <div className="flex w-full ">
            <div className="md:w-[60%] w-[100%} p-2">
              <SearchInput
                setInput={setInput}
                input={input}
                width={"100%"}
                onFocus={onFocus}
                setOnFocus={setOnFocus}
                placeholder={"Search by name"}
              />

              <div>
                <div className="flex cursor-pointer mt-3 md:mt-1 justify-between items-center md:block">
                  {input ? null : (
                    <p className=" py-2 text-[#333333] text-[14px] md:text-[16px] leading-[21px] font-semibold">
                      Suggested
                    </p>
                  )}
                  <p className="md:hidden">
                    <span></span>
                  </p>

                  <p className="md:hidden text-[14px] leading-[19px] font-semibold block border-b-1 border-[#0C378B] text-[#0C378B]">
                    Unselect all
                  </p>
                </div>

                <div className="mt-3">
                  <SearchDisplay
                    handleSeleted={handleSelected}
                    data={input ? searchedList : suggestedFriends}
                  />
                </div>
              </div>
            </div>

            <div className="hidden md:block md:w-[40%] border-l-[1px] p-2">
              <div className=" text-[16px]  leading-[21px] font-semibold border-b-1 border-[#ccc] flex justify-between items-center">
                <p className="text-[#808080]">5 Selected</p>
                <p className="text-[#333333] "> Unselect All</p>
              </div>

              <div className="mt-2 ">
                <SelectedUser data={selectedList} />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleModal();
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default InviteModal;
