/** @format */

import React from "react";
import EditIcon from "assets/icons/EditIcon";

// import AboutModal from "../../modal/AboutModal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import {
  useAddCertificate,
  useCertificate,
  useDeleteCertificate,
  useUpdateCertificate
} from "hooks/user/certificate";
import Spinner from "components/Spinner";
import CertificateModal from "../../modal/CertificateModal";

const certificateData = [
  {
    title: "IBK certiofication in UI/UX",
    organisation: "IBK Organisation",
    certificate: "Cousera Platform",
    date: new Date()
  }
];

const isTrue = true;
const Certificate = () => {
  const {
    certificateStore: {
      modal: { isOpen, mode },
      value,
      setValue,
      toggleModal
    }
  } = useStore();

  const { data: { data: certificates = [] } = {}, isLoading } = useCertificate();
  const { mutate: addCertificate } = useAddCertificate(() => toggleModal());
  const { mutate: deleteCertificate } = useDeleteCertificate(() => toggleModal());
  const { mutate: updateCertificate } = useUpdateCertificate(() => toggleModal());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const handleSubmit = () => {
    return mode == "Add" ? addCertificate(value) : updateCertificate(value);
  };

  return (
    <>
      <div className="w-full  mb-5  mb:border-1 shadow-[1px_2px_4px_rgba(0,0,0,0.1)] rounded-lg p-4 bg-secondary-bg">
        <div className="flex justify-between items-center">
          <h3 className="font-bold leading[27.28px] py-2 text-[20px] ">Certificate</h3>
          <div className="lg:mr-6" onClick={() => toggleModal("Add")}>
            <EditIcon size="18" />
          </div>
        </div>
        <div className="flex flex-col gap-2 py-2 md:gap-6 border-[1px] rounded-[4px] border-[#CCCCCC] pl-3 md:border-none">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : Array.isArray(certificates) && certificates.length > 0 ? (
            <main className="text-[14px] text-[#000] font-medium leading-6 whitespace-normal text-start">
              <ul className="flex gap-2 justify-start flex-col">
                {certificates.map((certificate, i) => (
                  <li
                    className="cursor-pointer"
                    key={i}
                    onClick={() => toggleModal("Edit", certificate)}
                  >
                    <div className=" flex gap-2 items-center justify-start">
                      <p className="h-1 w-1 justify-self-start rounded-full bg-black font-semibold text-[12px] leading-[16px] items-start"></p>
                      <p className="font-semibold text-[12px] leading-[16px]">
                        {certificate.title}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </main>
          ) : (
            <p className="text-sm text-[#808080] font-semibold italic text-center py-3">
              No certificate available.
            </p>
          )}
        </div>
      </div>

      {isOpen && (
        <CertificateModal
          isOpen={isOpen}
          mode={mode}
          toggleModal={toggleModal}
          value={value}
          handleChange={handleChange}
          handleSubmit={() => handleSubmit()}
          deleteCertifcate={deleteCertificate}
        />
      )}
    </>
  );
};

export default observer(Certificate);
