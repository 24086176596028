/** @format */

import React from "react";
import ArrowBackIcon from "assets/icons/ArrowBackIcon";
import GroupMember from "../component/GroupMember";
import GroupAdmins from "../component/GroupAdmins";
import { Link, useNavigate } from "react-router-dom";
import ResourceCard from "./ResourceCard";
const Resources = () => {
  const navigate = useNavigate();
  const index = "ttt";

  const resourceData = {
    id: "check",
    image: "/images/backdrop.png",
    courseTitle: "General Training Class for Professionals",
    intro: "Startup tools for any successful career path",
    completionPercertage: 50,
    numbersOfModule: 5,
    rating: 4.0
  };

  // const resources = Array.fill(5, resourceData);

  return (
    <section className="w-full lg:flex gap-7">
      <main className=" md:w-[68%] mt-5 md:mt-0  px-4 gap-8 bg-secondary-bg md:border-1 md:shadow-[1px_2px_4px_rgba(0,0,0,0.3)] md:rounded-md">
        <div
          onClick={() => navigate(-1)}
          className="md:h-[60px] h-[40px] px-2 flex justify-start items-center"
        >
          <ArrowBackIcon />
        </div>
        <div className="grid grid-cols-2 gap-5 items-center justify-center">
          <Link to={`${index}`}>
            <ResourceCard resourceData={resourceData} />
          </Link>
          {/* <ResourceCard />
          <ResourceCard />
          <ResourceCard />
          <ResourceCard />
          <ResourceCard />
          <ResourceCard /> */}
        </div>
      </main>

      {/* RightSideBar for resource */}
      <section className="hidden h-fit md:inline-block md:w-[30%]">
        <div className="w-full">
          <div className="flex w-fill flex-col gap-5">
            <GroupMember />
            <GroupAdmins />
          </div>
        </div>
      </section>
    </section>
  );
};

export default Resources;
