/** @format */

import React from "react";

const CancelIcon = () => {
  return (
    <div>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.749 1.46324C12.0837 1.1285 12.0837 0.58579 11.749 0.251049C11.4142 -0.083683 10.8715 -0.083683 10.5368 0.251049L6.00001 4.78784L1.46324 0.251049C1.1285 -0.083683 0.58579 -0.083683 0.251049 0.251049C-0.083683 0.58579 -0.083683 1.1285 0.251049 1.46324L4.78784 6.00001L0.251049 10.5368C-0.083683 10.8715 -0.083683 11.4142 0.251049 11.749C0.58579 12.0837 1.1285 12.0837 1.46324 11.749L6.00001 7.21218L10.5368 11.749C10.8715 12.0837 11.4142 12.0837 11.749 11.749C12.0837 11.4142 12.0837 10.8715 11.749 10.5368L7.21218 6.00001L11.749 1.46324Z"
          fill="#0C378B"
        />
      </svg>
    </div>
  );
};

export default CancelIcon;
