/** @format */

export const friedsData = [
  {
    id: "gjgfgadc",
    name: "Max Dox",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "tyyatdt",
    name: "John Paul",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "dtyaya",
    name: "Ben Masonj",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },

  {
    id: "ehuad",
    name: "Grase Awoniyi",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "tKIAkd",
    name: "John Paul",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "iakjkjjs",
    name: "Lizzy Anitello",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "qoautd",
    name: "Lizzy Johnson",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "palsoysy",
    name: "Page Martelli",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "oigdbd",
    name: "Ben Terry",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "papsidyd",
    name: "John Paul",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  },
  {
    id: "madjayars",
    name: "Honey Nelson",
    connections: 6,
    image: "/images/gallery.jpg",
    requestStatus: true,
    checked: false
  }
];
