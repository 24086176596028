/** @format */

import { action, makeObservable, observable } from "mobx";

class CertificateStore {
  modal = {
    isOpen: false,
    mode: "Add"
  };

  value = {
    title: "",
    organization: "",
    // certificate: "",
    institutionLogo: "",
    awardDate: new Date()
    // description: "",
  };

  constructor() {
    makeObservable(this, {
      value: observable,
      modal: observable,
      toggleModal: action,
      setValue: action
    });
  }

  toggleModal = (mode = "Add", data = {}) => {
    this.modal = {
      ...this.modal,
      isOpen: !this.modal.isOpen,
      mode
    };

    if (mode == "Add") {
      this.value = {
        title: "",
        organization: "",
        // certificate: "",
        institutionLogo: "",
        awardDate: new Date()
        // description: "",
      };
    }

    if (mode == "Edit") {
      this.value = {
        ...this.value,
        ...data
      };
    }
  };

  setValue = (field, value) => {
    this.value = {
      ...this.value,
      [field]: value
    };
  };
}

export default CertificateStore;
