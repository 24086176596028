/** @format */

import React, { useState } from "react";
import { Divider } from "@chakra-ui/react";
import BackIcon from "assets/icons/BackIcon";
import { avaterArray } from "constant/profile";
import { Avatars } from "pages/Dashboard/component/Connections";
import { useDisclosure } from "@chakra-ui/react";
import InviteModal from "../groupModals/InviteModal";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

const GroupMember = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const {
    groupStore: { groupInfo }
  } = useStore();

  const handleModal = () => {
    setOpen((prev) => !prev);
  };

  const totalMembers = groupInfo.totalMembers;

  return (
    <div className="hidden lg:block relative h-[220px] bg-white w-full  lg:border-1 lg:shadow-[1px_2px_3px_rgba(0,0,0,0.1)] lg:rounded-lg">
      <div className="p-3 flex flex-col gap-3">
        <h3 className="text-[16px] font-bold leading-[21px]">
          {totalMembers} member{totalMembers > 1 ? "s" : null}
        </h3>

        <div className="flex justify-between items-center lg:w-[100%]">
          <Avatars avaterArray={avaterArray} spacing={-6} />
          <div className="lg:w-[44px] lg:h-[44px] flex justify-center items-center rounded-full border-[1px] border-[#CCCCCC]">
            <p className="text-[#CCCCCC] text-[12px]">1.3 M</p>
          </div>
        </div>

        <div className="flex justify-end cursor-pointer">
          <div className="bg-[#E2EAF5] hove:bg-[#fff] rounded-full px-[16px] py-[8px] lg:w-[145px] border-[#0C378B] border-[1px]">
            <p
              onClick={() => {
                handleModal();
              }}
              className="text-[#0C378B] font-semibold leading-[18px] text-[16px]"
            >
              Invite network
            </p>
            {open && (
              <InviteModal
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                handleModal={handleModal}
                setOpen={setOpen}
              />
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="w-full flex justify-center items-center lg:h-[60px]">
        <div className="flex justify-center items-center gap-2">
          <p className="hover:text-[#1455D9] text-[#808080] font-semibold leading-[21px] text-[16px]">
            See all
          </p>
          <BackIcon />
        </div>
      </div>
    </div>
  );
};

export default observer(GroupMember);
