/** @format */

import React from "react";
import GroupCard from "components/Group";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import { useMyGroups } from "hooks/group";

const RequestGroup = () => {
  const { data: { data: groups = [] } = {}, isLoading } = useMyGroups();

  const handleRequest = () => {};

  return (
    <div className="w-[100%] mt-3 flex flex-col gap-4">
      {isLoading && <Spinner />}
      {groups.length > 0 ? (
        groups?.map((group) => (
          <div key={group.id} className="flex h-[72px] justify-between items-center">
            <div>
              <Link to="#">
                <GroupCard data={group} />
              </Link>
            </div>
            <div onClick={() => handleRequest(group.id)}>
              <button className="rounded-[5px] hover:bg-[#E2EAF5]  text-[12px] md:text-[14px] font-bold leading-[21px] text-[#0C378B]  border-[1px] border-[#0C378B] px-[16px] py-[8px] flex justify-center items-center">
                Withdraw
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No group request pending</p>
      )}
    </div>
  );
};

export default RequestGroup;
