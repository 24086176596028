/** @format */

import React, { useState } from "react";
import { FooterRoute } from "../Footer";
import { Select } from "@chakra-ui/react";
import HelpIcon from "assets/icons/HelpIcon";
import { footerAbouts, footerMentees } from "constant/footer";

const MoveableFooter = () => {
  const [selectLang, setSelectLang] = useState("");
  return (
    <section className="w-[100%] px-2 mt-4 flex flex-col gap-4 justify-center">
      <div className="flex justify-between w-[100%]">
        <FooterRoute content={footerAbouts} />
        <FooterRoute content={footerMentees} />
      </div>

      <div className="flex justify-center items-center">
        <img
          src="/images/Logo.png"
          className="object-cover md:w-[185px] md:h-[80px]"
          alt="footer"
        />
      </div>

      <div className="flex w-full gap-5 flex-col justify-center items-center">
        <div className="bg-white w-[90%]">
          <Select
            value={selectLang}
            placeholder="Select Language"
            onChange={(e) => setSelectLang(e.target.value)}
            className=""
          >
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Yoruba">Yoruba</option>
          </Select>
        </div>

        <div className="w-full flex gap-3 justify-center items-center">
          <div>
            <HelpIcon />
          </div>
          <p className="text-[#0C378B] text-sm font-bold">Help Center</p>
        </div>
      </div>
    </section>
  );
};

export default MoveableFooter;
