/** @format */

import GroupIcon from "assets/icons/GroupIcon";
import React from "react";
import MenteeIcon from "assets/icons/MenteeIcon";
import Message from "assets/icons/Message";
import MyNetworkIcon from "assets/icons/MyNetworkIcon";
import SettingsIcon from "assets/icons/SettingsIcon";
import AddFriendsIcon from "assets/icons/AddFriendsIcon";
import ProfileIcon from "assets/icons/ProfileIcon";
import ResourceSideIcon from "assets/icons/ResourceSideIcon";
import { Routes } from "constant";

export const navigation = [
  {
    name: "Profile",
    href: "/app",
    current: true,
    icon: <ProfileIcon />
  },

  {
    name: "Message",
    href: "/app/message",
    current: false,
    icon: <Message />
  },
  {
    name: "Mentorship",
    href: "/app/mentorships",
    current: false,
    icon: <MenteeIcon />
  },
  {
    name: "Resources",
    href: Routes.Resources,
    current: false,
    icon: <ResourceSideIcon />
  },

  {
    name: "Group",
    href: "/app/groups",
    current: false,
    icon: <GroupIcon />
  },
  {
    name: "Friend request",
    href: "/app/friend-request",
    current: false,
    icon: <AddFriendsIcon />
  },
  {
    name: "My network",

    href: "/app/friend-request/networks",
    current: false,
    icon: <MyNetworkIcon />
  },
  {
    name: "Settings",
    // href: "/app/settings",
    href: "#",
    current: false,
    icon: <SettingsIcon />
  }
];

export const friendsPageRouteList = [
  {
    name: "Network",
    routeTo: "/app/friend-request/networks"
  },
  {
    name: "Connect Request",
    routeTo: "/app/friend-request"
  },
  {
    name: "Request Sent",
    routeTo: "/app/friend-request/request-sent"
  }
];

export const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"]
];
