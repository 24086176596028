/** @format */

import { dummyProfile } from "constant/profile";
import React from "react";

const ChatProfileCard = ({ data }) => {
  return (
    <div className="flex w-full items-center">
      <div className="flex items-center  gap-4 h-[72px]">
        <div className="w-[72px] h-[72px]">
          <img
            src={data.src || dummyProfile.src}
            className="w-[64px] h-[64px] lg:w-[] lg:h-[] rounded-full "
          />
        </div>

        <div className="text-[#333333] h-[64px] flex gap-1 flex-col justify-center  w-[100%]">
          <div className="flex justify-between items-center w-[100%]">
            <p className="text-[14px] leading-[19px] font-semibold">
              {data.firstName} {data.lastName}
            </p>
            <p className="text-[12px] leading-[19px] font-semibold">Oct 20</p>
          </div>
          <p className="text-[12px] md:text-[14px] leading-[16px] text-[#333333]">
            {data.firstName}: {data.text.slice(0, 45)}...
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatProfileCard;
