import React, { useEffect } from "react";
import {
  HStack,
  Box,
  Stack,
  useMediaQuery,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody
} from "@chakra-ui/react";

import { Outlet, useNavigate } from "react-router-dom";
import SidebarMenu from "components/SidebarMenu";
import { SIDE_BAR_MENU } from "constant";
import Header from "./Header";
import { useStore } from "store";
import { Routes } from "constant";

const OnboardingLayout = () => {
  const { authService } = useStore();
  const navigate = useNavigate();
  const isAuthenticated = authService.isAuthenticated();
  useEffect(() => {
    !isAuthenticated && navigate(Routes.Login);
  }, [isAuthenticated, navigate]);

  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userRole = "Mentor";
  return (
    <HStack spacing="0" h="100vh" align="stretch" overflowY="hidden">
      {isDesktop && (
        <Box bg="#084482" width="250px">
          <SidebarMenu onCloseMobileMenu={onClose} menuItems={SIDE_BAR_MENU} isDisabled />
        </Box>
      )}
      <Stack
        justify="stretch"
        flex="1"
        boxShadow="rgba(0, 0, 0, 0.2) -20px 0px 20px -20px"
        overflowY="hidden"
        spacing="0"
      >
        <Header onOpen={onOpen} userRole={userRole} />
        <Box overflowY="auto" h="100%">
          <Outlet />
        </Box>
      </Stack>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent bgColor="#084482">
            <DrawerBody>
              <SidebarMenu onCloseMobileMenu={onClose} menuItems={SIDE_BAR_MENU} isDisabled />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </HStack>
  );
};

export default OnboardingLayout;
