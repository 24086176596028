import { action, makeAutoObservable, observable } from "mobx";
class ResourceStore {
  initialValues = {
    title: "",
    description: "",
    privacy: "PRIVATE",
    file: "",
    isPaid: false,
    price: null
  };

  constructor() {
    makeAutoObservable(this, {
      initialValues: observable,
      setInitialValues: action
    });
  }

  setInitialValues = (field, value) => {
    if (field === "price") {
      this.initialValues = {
        ...this.initialValues,
        [field]: value,
        isPaid: true,
        privacy: "PRIVATE"
      };
    } else {
      this.initialValues = {
        ...this.initialValues,
        isPaid: false,
        privacy: "PUBLIC",
        [field]: value
      };
    }
    // this.initialValues = {};
  };
}

export default ResourceStore;
