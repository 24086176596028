/** @format */

import React from "react";

const ShapeIcon = () => {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7071 0.292906C21.9306 0.516389 22.0262 0.819335 21.9939 1.1108C21.9848 1.1925 21.9657 1.2733 21.9366 1.3512L14.9439 21.3304C14.8084 21.7174 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4062L9.2424 12.7576L0.593867 8.91382C0.219186 8.7473 -0.0158775 8.36893 0.000836285 7.95925C0.0175501 7.54957 0.282654 7.1916 0.669655 7.05615L20.6488 0.063463C20.7272 0.0341206 20.8085 0.0149552 20.8907 0.00596671C20.9511 -0.000677167 21.0117 -0.00175303 21.0717 0.00257072C21.3032 0.0191136 21.5301 0.115892 21.7071 0.292906ZM17.1943 3.39148L3.71108 8.11062L9.77854 10.8073L17.1943 3.39148ZM11.1928 12.2215L18.6085 4.8057L13.8894 18.2889L11.1928 12.2215Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ShapeIcon;
