/** @format */

import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  FormControl,
  Input,
  ModalOverlay,
  FormLabel,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Spacer,
  Select
} from "@chakra-ui/react";
import dayjs from "dayjs";
import ModalButton from "components/Button/ModalButton";
import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import SaveIcon from "assets/icons/SaveIcon";
import PlusIcon from "assets/icons/PlusIcon";
import DustbinIcon from "assets/icons/DustbinIcon";

import CreatableSkill from "pages/Dashboard/component/CreatableSkill";
import useWidth from "utils/useWidth";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import {
  useAddExperinceMutation,
  useEditExperienceMutation,
  useExperienceDeleteMututation
} from "hooks/user/experience";

const industryData = [
  "Technology",
  "Health",
  "IT",
  "Banking",
  "Finance",
  "Teaching",
  "Freelancing"
];

const ExperienceModal = () => {
  const { size } = useWidth("sm", "lg");
  const initialRef = React.useRef(null);
  const {
    experienceStore: {
      toggleModal,
      setRemoveExperienceModal,
      modal: { isOpen, mode },
      initialValues: values,
      setInitialValue
    }
  } = useStore();

  const { mutate: addExperience } = useAddExperinceMutation(() => {
    toggleModal();
  });
  const { mutate: editExperience } = useEditExperienceMutation(() => {
    toggleModal();
  });
  const { mutate: deleteExperience } = useExperienceDeleteMututation(() => {
    setRemoveExperienceModal();
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitialValue(name, value);
  };

  const setSkills = (value) => {
    setInitialValue("skills", value);
  };

  const handleSubmit = () => {
    console.log("Submit Experience", values);
    mode === "Add" ? addExperience(values) : editExperience(values);
  };

  const removeExperience = () => {
    if (mode === "Edit") {
      deleteExperience(values.id);
    }
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={true}
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => toggleModal}
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Flex alignItems="center" gap={2}>
                <Box border="1px solid gray" p="4px" rounded="4px" onClick={toggleModal}>
                  <ArrowLeftIcon />
                </Box>
                <Box
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={700}
                  ml="3px"
                >
                  {mode} Experience
                </Box>
              </Flex>
              <Spacer />
              <Box>
                <Flex alignItems="center" gap={2}>
                  <Box
                    border="1px solid gray"
                    p="4px"
                    rounded="4px"
                    onClick={() => toggleModal("Add")}
                  >
                    <PlusIcon />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                Title
              </FormLabel>

              <Input
                ref={initialRef}
                name="title"
                value={values.title}
                type="text"
                placeholder="Title"
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Company
              </FormLabel>

              <Input
                name="company"
                value={values.company}
                type="text"
                placeholder="Company"
                background="rgba(20, 85, 217, 0.05)"
                onChange={handleChange}
              />
            </FormControl>

            <Flex justify="space-between" w="100%" mt="20px">
              <Box width="1/3">
                <Box lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                  Start Date
                </Box>
                <Box p="10px">
                  <Input
                    id="startDate"
                    fontSize={{ base: "1rem", md: "0.875rem" }}
                    bgColor="#F3F5F7"
                    name="startDate"
                    type="date"
                    value={
                      values.startDate
                        ? dayjs(values.startDate).format("YYYY-MM-DD")
                        : dayjs(userInfo?.startDate).format("YYYY-MM-DD")
                    }
                    onChange={handleChange}
                    aria-label="startDate"
                  />
                </Box>
              </Box>

              <Spacer />
              <Box width="1/3">
                <Box lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                  End Date
                </Box>
                <Box p="10px">
                  <Input
                    id="endDate"
                    fontSize={{ base: "1rem", md: "0.875rem" }}
                    bgColor="#F3F5F7"
                    name="endDate"
                    type="date"
                    value={
                      values.endDate
                        ? dayjs(values.endDate).format("YYYY-MM-DD")
                        : dayjs(userInfo?.endDate).format("YYYY-MM-DD")
                    }
                    onChange={handleChange}
                    aria-label="endDate"
                  />
                </Box>
              </Box>
            </Flex>

            <Box width="50%" mt="30px">
              <Box
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                borderRadius="md"
              >
                Industry
              </Box>
              <Select placeholder="Select option" name="industry">
                {industryData?.map((skill, i) => (
                  <option key={i} value={skill}>
                    {skill}
                  </option>
                ))}
              </Select>
            </Box>

            <Box mt="20px">
              <Box
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                borderRadius="md"
              >
                Skill
              </Box>
              <CreatableSkill skills={values.skills} setSkills={setSkills} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex alignItems="center" gap={4}>
              {mode === "Edit" && (
                <ModalButton
                  bg="#fff"
                  handleSubmit={removeExperience}
                  text="#0C378B"
                  title="Delete"
                >
                  <DustbinIcon />
                </ModalButton>
              )}

              <ModalButton title="Save" handleSubmit={handleSubmit}>
                <Box>
                  <SaveIcon />
                </Box>
              </ModalButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ExperienceModal);
