import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  addUserEducation,
  deleteUserEducation,
  editUserEducation,
  getUserEducation
} from "api/user";

export const useEducation = () => {
  const query = useQuery({
    queryFn: () => getUserEducation(),
    queryKey: ["education"]
  });

  return query;
};

export const useAddEducationMutation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: addUserEducation,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["education"]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }
  });

  const addEducation = (body) => {
    mutate.mutate(body);
  };

  return {
    mutate: addEducation,
    isError: mutate.isError,
    isSuccessFull: mutate.isSuccess,
    message: mutate.error
  };
};

export const useEditEducationMutation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: editUserEducation,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["education"]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }
  });

  const editEducation = (body) => {
    mutate.mutate(body);
  };

  return {
    editEducation,
    isError: mutate.isError,
    isSuccessful: mutate.isSuccess,
    message: "Failed to edit"
  };
};

export const useEducationDeleteMutation = (onSuccessCallback) => {
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: deleteUserEducation,
    onSuccess: (data) => {
      toast(data?.message);
      queryClient.invalidateQueries(["education"]);
      onSuccessCallback();
    }
  });

  const deleteEducationMutation = (id) => {
    mutate.mutate(id);
  };

  return {
    mutate: deleteEducationMutation,
    isError: mutate.isError,
    isSuccessFull: mutate.isSuccess
  };
};
