import { footerAbouts, footerMentees, footerFAQ } from "constant/footer";

import { Select } from "@chakra-ui/react";
import HelpIcon from "assets/icons/HelpIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const FooterRoute = ({ content }) => {
  return (
    <div className="flex flex-col md:gap-[10px] gap-[10px] md:pl-6">
      {content?.map((ele, i) => (
        <Link key={i} to={ele.href}>
          <p className="font-[600] text-[#333]  text-[10px] leading-[13px] md:text-[12px] md:leading-[17px]">
            {ele.title}
          </p>
        </Link>
      ))}
    </div>
  );
};

const Footer = () => {
  const [lang, setLang] = useState("Select Language");

  return (
    <div className="flex justify-start items-start md:grid md:grid-cols-4  md:grid-flow-row mb-20 lg:mt-5 ">
      <div className="bg-red flex justify-center w-[200px] md:w-[185px] items-center">
        <img src="/images/Logo.png" className="object-cover md:w-[185px]" alt="footer" />
      </div>

      <div className=" w-[100%] md:col-span-2 px-2 grid md:grid-cols-4 grid-flow-col-dense justify-between  gap-1 md:gap-3">
        <FooterRoute content={footerMentees} />
        <FooterRoute content={footerAbouts} />
        <div className="hidden md:block">
          <FooterRoute content={footerAbouts} />
        </div>
        <FooterRoute content={footerFAQ} />
      </div>

      <div className="lg:ml-16 hidden md:inline-flex flex-col justify-between items-center w-full h-[100%]">
        <div>
          <div className="bg-[#fff] w-[200px]">
            <Select
              value={lang}
              placeholder="Select Language"
              onChange={(e) => setLang(e.target.value)}
              className=" bg-white"
            >
              <option value="English">English</option>
              <option value="French">French</option>
              <option value="Yoruba">Yoruba</option>
            </Select>
          </div>
        </div>

        <div className=" hidden md:block">
          <div className="w-full flex gap-3 justify-center items-center">
            <div>
              <HelpIcon />
            </div>
            <p className="text-[#0C378B] text-sm font-bold">Help Center</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
