/** @format */

import { action, makeAutoObservable, observable } from "mobx";

class CareerStore {
  input = "";
  modal = {
    open: false
  };

  constructor() {
    makeAutoObservable(this, {
      modal: observable,
      input: observable,
      setInput: action,
      toggleModal: action
    });
  }

  toggleModal = () => {
    this.modal = {
      ...this.modal,
      open: !this.modal.open
    };
  };

  setInput = (value) => {
    this.input = value;
  };
}

export default CareerStore;
