/** @format */

import ResourceIcon from "assets/icons/ResourceIcon";
import React, { useEffect, useState } from "react";
import ResourceProgressBar from "./ResourceProgressBar";

import StarRating from "components/Rating";
import ProgressBar from "./ProgressBar";

const ResourceCard = ({ resourceData }) => {
  // const [percentage, setPercentage] = useState(0);

  return (
    <div className=" p-2 w-fit border-1 border-[#CCCCCC] rounded-[16px]">
      <div className="relative w-fit  rounded-lg md:h-[234px] flex justify-center ">
        <img
          src={resourceData.image}
          alt="resource-image"
          className="object-cover md:w-fit rounded-lg md:h-[234px]  "
        />

        <div className="absolute z-10 h-[40px] md:left-[80%] left-[70%] w-[40px] rounded-full bg-white flex justify-center items-center bottom-3">
          <ResourceIcon />
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-2">
        <p className="text-[16px] text-[#333] leading-[22px] font-bold">
          {resourceData.courseTitle}
        </p>

        <p className="leading-[24px] font-normal text-[12px] text-[#0C378B]">
          {resourceData.intro}
        </p>
        <div className="w-[100%] h-2">
          <ProgressBar percentage={`${resourceData.completionPercertage}%`} />
        </div>
        <div className="flex justify-between items-center text-[#808080]  text-[12px] leading-[24px] ">
          <p className="">{resourceData.completionPercertage}% Complete</p>
          <p className="">{resourceData.numberOfModule} Modules</p>
        </div>
        <div className="flex justify-end gap-3 items-center">
          <StarRating rating={resourceData.rating} maxRating={5} />
          <p className=" text-[#808080]  text-[12px] leading-[24px] ">({resourceData.rating}.0)</p>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
