/** @format */

import React, { useState } from "react";
import BellIcon from "assets/icons/BellIcon";
import InfoIcon from "assets/icons/InfoIcon";
import MoreIcon from "assets/icons/MoreIcon";
import ShareIcon from "assets/icons/ShareIcon";
import PencilIcon from "assets/icons/PencilIcon";
import SendIcon from "assets/icons/SendIcon";
import CopyIcon from "assets/icons/CopyIcon";
import dummyCover from "assets/images/cover-photo.jpg";
import SettingsIcon from "assets/icons/SettingsIcon";
import LogoutIcon from "assets/icons/LogoutIcon";
import FlagIcon from "assets/icons/FlagIcon";
import IndustryIcon from "assets/icons/IndustryIcon";
import { useDisclosure } from "@chakra-ui/react";
import GroupDescriptionModal from "../groupModals/GroupDescriptionModal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { Link } from "react-router-dom";
import { dummyProfile } from "constant/profile";
import { GoBook } from "react-icons/go";

const MoreDropDown = () => {
  return (
    <div className="z-50 w-[256px] hidden lg:block rounded-e-lg rounded-b-lg lg:h-[232px] bg-[#fff] lg:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] ">
      <div className="p-4 flex flex-col justify-evenly  h-full">
        <div className="flex items-center gap-5">
          <div className="hover:bg-[#ccc] hover:rounded-lg py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[18px] font-bold">
            <BellIcon color="#333333" />
            <p>Manage notifications</p>
          </div>
        </div>

        {/*  */}
        <div className="flex items-center gap-5">
          <div className="hover:bg-[#ccc] hover:rounded-lg py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <SettingsIcon color="#333333" />
            <p>Group settings</p>
          </div>
        </div>

        <div className="flex items-center gap-5 ">
          <div className="hover:bg-[#ccc] hover:rounded-lg  py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <LogoutIcon />
            <p>Leave this group</p>
          </div>
        </div>

        <div className="flex items-center gap-5 ">
          <div className="hover:bg-[#ccc] hover:rounded-lg  py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <FlagIcon />
            <p>Report this group</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoDropDown = () => {
  return (
    <div className="w-[276px] hidden lg:block rounded-e-lg rounded-b-lg lg:h-[180px] bg-[#fff] lg:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] ">
      <div className="p-4 flex flex-col justify-evenly  h-full">
        <div className="flex items-center gap-5">
          <div className="hover:bg-[#ccc] hover:rounded-lg py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[18px] font-bold">
            <PencilIcon />
            <p>Repost to timeline</p>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <div className="hover:bg-[#ccc] hover:rounded-lg py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <SendIcon />
            <p>Send as direct message</p>
          </div>
        </div>

        <div className="flex items-center gap-5 ">
          <div className="hover:bg-[#ccc] hover:rounded-lg  py-[8px] px-[10px] flex items-center gap-5 text-[14px] leading-[21px] font-bold">
            <CopyIcon />
            <p>Copy link</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupsHeader = () => {
  const [openMore, setOpenMore] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [showAboutProfile, setShowAboutProfile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    groupStore: { groupInfo }
  } = useStore();
  const groupCover = groupInfo?.groupCover || dummyCover;
  return (
    <div className=" mt-3 lg:mt-[0px] relative  bg-white col-span-3 w-[100%]  lg:border-1 lg:shadow-[1px_2px_4px_rgba(0,0,0,0.1)] rounded-md ">
      <div className="w-full p-2 lg:p-[0px]">
        <div className="lg:h-[168px] w-[full]  lg:px-[0px]">
          <img
            src={groupCover}
            alt="groups-cover"
            className="object-cover lg:h-[168px] h-[98px] w-[100%] lg:w-[100%]  rounded-t-lg"
          />
        </div>

        <div className="w-full ">
          <div className="relative w-full">
            <div className="absolute lg:-top-[75px] top-[-40px] left-7 ">
              <img
                src={dummyProfile.src}
                alt="group-coverphoto"
                className="lg:h-[160px] w-[101px] h-[101px] lg:w-[160px] rounded-full "
              />
            </div>
          </div>

          <div className="flex justify-between items-end lg:py-3">
            <div></div>
            <div className="flex items-center gap-3 justify-evenly px-3 w-[200px]">
              <div className="hover:bg-[#CCCCCC] rounded-full flex justify-center items-center p-2">
                {showAboutProfile && (
                  <GroupDescriptionModal
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    description={groupInfo.description}
                    toggle={() => setShowAboutProfile(!showAboutProfile)}
                  />
                )}

                <div
                  onClick={() => {
                    setShowAboutProfile((prev) => !prev);
                    onOpen();
                  }}
                >
                  <InfoIcon />
                </div>
              </div>

              <div
                onClick={() =>
                  setOpenInfo((prev) => {
                    if (openMore) {
                      setOpenMore(false);
                    }
                    return !prev;
                  })
                }
                className="hover:bg-[#CCCCCC] rounded-full flex justify-center items-center p-2 relative"
              >
                <ShareIcon />
                <div className="absolute top-11 left-1">{openInfo && <InfoDropDown />}</div>
              </div>
              <div className="hover:bg-[#CCCCCC] rounded-full flex justify-center items-center p-2">
                <BellIcon />
              </div>
              <div
                onClick={() =>
                  setOpenMore((prev) => {
                    if (openInfo) {
                      setOpenInfo(false);
                    }
                    return !prev;
                  })
                }
                className="hover:bg-[#CCCCCC] rounded-full flex justify-center items-center p-2 relative"
              >
                <MoreIcon />
                <div className="absolute top-11 right-2 z-50">{openMore && <MoreDropDown />}</div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="py-4  mt-5 flex gap-3 flex-col px-4 w-full">
          <p className=" text-[20px] font-[700] leading-[21px] text-[#333] w-full ">
            {/* {groupInfo.groupName} */}Career Development Group
          </p>
          <div className="flex justify-between items-center w-[100%]">
            <div className="flex flex-col gap-2">
              <p className=" text-[14px] font-[600] leading-[18px] text-[#808080]">
                23 mutuals joined
              </p>

              <div className="flex items-center gap-2 text-[14px] font-[700] leading-[18px] text-[#0C378B]">
                <IndustryIcon />
                <p>Tech Industry </p>
              </div>
            </div>

            <div className="flex items-center gap-2 border-[1px] border-[#CCCCCC]  rounded-lg p-4 lg:px-[25px] lg:py-[11px]">
              <GoBook />
              <p className=" hidden lg:block text-[#333333] text-[14px] leading-[19px]">
                <Link to="resource"> Resources</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(GroupsHeader);
