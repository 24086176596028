import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { createGroup, fetchGroupDetails, fetchGroupFeeds, getMyGroups } from "api/group";

export const useMyGroups = () => {
  const query = useQuery(["myGroups"], getMyGroups);
  return query;
};
export const useGroupDetails = (groupId, setInfo) => {
  const query = useQuery(["getGroupDetails", groupId], () => fetchGroupDetails(groupId));

  if (query.isSuccess && setInfo) {
    setInfo(query.data.data);
  }

  return query;
};
export const useGroupFeeds = (groupId, skip, limit) => {
  const query = useQuery(["fetchGroupFeeds", groupId], () => fetchGroupFeeds(groupId, skip, limit));
  return query;
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((postData) => createGroup(postData), {
    onSuccess: (data) => {
      toast(data?.message);
      if (data.status) {
        queryClient.invalidateQueries(["myGroups"], { refetchActive: true });
      }
    },
    onError: (data) => console.log(data)
  });

  return mutation;
};
