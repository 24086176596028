import React from "react";
import icons from "./icon-sprite.svg";

function Icon({ width, height, id, className, onClick, size, disabled }) {
  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      className={`${className} ${disabled ? "opacity-7" : ""}`}
      onClick={onClick}
    >
      <use xlinkHref={`${icons}#${id}`} />
    </svg>
  );
}

export default Icon;
