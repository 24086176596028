import React from "react";
import { LandingPageNavbar } from "./components/LandingPageNavbar";
import { Outlet } from "react-router-dom";
import Footer from "pages/LandingPage/components/Footer";
import { Flex } from "@chakra-ui/react";

export const PublicLayout = () => {
  return (
    <div>
      <LandingPageNavbar />
      <Outlet />

      <Flex className="flex justify-center  bg-blue">
        <Footer />
      </Flex>
    </div>
  );
};
