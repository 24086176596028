/** @format */

import React from "react";

const FeedText = ({ text }) => {
  return (
    <div className="w-full px-6  justify-center items-center">
      <p className="text-[14px]  leading-[21px] text-[#333333]"> {text}</p>
    </div>
  );
};

export default FeedText;
