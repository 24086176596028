/** @format */

import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "assets/icons/HomeIcon";
import MyNetworkIcon from "assets/icons/MyNetworkIcon";
import NotificationIcon from "assets/icons/NotificationIcon";
import SearchInput from "components/Search/SearchInput";
import HomeDrawer from "./Drawer/Drawer";
import { dummyProfile } from "constant/profile";
import NavDropdownIcon from "assets/icons/NavDropdownIcon";
import MenuDropdown from "./Custom-dropdown/MenuDropdown";

import { useSearch } from "hooks/search";
import { useGetProfile } from "hooks/user";

const navigation = [
  { name: "Home", href: "/app/timeline", current: true, icon: <HomeIcon /> },
  {
    name: "My network",
    href: "/app/friend-request/networks",
    current: false,
    icon: <MyNetworkIcon color="#fff" />
  },
  {
    name: "Notification",
    href: "#",
    current: false,
    icon: <NotificationIcon />
  }
];

function Nav({ showNav, setShowNav }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [onFocus, setOnFocus] = useState(false);
  const { data } = useGetProfile();

  const { refetch } = useSearch(searchQuery);

  const handleKeyDown = (e) => {
    if (searchQuery.trim() === "") return;
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
      navigate(`/app/search?keyword=${searchQuery}`, {
        replace: true
      });
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    if (searchQuery.length > 3) refetch();
  }, [refetch, searchQuery]);

  return (
    <nav className=" z-10 w-full h-[80px]  bg-[#0C378B] flex justify-center items-center">
      <section className=" w-[100%] lg:w-[calc(1200px-104px)] h-[100%] flex justify-between items-center">
        <div className="lg:hidden">
          <HomeDrawer />
        </div>
        <div className="flex gap-7 lg:items-center ">
          <div className="flex-shrink-0">
            <img
              src="/images/proXsure_logo.svg"
              className="h-32 lg:w-[185px] w-auto block "
              alt="logo"
            />
          </div>

          {/*  */}
          <div className="hidden lg:inline-block">
            <SearchInput
              setInput={setSearchQuery}
              input={searchQuery}
              onFocus={onFocus}
              setOnFocus={setOnFocus}
              placeholder={"Search"}
              navigateTo={handleKeyDown}
            />
          </div>
        </div>

        <div className="w-[30px] h-[30px] mr-2 lg:hidden" onClick={() => navigate("/app/timeline")}>
          <Link to="/app">
            <HomeIcon />
          </Link>
        </div>

        <div className=" hidden lg:inline-flex  gap-8 items-center">
          <div className="flex items-center gap-5 lg:h-full">
            {navigation.map((nav, i) => (
              <NavLink key={i} to={nav.href}>
                <div className="flex flex-col justify-center items-center text-white">
                  {nav.icon}
                  <p className="font-bold leading-[21px] text-[16px]">{nav.name}</p>
                </div>
              </NavLink>
            ))}
          </div>

          <div className="lg:h-full border-l">
            <div className=" lg:flex lg:gap-3 text-white items-center pl-4">
              <Link to="/app/profile">
                <img
                  className="rounded-full lg:h-10 lg:w-10 object-cover"
                  src={data?.data?.profile?.profilePic || dummyProfile.src}
                  alt="nav-image"
                />
              </Link>
              <div className=" relative">
                <div
                  className="flex items-center gap-2 z-30"
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    setShowNav(!showNav);
                  }}
                >
                  <p className="text-[#fff] text-[16px] leading-5 font-bold">Me</p>
                  <NavDropdownIcon />
                </div>
                <div className="absolute right-0 top-[60px] z-10">
                  {showNav && <MenuDropdown ref={ref} showNav={showNav} setShowNav={setShowNav} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </nav>
  );
}
export default Nav;
