/** @format */

import React from "react";

const EmptyComponent = () => {
  return (
    <div className="w-full h-full">
      <img src="/images/messageframe.png" alt="chatimage" className="w-[100%] h-[100%]" />
    </div>
  );
};

export default EmptyComponent;
