import React from "react";
import { BellIcon, HamburgerIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Image,
  IconButton,
  Center,
  HStack,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery
} from "@chakra-ui/react";
import userIcon from "assets/svgs/user.svg";
import { useNavigate } from "react-router";
import AuthServiceInstance from "services";
import { observer } from "mobx-react-lite";
import { Routes } from "constant";

const Header = ({ onOpen, userRole }) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const firstName = AuthServiceInstance.getProfile()?.firstName;
  const lastName = AuthServiceInstance.getProfile()?.lastName;
  const logOut = () => {
    AuthServiceInstance.deleteToken();
    navigate(Routes.Login);
  };
  return (
    <HStack
      justify={["space-between", "space-between", "flex-end"]}
      p={["15px", "30px 50px"]}
      spacing="15px"
      boxShadow="md"
      zIndex="1"
    >
      {!isDesktop && (
        <IconButton
          bgColor="#DFDFDF"
          color="#FD5D03"
          boxSize="40px"
          onClick={onOpen}
          aria-label="Sidebar"
          icon={<HamburgerIcon />}
        />
      )}
      <HStack>
        <IconButton
          bgColor="#DFDFDF"
          color="#FD5D03"
          boxSize="40px"
          aria-label="Notification"
          icon={<BellIcon />}
        />
        <Menu>
          <MenuButton>
            <HStack>
              <Center boxSize="40px" borderRadius="10px" bgColor="#DFDFDF" p="15px">
                <Image src={userIcon} />
              </Center>
              <Stack align="flex-start" spacing="0">
                <Text color="#084482" textTransform="capitalize">
                  {`${firstName} ${lastName}`}
                </Text>
                <Text>{userRole}</Text>
              </Stack>
              <TriangleDownIcon w={3} />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem>Profile</MenuItem>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
};
export default observer(Header);
