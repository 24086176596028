/** @format */

import React from "react";

const Elliples = () => {
  return (
    <div>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22" cy="22" r="22" fill="white" />
      </svg>
    </div>
  );
};

export default Elliples;
