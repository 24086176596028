/** @format */

import DashboardIcon from "assets/icons/DashboardIcon";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { navigation } from "constant/menu";

const RightSideBar = ({ onClose }) => {
  const { pathname } = useLocation();

  const handleLinkClick = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div className=" bg-secondary-bg md:border-1 md:shadow-[3px_5px_6px_rgba(0,0,0,0.2)] md:rounded-md ">
      <div className=" md:rounded-xl ">
        <nav className=" flex justify-start lg:justify-center gap-3 flex-col text-[#0c378b] w-full md:p-2">
          <Link
            to="/app/profile"
            className=" w-full font-bold border-b-[1px] md:border-none"
            onClick={handleLinkClick}
          >
            <div className="flex items-center w-full text-[16px] rounded-[5px] hover:border-1 hover:border-[#92b0ec] hover:shadow-[3px_5px_6px_rgba(19,39,78,0.2)] py-[7px] px-[28px]  leading-[21.3px] font-bold">
              <div className="lg:w-30 justify-items-end">
                <span>
                  <DashboardIcon />
                </span>
              </div>
              <div className="ml-4">
                <p> Dashboard</p>
              </div>
            </div>
          </Link>
          {navigation.map((nav) => (
            <Link
              key={nav.href}
              to={nav.href}
              className={`w-full font-bold border-b-[1px] md:border-none ${
                pathname === nav.href ? "active" : ""
              }`}
              onClick={handleLinkClick}
            >
              <div className="flex items-center w-full text-[16px] rounded-[5px] hover:border-1 hover:border-[#92b0ec] hover:shadow-[3px_5px_6px_rgba(19,39,78,0.2)] py-[7px] px-[28px] leading-[21px] font-bold">
                <div className="lg:w-30 justify-items-end">
                  <span className={`${pathname === nav.href ? "text-white" : "text-[#0c378b]"} `}>
                    {nav.icon}
                  </span>
                </div>
                <div className="ml-4">
                  <p>{nav.name}</p>
                </div>
              </div>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default RightSideBar;
