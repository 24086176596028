import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Button,
  Box,
  Text,
  Flex,
  Input
} from "@chakra-ui/react";
import AddInputImageIcon from "assets/icons/AddInputImageIcon";
import UploadIcon from "assets/icons/UploadIcon";
import useWidth from "utils/useWidth";
// import { useStore } from "store";
import { useAddCoverPhoto } from "hooks/user";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

const CoverPhotoModal = ({ isOpen, onClose, toggle }) => {
  const [image, setImage] = useState({ image: "", fileName: "coverphoto" });

  const [photo, setPhoto] = useState("");
  const {
    userStore: {
      selectCoverPhoto: { seletedFile },
      setCoverPhoto
    }
  } = useStore();
  const imageRef = useRef();
  const { size } = useWidth("sm", "2xl");
  const { mutate: addCoverPhoto } = useAddCoverPhoto();

  const handleFile = (e) => {
    const result = e.target.result;

    setPhoto(result);
  };

  const handleChange = (value, file) => {
    setCoverPhoto(file);
    setImage((prev) => ({
      ...prev,
      image: file,
      fileName: value
    }));

    const reader = new FileReader();
    reader.onload = handleFile;
    reader.readAsDataURL(file);
  };

  const handleFocus = () => {
    imageRef.current.click();
  };

  const handleSubmit = () => {
    const formData = new FormData();

    console.log("Checking selected file: ", seletedFile);
    formData.append("file", seletedFile);

    const fileHeader = {
      header: {
        "content-type": "multipart/form-data"
      }
    };

    addCoverPhoto(formData, fileHeader);
  };

  return (
    <div className="md:w-[600px]">
      <Modal
        onClose={() => {
          onClose();
          toggle();
        }}
        size={size}
        isOpen={() => {
          isOpen();
          toggle();
        }}
        motionPreset="scale"
        // isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            justify="space-between"
            w="full"
            alignItems="center"
            borderBottom="2px solid #F2F2F2 "
            py="0.6rem"
          >
            <Box p={{ base: "1rem" }} onClick={() => handleModal()}>
              <Text
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight={700}
                ml="3px"
              >
                Upload Cover Photo
              </Text>
            </Box>
            <Box
              border="2px solid #F5F5F5"
              p="16px"
              justifySelf="end"
              borderRadius="4px"
              mr="16px"
              position="relative"
            >
              <Box position="absolute" top={"-2"} right={"-3"}>
                <ModalCloseButton />
              </Box>
            </Box>
          </Flex>
          <ModalBody>
            <Flex
              w="full"
              justifyContent="center"
              onClick={() => {
                // if (imageFile.selectedFile) return;
                handleFocus();
              }}
            >
              {photo ? (
                <div ref={imageRef}>
                  <img src={photo} alt="coverImage" className="object-cover  md:h-[255px]" />
                </div>
              ) : (
                <Flex
                  mt="20px"
                  w="80%"
                  height="255px"
                  border="1px solid #1455D9"
                  borderStyle="dashed"
                  borderRadius="8px"
                  // onClick={() => handleInput()}
                  justify="center"
                  alignItems="center"
                  backgroundColor="#E7EEF6"
                  flexDirection="column"
                  cursor="pointer"
                >
                  <AddInputImageIcon />
                  <Box textAlign="center">
                    <Text fontSize={{ base: "14px", md: "16px" }} lineHeight="24px" color="#808080">
                      <span className="text-[#1455D9] border-b-1 border-[#1455D9] pb-[1px] ">
                        Click to upload
                      </span>
                      or drag and drop
                    </Text>
                    <Text fontSize={{ base: "14px", md: "16px" }} lineHeight="24px" color="#808080">
                      image file here
                    </Text>
                  </Box>
                  <Box display="none">
                    <form encType="multipart/form">
                      <input
                        type="file"
                        accept="image/*"
                        // display="none"
                        className="w-full h-full border-spacing-0"
                        ref={imageRef}
                        // value={image}
                        onChange={(e) => handleChange(e.target.value, e.target.files[0])}
                      />
                    </form>
                  </Box>
                </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex w="full" height="80px" backgroundColor="#E7EEF6" justify="end" borderRadius="8px">
              <Button
                onClick={() => {
                  onClose();
                  toggle();
                }}
                alignSelf="end"
                margin="0.5rem"
                color="#333333"
                background="#fff"
                borderRadius="8px"
                shadow="rgba(0, 0, 0, 0.25)"
                px="1rem"
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  onClose();
                  toggle();
                  // handleSubmit();
                }}
                alignSelf="end"
                margin="0.5rem"
                background="#0C378B"
                borderRadius="8px"
              >
                <Flex gap={2} alignItems="center" onClick={handleSubmit}>
                  <UploadIcon />
                  <Text text="14px" color="#fff">
                    Upload
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default observer(CoverPhotoModal);
