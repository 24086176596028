/** @format */

import { action, makeAutoObservable, observable } from "mobx";

class ExperienceStore {
  initialValues = {
    id: "",
    title: "",
    company: "",
    startDate: new Date(),
    endDate: new Date(),
    industry: "",
    skills: []
  };

  modal = {
    isOpen: false,
    mode: "Add",
    removeExperience: false,
    experienceId: 0
  };

  constructor() {
    makeAutoObservable(this, {
      initialValues: observable,
      modal: observable,
      toggleModal: action,
      setInitialValue: action,
      setSelectedExperience: action,
      setRemoveExperienceModal: action
    });
  }

  toggleModal = (mode = "Add") => {
    this.modal = {
      ...this.modal,
      isOpen: !this.modal.isOpen,
      mode
    };
    if (this.modal.mode === "Add") {
      this.initialValues = {
        id: "",
        title: "",
        company: "",
        startDate: new Date(),
        endDate: new Date(),
        industry: "",
        skills: []
      };
    }
  };

  setRemoveExperienceModal = (id = "") => {
    this.modal = {
      ...this.modal,
      removeExperience: !this.modal.removeExperience,
      experienceId: id
    };
  };

  setInitialValue = (field, value) => {
    this.initialValues = {
      ...this.initialValues,
      [field]: value
    };
  };

  setSelectedExperience = (experience) => {
    this.initialValues = experience;
    this.toggleModal("Edit");
  };
}

export default ExperienceStore;
