import ArrowdownIcon from "assets/icons/ArrowdownIcon";
import DividerIcon from "assets/icons/DividerIcon";
import MoreCommentIcon from "assets/icons/MoreCommentIcon";
import PostAvater from "components/PostInput/PostAvater";
import { refetchKeys } from "constant";
import { useFeedAction } from "hooks/feeds";
import React, { useState } from "react";
import { getTimeAgoFromUpdated, isCurrentUserLiked } from "utils/helpers";

const CommentPanel = ({ comments = [], postType, showComment, id }) => {
  const [show, setShow] = useState(false);
  const mutation = useFeedAction(refetchKeys[postType]);

  const handleLike = (id, action) => {
    try {
      return mutation.mutate({
        id,
        type: postType,
        targetType: "comment",
        action
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  return (
    <div className="md:px-4 w-full mt-5">
      {showComment == id && (
        <div>
          {comments.length > 0 && (
            <div className="flex justify-start md:bg-[#fff] md:block  px-[18px] ">
              <button className="flex gap-2 items-center text-[14px] font-semibold leading-[21px]">
                Most recent
                <span>
                  <ArrowdownIcon />
                </span>
              </button>
            </div>
          )}
          <div className="w-full mt-7 flex flex-col gap-5 px-4 ">
            {comments.map((comment) => {
              const {
                likes,
                owner: { profile }
              } = comment;
              const numberOfLikes = likes.length;
              const isLikedByCurrentUser = isCurrentUserLiked(likes);

              return (
                <div key={comment.id} className=" w-full flex gap-3 items-start justify-start">
                  <div>
                    <PostAvater src={comment.src} />
                  </div>

                  <div className=" w-full">
                    <div className="  flex justify-between w-full">
                      <div className="flex bg-[#F3F2F0] flex-col p-2 gap-1 w-full">
                        <div className="w-full  ">
                          <div className="flex justify-between items-center">
                            <p className="font-bold text-sm lg:text-[16px] lg:leading-[21px]">
                              {profile.firstName} {profile.lastName}
                            </p>
                            <div className="justify-start flex items-center gap-3 pr-4">
                              <p className="text-[12px] leading-[16px] font-semibold">
                                {getTimeAgoFromUpdated(comment.createdAt, comment.updatedAt)}
                              </p>
                              <MoreCommentIcon />
                            </div>
                          </div>
                          <p className="text-[#808080] text-[14px] leading-[19px]">
                            {comment.jobTitle} @ {comment.company}
                          </p>
                        </div>
                        {/* Comment text */}
                        <div className="text-[#333333] py-2 text-[14px]  leading-[21px] font-semibold w-full">
                          <p>{comment.text}</p>
                        </div>
                      </div>
                    </div>

                    {/* Like */}
                    <div className="flex py-2 gap-4 items-center text-[#808080] text-[12px] font-bold">
                      <div
                        onClick={() =>
                          handleLike(comment.id, isLikedByCurrentUser ? "unlike" : "like")
                        }
                        className={`flex md:gap-2 gap-1 justify-center items-center hover:rounded-[25px] ${
                          isLikedByCurrentUser ? "#0C378B" : "#333"
                        } hover:bg-[#CCCCCC]`}
                      >
                        <p
                          className={`${isLikedByCurrentUser ? "text-[#0C378B]" : "text-[#333]"} px-2 cursor-pointer`}
                        >
                          {numberOfLikes} Like
                        </p>
                      </div>

                      <DividerIcon />
                      <p>Reply</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {comments.length > 1 && (
            <div
              onClick={() => setShow(!show)}
              className="flex justify-start  md:bg-[#fff] md:block  px-[18px] "
            >
              <button className="flex gap-2 items-center text-[14px] font-semibold leading-[21px]">
                Show {show ? "Less" : "More"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommentPanel;
