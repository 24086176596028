import React from "react";
import emptyStateFeedsImage from "assets/images/empty-state-feeds.png";

const GroupCard = ({ data }) => {
  const description = data.description;
  const truncatedDescription =
    description.length > 20 ? `${description.slice(0, 20)}...` : description;
  const imageSource = data.image || emptyStateFeedsImage;

  return (
    <div className="cursor-pointer">
      <div className="h-[72px] md:w-[231px] flex md:gap-[24px] gap-3 items-center text-[#333]">
        <div className="md:w-[62] md:h-[62px]">
          <img
            src={imageSource}
            className="object-cover rounded-[100%] w-[72px] h-[72px] md:w-[62px] md:h-[62px]"
            alt={data.groupName}
          />
        </div>
        <div className="flex flex-col gap-1 md:gap-2">
          <p className="font-medium text-[14px] md:text-[16px] leading-[21px] md:leading-[21px]">
            {data.groupName}
          </p>
          <p className="font-normal text-[12px] md:text-[14px] leading-[16px] text-[#808080] md:leading-[19px]">
            {truncatedDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
