/** @format */

import ImageCard from "components/Image/ImageCard";
import React from "react";

const ChatCardHeader = ({ data }) => {
  return (
    <div className="w-[91px] h-[140px] flex justify-center items-center flex-col">
      <ImageCard height={80} width={80} src={data.src} />
      <div className="flex justify-center items-center flex-col">
        <p className="text-[13px] leading-[20px] font-bold text-[#333] ">
          {data.firstName} {data.lastName}
        </p>
        <p className="text-[11px] leading-[16px] font-normal text-[#333]">
          {data.jobTitle} @ {data.company}
        </p>
      </div>
    </div>
  );
};

export default ChatCardHeader;
