import React, { useRef } from "react";
import { Input, IconButton, Flex } from "@chakra-ui/react";
import { ChatIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

const ChatInput = ({ conversation, handleTyping }) => {
  const inputRef = useRef();
  const { chatStore } = useStore();
  const { sendMessage } = chatStore;

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = inputRef.current.value.trim();
    if (message) {
      handleTyping(false);
      sendMessage({ message, conversation });
      inputRef.current.value = "";
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleChange = () => {
    const message = inputRef.current.value.trim();
    if (message.length > 2) {
      handleTyping(true);
    } else {
      handleTyping(false);
    }
  };

  return (
    <Flex as="form" onSubmit={handleSubmit} alignItems="center">
      <Input
        ref={inputRef}
        placeholder="Type a message"
        variant="filled"
        bg="gray.100"
        size="lg"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        h={"100px"}
        mr={2}
        autoFocus
        _focus={{ bg: "gray.200" }}
      />
      <IconButton
        type="submit"
        aria-label="Send"
        icon={<ChatIcon />}
        bg="blue.500"
        colorScheme="blue"
        size="lg"
        isDisabled={!inputRef.current?.value || inputRef.current.value.trim().length <= 2}
        _hover={{ bg: "blue.600" }}
        _active={{ bg: "blue.700" }}
      />
    </Flex>
  );
};

export default observer(ChatInput);
