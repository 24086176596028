/** @format */

import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  Input,
  ModalOverlay,
  FormLabel,
  Button,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Spacer,
  Text
} from "@chakra-ui/react";
import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import SaveIcon from "assets/icons/SaveIcon";
import Select from "react-select";
import LocationIcon from "assets/icons/LocationIcon";
import UploadImageIcon from "assets/icons/UploadImageIcon";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import useWidth from "utils/useWidth";
import { useUpdateProfile } from "hooks/user";

const values = [
  { value: "Portugal", label: "Chocolate" },
  { value: "Nigeria", label: "Strawberry" },
  { value: "USA", label: "Vanilla" }
];

const ProfileEditModal = ({ isOpen, onOpen, onClose, toggle, profileId, profile }) => {
  const [inputData, setInputData] = useState(profile);
  const [imageFile, setImageFile] = useState({
    selectedFile: "",
    fileName: ""
  });

  const [selectedOption, setSeletecOption] = useState();
  const { size } = useWidth("sm", "xl");

  const initialRef = React.useRef(null);
  const profilePicRef = React.useRef();

  const {
    userStore: { updateProfile, postProfileImage, message }
  } = useStore();

  const { mutate: updateUserProfile } = useUpdateProfile();

  const handleFocusOnInput = () => {
    return profilePicRef.current.click();
  };
  const handleSeletedLocation = (selectedOption) => {
    setSeletecOption(selectedOption);
  };

  const handleSubmit = () => {
    updateUserProfile(inputData);

    if (Object.keys(imageFile).length > 0) {
      const formData = new FormData();
      formData.append("filename", imageFile.fileName);
      formData.append("file", imageFile.selectedFile);
      const fileHeader = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      postProfileImage(formData, fileHeader);
    }

    setTimeout(() => {
      toggle();
    }, 500);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={() => {
          isOpen();
        }}
        onClose={() => {
          onClose();
        }}
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Flex alignItems="center" gap={2}>
                <Box
                  border="1px solid gray"
                  p="4px"
                  rounded="4px"
                  onClick={() => {
                    onClose();
                    toggle();
                  }}
                >
                  <ArrowLeftIcon />
                </Box>
                <Box
                  lineHeight={{ base: "14px", md: "25px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={700}
                  ml="3px"
                >
                  Profile
                </Box>
              </Flex>
              <Spacer />
              <Box
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Flex alignItems="center" gap={2} onClick={() => onClose()}>
                  <Flex p="4px" gap={1} w="80px" color="#fff" bgColor="#0C378B">
                    <SaveIcon />
                    <Box
                      fontWeight={700}
                      lineHeight={{ base: "16px", md: "21px" }}
                      fontSize={{ base: "14px", md: "16px" }}
                    >
                      Save
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel lineHeight={{ base: "14px", md: "25px" }} fontSize="14px" fontWeight={600}>
                Full Name
              </FormLabel>

              <Input
                ref={initialRef}
                name="firstName"
                value={inputData?.firstName}
                type="text"
                placeholder="Full Name"
                background="rgba(20, 85, 217, 0.05)"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Title
              </FormLabel>

              <Input
                name="jobTitle"
                value={inputData?.jobTitle}
                type="text"
                placeholder="Job Title"
                background="rgba(20, 85, 217, 0.05)"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Company
              </FormLabel>

              <Input
                name="company"
                value={inputData?.company}
                type="text"
                placeholder="Company Name"
                background="rgba(20, 85, 217, 0.05)"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Email
              </FormLabel>
              <Input
                name="email"
                value={inputData?.email}
                type="email"
                placeholder="example@gmail.com"
                background="rgba(20, 85, 217, 0.05)"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                lineHeight={{ base: "14px", md: "25px" }}
                fontSize="14px"
                fontWeight={600}
                mt="10px"
              >
                Phone Number
              </FormLabel>

              <Input
                name="phone"
                value={inputData?.phoneNumber}
                type="number"
                placeholder="+234"
                background="rgba(20, 85, 217, 0.05)"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>

            <Flex justify="space-between" alignItems="center" mt="20px" mb="20px">
              <Box w="45%">
                <Flex
                  alignItems="center"
                  gap={2}
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="21px"
                  mb="4px"
                >
                  Upload Photo
                </Flex>
                <Flex
                  justify="center"
                  alignItems="center"
                  bgColor="#0C378B"
                  borderRadius="8px"
                  py="8px"
                  gap={4}
                  onClick={handleFocusOnInput}
                  cursor="pointer"
                >
                  <Box
                    fontWeight={700}
                    lineHeight={{ base: "16px", md: "21px" }}
                    fontSize={{ base: "14px", md: "16px" }}
                    color="#fff"
                  >
                    Upload
                  </Box>
                  <UploadImageIcon />
                  <form encType="multipart/form" className="hidden">
                    <input
                      type="file"
                      accept="image/*"
                      display="none"
                      ref={profilePicRef}
                      onChange={(e) => {
                        setImageFile((prev) => ({
                          ...prev,
                          fileName: e.target.value,
                          selectedFile: e.target.files[0]
                        }));
                      }}
                    />
                  </form>
                </Flex>
              </Box>

              <Box w="40%">
                <Flex
                  alignItems="center"
                  gap={2}
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="21px"
                  mb="4px"
                >
                  <span>
                    <LocationIcon size={16} />
                  </span>
                  Location
                </Flex>

                <Flex gap={3} color="#fff" text="#333">
                  <Select
                    seleted={selectedOption}
                    options={values}
                    onChange={(selectedOption) => handleSeletedLocation(selectedOption)}
                  />
                </Flex>
              </Box>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
           
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ProfileEditModal);
