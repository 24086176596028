import React, { useState } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Button,
  Text,
  Flex
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { BiWorld } from "react-icons/bi";
import { IoIosLink } from "react-icons/io";
import { RiGroupFill } from "react-icons/ri";

const PostSettingsModal = ({
  onClose,
  isOpen,
  viewPostOption,
  handleSelectedOption,
  toggleCommentControl
}) => {
  const [selectedControl, setSelectedControl] = useState("Anyone");
  const [commentViewControl, commentViewContro] = useState([
    {
      name: "Anyone",
      selected: true,
      icon1: <BiWorld color="#808080" />,
      icon2: ""
    },
    {
      name: "Network Only",
      selected: false,
      icon1: <IoIosLink color="#808080" />,
      icon2: ""
    },
    {
      name: "Groups",
      selected: false,
      icon1: <RiGroupFill color="#808080" />,
      icon2: <ArrowCircleRightIcon width={12} height={12} />
    }
  ]);

  const handleCommentControl = (arg) => {
    setViewPostOption((prev) =>
      prev.map((opt) => {
        if (opt.name === arg) {
          setViewOption(opt.name);
          return { ...opt, selected: true };
        }
        if (opt.name !== arg) {
          return { ...opt, selected: false };
        }
        return opt;
      })
    );
  };
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Flex alignItems="center" justify="space-between" borderBottom="1px solid #333">
            <ModalHeader>
              <Box
                w={{ base: "", md: "10rem" }}
                color="#333"
                lineHeight={{ md: "27.28px" }}
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={600}
              >
                Post settings
              </Box>
            </ModalHeader>
            <ModalCloseButton />
          </Flex>
          <ModalBody borderBottom="1px solid #333">
            <Box>
              <Box my={{ lg: "1rem" }}>
                <Text fontSize={{ lg: "16px" }} fontWeight={700} color="#333">
                  Who can see your post?
                </Text>
              </Box>

              <Box>
                <Flex flexDirection="column" gap={3} py={3} color="#333">
                  {viewPostOption.map((opt) => {
                    return (
                      <Flex
                        key={opt.name}
                        fontSize={{ base: "12px", md: "16px" }}
                        fontWeight={600}
                        color="#333"
                        lineHeight={{ base: "", md: "21px" }}
                        onClick={() => handleSelectedOption(opt.name)}
                        justify="space-between"
                        alignItems="center"
                      >
                        <Flex gap={2} alignItems="center">
                          <Box background="#ccc" p={2} borderRadius="100%">
                            {opt.icon1}
                          </Box>
                          <Text color="#333" fontSize={{ lg: "16px" }} lineHeight={{ lg: "21px" }}>
                            {" "}
                            {opt.name}
                          </Text>
                          <span>{opt.icon2} </span>
                        </Flex>
                        <input
                          className="border-1 border-black w-3 h-3 rounded border-r-black"
                          type="radio"
                          checked={opt.selected}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              </Box>
              <Flex
                _hover={{ bg: "#CCCCCC", p: "10px" }}
                justify="space-between"
                mt={{ lg: "2rem" }}
                onClick={toggleCommentControl}
              >
                <Flex flexDirection="column" gap={2}>
                  <Text fontSize={{ base: "12px", md: "14px" }}>Comment control</Text>
                  <Text fontSize={{ base: "12px", md: "14px" }}>{selectedControl}</Text>
                </Flex>

                <ArrowCircleRightIcon width={15} height={15} />
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(PostSettingsModal);
