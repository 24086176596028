/** @format */

import CircleIcon from "assets/icons/CircleIcon";
import MessageMoreIcon from "assets/icons/MessageMoreIcon";
import RatingIcon from "assets/icons/RatingIcon";
import React from "react";
import ChatCardHeader from "./ChatCardHeader";
import ChatMessageCard from "./ChatMessageCard";
import ChatInput from "./ChatInput";
import { messageData } from "constant/message";

const ChatCard = () => {
  return (
    <section className="flex flex-col h-[100%]">
      <div className="flex justify-between items-center px-5 py-1 border-b-2 border-[#CCCCCC] ">
        <div className="flex flex-col">
          <h3> Funke Opeke</h3>
          <div className="flex gap-2 items-center">
            <CircleIcon />
            <p>online</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <MessageMoreIcon />
          <RatingIcon />
        </div>
      </div>

      <div className="h-[15%] lg:inline-block flex justify-center items-center px-5 py-3 border-b-1 border-[#CCCCCC]">
        <ChatCardHeader data={messageData} />
      </div>

      <div className="md:h-[60%]  border- border-green px-5 py-5 ">
        <div className="h-[100%]">
          <ChatMessageCard />
        </div>
      </div>

      <div className="h-[20%] justify-items-end">
        <ChatInput />
      </div>
      {/*  */}
    </section>
  );
};

export default ChatCard;
