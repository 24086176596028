/** @format */

import { action, makeAutoObservable, observable } from "mobx";

class EducationStore {
  initialValues = {
    institution: "",
    degree: "",
    startDate: new Date(),
    endDate: new Date()
  };

  modal = {
    isOpen: false,
    mode: "Add",
    removeEducation: false,
    educationId: 0
  };

  constructor() {
    makeAutoObservable(this, {
      initialValues: observable,
      modal: observable,
      toggleModal: action,
      setInitialValues: action,
      setSeletedEducation: action,
      setRemoveEducationModal: action
    });
  }

  setInitialValues = (field, value) => {
    this.initialValues = {
      ...this.initialValues,
      [field]: value
    };
  };

  toggleModal = (mode = "Add") => {
    this.modal = {
      ...this.modal,
      isOpen: !this.modal.isOpen,
      mode
    };

    if (mode === "Add") {
      this.initialValues = {
        institution: "",
        degree: "",
        startDate: new Date(),
        endDate: new Date()
      };
    }
  };

  setRemoveEducationModal = (id = "") => {
    this.modal = {
      ...this.modal,
      removeEducation: !this.modal.removeEducation,
      eduucationId: id
    };
  };

  setSeletedEducation = (education) => {
    this.initialValues = education;
    this.toggleModal("Edit");
  };
}

export default EducationStore;
