import React from "react";
import theme from "styles";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Define an error handler function
function errorHandler(error, info) {
  // You can log the error or take other actions here
  console.error(error);
  console.error(info);
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
          <App />
        </ErrorBoundary>
      </ChakraProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
reportWebVitals();

// Define a fallback UI for error boundary
function ErrorFallback({ error }) {
  return (
    <div>
      <h1>Something went wrong</h1>
      <p>{error.message}</p>
      {/* You can render a user-friendly error message here */}
    </div>
  );
}
